.push-inner {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px 0 10px 0;
  padding: 20px;
  height: 100%;
  box-shadow: 0 8px 14px 0 rgba(216, 221, 230, .5);
  z-index: 2;

  .title {
    display: block;
    position: relative;
    line-height: 1.2;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $colorPrimary;
    font-size: 24px;
  }

  p {
    margin-top: 0;
    line-height: 1.3;
  }

  .push-btn {
    margin-top: auto;
    margin-bottom: 10px;

    .btn-ag {
      font-size: 20px;
    }
  }
}

// --------------------------
// Push star
// --------------------------
.push-star {
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  & > img {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

  .push-inner {
    margin: -20px 10px 40px;

    // @include media(">=md") {
      margin: -50px 40px 40px;
    // }
  }

  .btn {
    margin-bottom: 20px;
  }
}

