.push-inner {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px 0 10px 0;
  padding: 20px;
  height: 100%;
  box-shadow: 0 8px 14px 0 rgba(216, 221, 230, .5);
  z-index: 2;

  .title {
    margin-bottom: 10px;
  }

  p {
    margin-top: 0;
    line-height: 1.3;
  }

  .push-btn {
    margin-top: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      align-self: center;
    }
  }
}

// --------------------------
// Push star
// --------------------------
.push-star {
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  & > img {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

  .push-inner {
    margin: -20px 10px 40px;

    @include media(">=md") {
      margin: -50px 40px 40px;
    }
  }

  .push-inner.push-without-image {
    margin: 40px 10px;
  }

  .btn {
    margin-bottom: 20px;
  }
}

// --------------------------
// Push simple
// --------------------------
.push-simple {
  list-style: none;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-primary-core-bundle;

    .iicon {
      display: block;
      margin: 10px auto;
      width: rem(30);
      height: rem(30);
    }
  }
  .push-inner {
    .img-wrapper {
      img.img-presentation {
        width: 40px;
      }
    }
  }
}

.push-simple-green {
  .title {
    color: $color-secondary-core-bundle;
  }
}

.push-simple-img {
  .img-wrapper {
    height: 140px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 10px 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .push-inner {
    padding: 0;

    .title,
    p {
      padding: 0 20px;
    }
  }
}

// --------------------------
// Push number
// --------------------------
.push-number {
  color: $color-primary-core-bundle;
  text-align: center;

  .title {
    font-size: 2.3rem;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 10px;

    .number {
      font-size: 3rem;
      line-height: 1;
      display: block;
      margin-bottom: 10px;
    }
  }

  p {
    margin-top: 0;
    line-height: 1.3;
  }

  @include media(">=md") {
    .title {
      .number {
        font-size: 3.6rem;
      }
    }
  }

  @include media(">=lg") {
    .title {
      .number {
        font-size: 4.4rem;
      }
    }
  }
}

// --------------------------
// Push interne
// --------------------------
.push-internal {
  width: 100%;

  .push-inner {
    border-radius: 0;
    box-shadow: none;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-between;
    background: linear-gradient(to right, #0788da 0%, $color-primary-core-bundle 100%);
  }

  .title {
    color: #fff;
  }

  .push-btn {
    margin-top: 30px;
  }

  .push-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .push-image {
    display: none;
  }

  @include media(">=sm") {
    .push-content {
      width: 70%;
      max-width: 800px;
    }

    .push-image {
      width: 22%;
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  @include media(">=md") {
    .push-image {
      position: absolute;
      display: inline-block;
      bottom: 0;
      left: 10%;
      width: 96px;
      transform: translate3d(-50%, 0, 0);

      img {
        width: 100%;
        max-width: 196px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

// --------------------------
// Push conseils
// --------------------------
.push-advice {
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  .push-inner {
    overflow: hidden;
    box-shadow: none;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-between;
    background:
      radial-gradient(
        circle at bottom left,
        #0788da 10%,
        $color-primary-core-bundle 40%
      );
  }

  .title {
    color: #fff;
  }

  .push-btn {
    margin-top: 30px;
  }

  .push-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .push-image {
    position: absolute;
    bottom: -15px;
    left: -20px;
    width: 96px;

    img {
      width: 100%;
      max-width: 196px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      transform: rotate(15deg);
    }
  }

  @include media(">=sm") {
    .push-content {
      width: 70%;
      max-width: 800px;
    }

    .push-image {
      width: 22%;
      bottom: -30px;
      left: -40px;
    }
  }
}

// --------------------------
// Push infos
// --------------------------
.push-infos {
  text-align: left !important;

  .push-infos__summary {
    border-bottom: 1px solid $color-secondary-core-bundle;
    padding-bottom: 20px;
  }

  .btn {
    float: right;
  }
}

.section a.btn {
  max-width: 90%;
  white-space: normal;
}
