/* common styles */
.push-agency-bg {
  position: relative;
  background: url('../../images/mdp/push-agency-bg.jpg') no-repeat top center;
  background-size: cover;
}

/* Neutral : find your agency */
.push-agency-neutral {
  position: relative;
  padding: 20px 20px 160px 20px;
  border-top: 4px solid $colorSecondary;
  background: url('../../images/mdp/push-agency-neutral-bg.jpg') no-repeat top center;
  background-size: cover;
  color: $colorText;

  @media (min-width: $screen-sm) {
    padding: 40px;
  }

  .france {
    height: 239px;
  }

  .push-agency-content {
    .push-agency-title {
      line-height: 110%;
      @include em-size(36);
      text-transform: uppercase;

      strong {
        display: block;
        text-transform: none;
      }
    }

    p {
      padding: 20px 0 30px 0;
      @include em-size(18);
    }

    .push-agency-button {
      margin-bottom: 30px;
    }

  }

  .personnage {
    height: 150px;
    position: absolute;
    bottom: -160px;
    margin-left: 60px;

    @media (min-width: $screen-sm) {
      bottom: -40px;
      margin-left: 360px;
    }
  }
}

.col-agent,
.col-contributor {
  text-align: center;
  padding-bottom: 20px;

  h1.col-title {
    color: $colorBlue;
    font-weight: normal;
    text-transform: none;
    margin: 0;
    @include em-size(24);
  }

  h3 {
    @include em-size(18);

    a {
      color: $colorText;
      text-decoration: underline;
      font-weight: normal;
      @include em-size(16);
    }
  }

  p {
    margin-bottom: 15px;
    line-height: 1.1;

    a {
      color: $colorAnthracite;
      text-decoration: underline;
      @include em-size(13);
    }
  }

  @media (min-width: $screen-md) {
    padding-bottom: 30px;

    p.col-title {
      margin-bottom: 30px;
      @include em-size(22);
    }
  }
}

.col-agent {
  position: relative;
  z-index: 3;

  .agent-linked-in-profile {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 25px;

    a {
      position: relative;
      display: inline-block;
      padding: 0 30px 0 40px;
      text-decoration: none !important;
      color: $colorPrimary;
      font-weight: bold;

      span {
        display: inline-block;
        text-decoration: none !important;
        color: $colorPrimary;

        &.iicon-linked-in {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          height: 40px;

          &::before {
            @include em-size(30);
          }
        }

        &.text {
          padding: 0 5px;
          line-height: 1;
          text-transform: none !important;
          @include em-size(16);
        }
      }

      svg {
        position: absolute;
        right: 0;
        top: 0;
        width: 37px;
        height: 27px;
      }

      &:hover {
        text-decoration: none !important;

        .text {
          text-decoration: underline !important;
        }
      }
    }
  }

  .agent-quote {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    margin: 15px 0 20px;

    &::after {
      position: absolute;
      z-index: 1;
      left: 50%;
      margin-left: -1250px;
      top: calc(100% - 110px);
      height: 700px;
      width: 2500px;
      display: inline-block;
      content: "";
      background-color: $colorLightGrey;
    }

    .agent-quote-inner {
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      padding: 30px 20px;
      background-color: $colorBlue;
      color: #fff;
      font-style: italic;
    }

    .agent-quote-text {
      p {
        margin-bottom: 10px;
        line-height: 1.2;
        font-weight: normal !important;
        @include em-size(16);
      }
    }

    .agent-quote-author {
      padding-top: 10px;

      p {
        margin-bottom: 0;
        font-weight: normal !important;
        font-style: normal;
        line-height: 1.2;
        @include em-size(14);
      }
    }

    @media (min-width: $screen-md) {
      text-align: center;
      margin-top: 40px;

      .agent-quote-inner {
        display: inline-block;
        width: 86%;
        padding: 60px;
        border-radius: 10px 0 10px 0;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);
      }

      .agent-quote-text {
        @include em-size(20);

        p {
          margin-bottom: 15px;
        }
      }

      .agent-quote-author {
        @include em-size(18);
      }
    }
  }
}

.col-contributor {
  position: relative;
  z-index: 3;
  padding-top: 15px;
  background-color: $colorLightGrey;

  &::after {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -1250px;
    top: -10px;
    height: 700px;
    width: 2500px;
    display: inline-block;
    content: "";
    background-color: $colorLightGrey;
  }

  .col-title,
  .contributors-list {
    position: relative;
    z-index: 2;
  }

  h2.col-title {
    margin-top: 0;
    margin-top: 0;
    margin-bottom: 30px;
    @include em-size(24);
  }

  .contributors-list {
    p {
      color: $colorText;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 25px;
      @include em-size(18);

      a {
        color: $colorText;
        font-weight: normal;
        @include em-size(16);
      }
    }
  }

  @media (min-width: $screen-md) {
    .contributors-list {
      flex-direction: row;
      align-items: start;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;

      p {
        text-align: center;
        width: 33%;
        word-wrap: break-word;
      }
    }
  }

  @media (min-width: $screen-lg) {
    .contributors-list {
      padding: 0 40px;
    }
  }
}

/* Details */
.push-agency-details {
  @extend .push-agency-bg;

  position: relative;
  padding: 10px 0 40px 0;

  .container {
    position: relative;
  }

  @media (min-width: $screen-sm) {
    padding: 50px 0 0 0;
  }

  @media (min-width: $screen-md) {
    .row {
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .push-agency-people {
    text-align: center;
    margin-bottom: 30px;

    img {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      max-width: 300px;
      height: auto;
      border-bottom: 3px solid $colorGreen;
    }

    .personnage {
      position: relative;
      width: 100%;
      height: 280px;
      background-position: bottom center;
      background-size: auto 260px;

      &::after {
        position: absolute;
        left: 25%;
        bottom: 0;
        width: 50%;
        height: 4px;
        background-color: $colorGreen;
        content: '';
      }
    }

    @media (min-width: $screen-md) {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 265px;
      margin-bottom: 0;

      .personnage {
        height: 265px;
        background-size: 230px auto;

        &::after {
          display: none;
        }
      }

      img {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        border-bottom: 0;
        max-height: 100%;
        width: auto;
      }
    }

    @media (min-width: $screen-lg) {
      height: 265px;

      .personnage {
        height: 265px;
      }
    }
  }

  .push-agency-content {
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: left;

    @media (min-width: $screen-md) {
      margin-left: 25%;
      min-height: 265px;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    & > div {
      color: inherit;
      text-transform: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding: 10px 0;
      margin: 0;
      line-height: 110%;

      .push-agency-your-agency {
        display: block;
        padding-bottom: 10px;
        line-height: 100%;
        @include em-size(15);
      }

    }

    .push-agency-city {
      @include em-size(30);
      line-height: 100%;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0;
      margin: 10px 0 0;
    }

    .push-agent {
      margin-top: 7px;
      line-height: 1.3;
    }

    .push-agency-button {
      margin: 10px 0 20px 0;
    }
  }

  .push-agency-phone {
    position: relative;
    display: block;
    padding-left: 33px;
    margin: 0 0 10px;
    font-weight: bold;
    text-align: left;
    @include em-size(18);

    .iicon {
      position: absolute;
      top: 4px;
      left: 0;
      width: 25px;
      height: 25px;
    }
  }

  .push-agency-email {
    position: relative;
    padding-left: 33px;
    text-align: left;
    margin: 0 0 25px;
    color: $colorText;
    font-weight: bold;
    font-size: 1.125em !important;

    a {
      display: inline-block;
      color: $colorText;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .iicon {
      position: absolute;
      left: 0;
      top: 3px;
      width: 25px;
      height: 25px;
    }
  }

  .push-agency-orias {
    position: relative;
    border: 0;
    margin-top: 5px;
    padding-left: 40px !important;
    @include em-size(13);

    a {
      color: $colorText;
      text-decoration: none;
    }

    .iicon {
      position: absolute;
      left: 0;
      top: 7px;
      width: 25px;
      height: 25px;
    }

    @media (min-width: $screen-md) {
      margin-bottom: 20px;
    }
  }

  .push-agency-link-other-agency {
    display: block;
    float: right;
    border: 1px dotted transparent;
    margin-top: 10px;
    text-transform: uppercase;
    color: #9e9e9e;
    @include em-size(13);

    @media (min-width: $screen-md) {
      position: absolute;
      bottom: 0;
      right: 10px;
      float: none;
      margin-top: 0;
    }

    span {
      position: relative;
      display: block;
      padding: 5px 30px 5px 5px;
      color: $colorText;

      &::after {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        content: '';
        @extend .sprite;
        background-position: 0 -114px;
        width: 20px;
        height: 30px;
      }
    }

    &:focus {
      outline: 0;
      border: 1px dotted $colorText;
    }
  }
}

.push-agency-contact-agency {
  a {
    display: block;
    padding: 10px;
    color: #fff;
    background: $colorGreen;
    line-height: 110%;
    text-align: center;
    @include em-size(16);
    font-weight: bold;
    @include transition(all .5s);
    border: 1px dotted transparent;

    &:hover,
    &:focus {
      text-decoration: none;
      background: darken($colorGreen, 10%);
    }
    &:focus {
      outline: 0;
      text-decoration: underline;
      border: 1px dotted #fff;
    }
  }

  a > span {
    color: #fff;
  }

  @media (max-width: $screen-xs) {
    a > i {
      display: block;
    }
  }
}

.schedule-meeting {
  padding-top: 40px;

  .schedule-meeting-inner {
    display: block;
    width: 100%;
    padding: 20px;
  }

  h2 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
    @include em-size(20);
  }

  form {
    .form-group {
      .form-sub-group {
        position: relative;

        &.form-sub-group-date {
          .iicon {
            position: absolute;
            z-index: 3;
            right: 12px;
            bottom: 12px;
            pointer-events: none;
            @include em-size(21);
          }
        }

        &.form-sub-group-hour {
          .iicon {
            position: absolute;
            z-index: 3;
            right: 12px;
            bottom: 12px;
            pointer-events: none;
            @include em-size(21);
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    text-align: center;

    .schedule-meeting-inner {
      display: inline-block;
      width: 86%;
      padding: 30px;
      background-color: #fff;
      border-radius: 10px 0 10px 0;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
    }

    h2 {
      margin-bottom: 30px;
      @include em-size(22);
    }

    form {
      text-align: left;
      display: inline-block;
      width: 100%;
      max-width: 500px;

      .form-group {
        padding-bottom: 3px;
        @include clearfix;

        &.form-group-submit {
          text-align: right;

          button,
          input {
            width: 200px;
            margin-right: -30px;
            @include scale(.7);
          }
        }

        textarea {
          height: 110px;
          border-radius: 3px;
        }

        input.form-control,
        .form-group textarea,
        select.form-control,
        .btn.dropdown-toggle {
          height: 45px;
        }

        .form-sub-group {
          display: inline-block;
          float: left;

          &.form-sub-group-date {
            width: calc(70% - 15px);
            float: left;
          }

          &.form-sub-group-hour {
            width: 29%;
            float: right;
          }
        }

        label {
          font-weight: bold;
          display: block;
          @include clearfix;
        }

        .radio-inline {
          margin-left: 0;

          input {
            margin-left: -15px;
          }
        }
      }

    }
  }
}

.push-agency-hours {
  text-align: center;

  @media (min-width: $screen-sm) {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
  }
}

.business-hours {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 33px;
  text-align: left;

  .iicon {
    position:absolute;
    left: 0;
    top: 4px;
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    overflow: visible;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    @include em-size(18);

    @media (min-width: $screen-md) and (max-width: $screen-lg) {
      @include em-size(13);
    }
  }

  .business-hours-open {
    color: $colorSecondary;
  }
}

.opening-hours {
  padding: 0 20px;

  h2 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
    @include em-size(20);
  }

  p {
    &.day-name {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0 !important;
      margin-bottom: 15px;
    }

    &.day-period-value,
    &.day-period-label,
    &.day-hour-info {
      line-height: 1;
      margin: 0 0 5px !important;
      @include em-size(14);
    }

    &.day-period-value {
      font-weight: bold;
    }

    &.day-hour-info {
      font-style: italic;
    }

    &.not-available {
      text-align: center;
      padding-bottom: 30px;
    }
  }

  ul {
    margin-left: -20px;
    margin-right: -20px;

    li.day {
      padding: 13px 30px 15px;
      display: block;
      vertical-align: top;
      border-top: 1px solid $colorGrey;
      clear: both;
      text-align: right;
      @include clearfix;

      .day-morning,
      .day-afternoon {
        display: inline-block;
        width: 49%;
        vertical-align: top;
        text-align: left;
        padding-right: 40px;
      }

      &:first-child {
        border-top: 0;
      }

      &.active {
        background: $colorBlue;
        color: #fff;
      }
    }
  }

  .hour-exception {
    margin-top: 25px;
    text-align: center;
    background-color: $colorLightGrey;
    padding: 20px 12px;
    border-radius: 10px;

    p {
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 7px;
      font-style: italic;
      text-align: center;
      @include em-size(15);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $screen-sm) {
    p {
      &.day-name {
        text-align: left;
      }
    }

    ul {
      margin-left: -20px;
      margin-right: -20px;
      display: flex;
      padding-bottom: 20px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;

      li.day {
        padding: 15px;
        border-top: 0;
        flex: 1;
        text-align: left;
        border-left: 1px solid $colorLightGrey;

        .day-morning,
        .day-afternoon {
          display: block;
          margin-bottom: 20px;
          width: 100%;
          padding-right: 0;
        }

        .day-afternoon {
          margin-bottom: 0;
        }

        &:first-child {
          border-left: 0;
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    h2 {
      margin-bottom: 30px;
      @include em-size(22);
    }

    p {
      &.day-period-value,
      &.day-period-label,
      &.day-hour-info {
        line-height: 1.1;
        @include em-size(15);
      }
    }
  }
}

body.agency-opening-hours {
  .content {
    padding-bottom: 0;

    .assistance {
      background-color: $colorLightGrey;
      padding: 20px 12px;
      margin-top: 35px;

      a.btn-ag-assistance {
        background: none !important;
      }
    }
  }

  @media (min-width: $screen-md) {
    .content {

      .assistance {
        margin-top: 75px;
      }
    }
  }
}

#collapseAgents {
  color: #fff;
  background: $colorGreen;

  .row {
    padding-top: 30px;
    padding-bottom: 30px;

    p:first-child {
      text-transform: uppercase;
    }

    a {
      color: #fff;
      text-decoration: underline;
      border: 1px dotted transparent;
      white-space: normal;
      word-wrap: break-word;

      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
        border: 1px dotted #fff;
      }
    }

    a[href^="tel"] {
      text-decoration: none;
    }

    p {
      margin-top: 20px;
    }

    .col-contributor {
      margin-top: 40px;

      @media (min-width: $screen-sm) {
        margin-top: 0;
      }
    }
  }
}

#map {
  width: 100%;
  height: 560px;
  margin-top: -15px;

  @media (min-width: $screen-md) {
    text-align: left;
    margin-top: 0;
  }
}

.gmap-infobulle {
  .gmap-infobulle-title {
    display: block;
    padding: 5px 0 12px;
    font-weight: bold;
    color: $colorBlue;
    @include em-size(20);
  }

  .gmap-infobulle-content {
    display: block;
    color: $colorAnthracite;
    @include em-size(16);

    .row {
      & > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .gmap-infobulle-link {
    float: right;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    color: $colorBlue;
    margin: 10px 0;
    @include em-size(16);

    &:hover {
      text-decoration: none;
    }
  }
}

/* Message */
.push-agency-message {
  @extend .push-agency-bg;

  padding: 10px 10px 0 10px;
  border-bottom: 4px solid $colorSecondary;

  .push-agency-people {
    position: relative;

    img {
      display: block;
      height: auto;
      max-height: 200px;
      width: auto;
      max-width: 100%;
      margin: 0 auto;

      @media (max-width: $screen-sm) {
        border-bottom: 3px solid $colorGreen;
      }

      @media (min-width: $screen-sm) {
        float: right;
      }
    }

    .personnage {
      margin: 0 auto;
    }
  }

  .push-agency-content {
    padding-top: 30px;
    text-align: center;

    blockquote {
      border: 0;
      padding: 0;
      @include em-size(20);
    }

    p {
      padding: 0;
      margin: 0;
    }

    @media (min-width: $screen-sm) {
      text-align: left;
    }

    @media (max-width: $screen-sm) {
      .push-agency-city-name {
        padding: 20px 0 0;
      }
    }
  }
}

/* Simple */
.push-agency-simple {
  @extend .push-agency-bg;

  padding: 10px 10px 0 10px;
  margin: 10px auto;
  border-bottom: 4px solid $colorSecondary;

  .push-agency-people {
    img {
      display: block;
      margin: 0 auto;
      max-height: 150px;
      width: auto;

      @media (max-width: $screen-sm) {
        border-bottom: 3px solid $colorGreen;
      }
    }

    .personnage {
      width: 100%;
      height: 160px;
      background-size: 136px 160px
    }
  }

  .push-agency-content {
    padding-top: 20px;
    text-align: center;

    @media (min-width: $screen-sm) {
      text-align: left;
    }

    p {
      padding: 0 !important;
      margin: 0;
    }

    address {
      margin-bottom: 5px;
    }

    .push-agency-your-agency {
      line-height: 110%;
      @include em-size(14);
    }

    .push-agency-city {
      line-height: 100%;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0;
      margin: 10px 0 0;
      @include em-size(24);
    }
  }

  .push-agency-cta {
    vertical-align: middle;

    .push-agency-button {
      margin: 10px 0 20px 0;
    }

    @media (min-width: $screen-sm) {
      padding-top: 45px;
    }
  }
}

/* Agent */
.push-agency-agent {
  @extend .push-agency-bg;

  padding: 10px 10px 0 10px;
  margin: 10px auto;
  border-bottom: 4px solid $colorSecondary;

  .push-agency-people {
    img {
      display: block;
      margin: 0 auto;
      max-height: 150px;
      width: auto;
    }

    .personnage {
      width: 100%;
      height: 160px;
      background-size: 136px 160px
    }
  }

  .push-agency-content {
    padding-top: 30px;
    text-align: center;

    @media (min-width: $screen-sm) {
      text-align: left;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .push-agency-name {
      @include em-size(24);
      line-height: 110%;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
  }

  .push-agency-cta {
    vertical-align: middle;

    @media (min-width: $screen-sm) {
      padding-top: 45px;
    }

    .push-agency-button {
      margin: 10px 0 20px 0;
    }
  }
}

/* Find */
.push-agency-find {
  @extend .push-agency-bg;

  padding: 10px 10px 0 10px;
  margin: 10px auto;
  border-bottom: 4px solid $colorSecondary;

  .personnage {
    height: 150px;
    margin: 0 auto;
  }

  .push-agency-content {

    padding-top: 30px;
    text-align: center;

    @media (min-width: $screen-sm) {
      text-align: left;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .push-agency-title {
      @include em-size(26);
      line-height: 110%;
      font-weight: normal;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }

    .push-agency-subtitle {
      @include em-size(22);
      line-height: 110%;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }

  }

  .push-agency-cta {

    vertical-align: middle;

    @media (min-width: $screen-sm) {
      padding-top: 45px;
    }

    .push-agency-button {
      margin: 10px 0 20px 0;
    }

  }
}

/* Push advisor SARPGN */
.push-advisor {

  position: relative;
  padding: 20px 20px 0 20px;
  border-top: 4px solid $colorSecondary;
  background: url('../../images/mdp/push-agency-neutral-bg.jpg') no-repeat top center;
  background-size: cover;
  color: $colorText;

  @media (min-width: $screen-sm) {
    padding: 40px 40px 0 40px;
  }

  .container {
    //background: url('../../images/sarpgn/sarpgn-miss@2x.png') no-repeat;
    background-size: auto 230px;
    background-position: bottom left;
    padding-bottom: 230px;

    @media (min-width: $screen-sm) {
      background-position: bottom left;
      padding-bottom: 0;
      background-size: auto 300px;
    }

    @media (min-width: $screen-md) {
      background-size: auto 100%;
    }

  }

  .push-advisor-content {

    .push-advisor-title {
      line-height: 110%;
      @include em-size(36);
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      @include em-size(18);
    }

  }

  .push-advisor-button {
    margin-top: 30px;
    margin-bottom: 30px;
  }

}

/* Advisor page */
body.sarpgn .push-agency-details {

  position: relative;
  padding-bottom: 20px;

  @media (max-width: $screen-sm) {
    padding-bottom: 0;
  }

  .push-agency-people {

    .miss {
      //background: url('../../images/sarpgn/sarpgn-miss@2x.png') no-repeat;
      background-size: auto 100%;
      background-position: bottom center;
      height: 200px;
      margin-top: 30px;

      @media (min-width: $screen-sm) {
        background-position: bottom center;
        padding-bottom: 0;
        margin-top: 0;
        background-size: auto 300px;
        height: 300px;
      }

      @media (min-width: $screen-md) {
        background-size: auto 100%;
        height: 300px;
      }

    }

  }

  h1 {
    color: $colorText;
    text-align: center;

    .push-agency-title {
      @include em-size(26);
      line-height: 110%;
      display: block;
    }
    .push-agency-subtitle {
      @include em-size(14);
      text-transform: none;
    }
  }

  .push-agency-content {
    margin: 10px 0 20px 0;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    a[href^="tel"] {
      text-decoration: none;
    }

    p {
      margin: 0 0 20px 0;
    }

  }

  .push-agency-hours {
    font-size: 1.25em;
    padding-top: 10px;
    padding-bottom: 20px;

    strong {
      display: block;
    }

    @media (min-width: $screen-sm) {
      padding-top: 60px;
      text-align: center;
    }
  }
}

/* Devenir client */
body.sarpgn .push-agency-become-customer {
  & > .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .push-agency-become-customer-title {
    background: $colorOrange;
    color: #fff;
    padding: 10px;
  }

  .push-agency-become-customer-subtitle {
    color: $colorOrange;
    line-height: 120%;
    font-size: 1.125em;
    margin-top: 30px;
    text-align: center;

    @media (min-width: $screen-md) {
      margin-top: inherit;
    }

    strong {
      display: block;
    }
  }

  .img-advisor {
    border-bottom: 3px solid $colorOrange;
    margin-left: auto;
    margin-right: auto;
  }

}

/* Vous êtes déjà client */
body.sarpgn .push-agency-already-customer {

  & > .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .push-agency-already-customer-title {
    background: $colorSecondary;
    color: #fff;
    padding: 10px;
  }

  .push-agency-already-customer-subtitle {
    color: $colorSecondary;
    line-height: 120%;
    font-size: 1.125em;
    margin-top: 30px;
    text-align: center;

    @media (min-width: $screen-md) {
      margin-top: inherit;
    }
  }

  .img-advisor {
    border-bottom: 3px solid $colorSecondary;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-md) {
      margin-top: 90px;
    }
  }

}


// OFFERS
.offers-block-list {
  display: inline-block;
  text-align: center;
  padding: 0 20px;
  max-width: 920px;
  width: 100%;
  margin-bottom: 40px;
  @include clearfix;

  h2 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 30px;
    @include em-size(20);
  }

  > ul {
    > li {
      overflow: hidden;
      background-color: #fff;
      margin-bottom: 30px;
      min-height: 150px;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
      @include clearfix;

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 0;
      }

      div.pic {
        height: 150px;
        display: block;
        width: 100%;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      div.content {
        position: relative;
        padding: 15px;
        display: block;
        width: 100%;
        background-color: #fff;

        h3 {
          color: $colorGreen;
          margin: 0 0 15px;
          font-weight: bold;
          line-height: 1.1;
          @include em-size(18);
        }

        p {
          color: $colorAnthracite;
          line-height: 1.2;
          margin-bottom: 0;
          @include em-size(15);
        }

        a.cta {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          display: block;
          height: 70px;
          line-height: 70px;
          text-transform: uppercase;
          text-align: center;
          background-color: #fff;
          color: $colorGreen;
          font-weight: bold;
          border-top: 1px solid $colorGreen;
          @include transition(all .2s .1s);

          &:hover {
            text-decoration: none;
            background-color: $colorGreen;
            color: #fff;
          }
        }

        ul.ctas {
          padding-top: 30px;

          li {
            flex: 1;
            margin: 0 0 10px;

            a {
              @include em-size(14);
            }
          }
        }
      }

      &.is-link:hover {
        div.content {
          a {
            text-decoration: none;
            background-color: $colorGreen;
            color: #fff;
          }
        }
      }

      &.offer-item-alt {
        div.content {
          h3 {
            color: $colorBlue;
          }

          a.cta {
            color: $colorBlue;
            border-top: 1px solid $colorBlue;

            &:hover {
              background-color: $colorBlue;
            }
          }
        }

        &.is-link:hover {
          div.content {
            a {
              text-decoration: none;
              background-color: $colorBlue;
              color: #fff;
            }
          }
        }
      }
    }

    &.multi-offers {
      > li {
        div.content {
          padding-bottom: 90px;
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    h2 {
      @include em-size(22);
    }

    > ul {
      > li {
        min-height: 200px;

        div.pic {
          float: left;
          width: 215px;
          min-height: 200px;
        }

        div.content {
          float: right;
          padding: 20px;
          width: calc(100% - 215px);
          min-height: 200px;

          h3 {
            @include em-size(20);
          }
        }

        ul.ctas {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-around;
          padding-top: 30px;

          li {
            flex: 1;
            margin: 0;

            a {
              @include em-size(14);
            }
          }
        }
      }

      &.multi-offers {
        > li {
          min-height: 220px;
          display: inline-block;
          width: calc(50% - 15px);
          float: right;

          div.pic {
            width: 175px;
            min-height: 225px;
          }

          div.content {
            width: calc(100% - 175px);
            padding: 20px 20px 90px;
            min-height: 225px;
          }

          &:nth-child(odd) {
            clear: left;
            float: left;
          }
        }
      }
    }
  }
}


// NEWS
.news-block {
  width: 100%;
  text-align: center;

  .news-block-inner {
    padding: 0 20px;
    max-width: 540px;
    text-align: left;
    display: inline-block;
  }

  iframe {
    width: 100% !important;
  }

  @media (min-width: $screen-sm) {
    iframe {
      width: 500px !important;
    }
  }
}

.push-agency-details {
  .push-agency-hours {
    a.btn-ag-bg-blue,
    form#contactAgency {
      position: relative;
      display: block;
      clear: both;
      width: calc(100% - 20px);
      margin-bottom: 10px;
    }

    a.btn-ag-bg-blue {
      margin-left: 10px;
    }

    form#contactAgency .btn-ag {
      display: block;
      clear: both;
      width: 100%;
      margin-left: 10px;
    }

    @media (min-width: $screen-lg) {
      form#contactAgency,
      a.btn-ag-bg-blue {
        width: auto;
        display: inline-block;
        float: left;
        clear: none;
        margin-bottom: 0;
      }

      a.btn-ag-bg-blue,
      form#contactAgency .btn-ag {
        font-size: 1em;
        margin-left: 0;
      }


      form#contactAgency {
        margin-right: 15px;
      }
    }
  }
}

body.sarpgn .advisor-info {
  text-align: center;
  margin-top: 30px;

  @media (min-width: $screen-md) {
    text-align: left;
  }

  a[href^="mailto"] {
    color: inherit;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

body.sarpgn .push-agency-phone {
  position: relative;
  display: inline-block;
  padding-top: 5px;
  padding-left: 20px;
  font-weight: normal;
  @include em-size(16);

  &:before {
    position: absolute;
    top: 6px;
    left: 3px;
    content: '';
    display: block;
    width: 30px;
    height: 32px;
    @extend .sprite;
    background-position: 0 0;
  }
}

body.sarpgn #collapseAgents {
  .row {
    p:first-child {
      text-transform: inherit;
    }
  }
}

/* Message SARPGN */
body.sarpgn .push-agency-message {

  .push-agency-people {

    .miss {
      //background: url('../../images/sarpgn/sarpgn-miss@2x.png') no-repeat;
      background-size: auto 100%;
      background-position: bottom center;
      height: 200px;

      @media (min-width: $screen-sm) {
        background-position: bottom center;
        padding-bottom: 0;
        background-size: 100% auto;
        margin-top: 0;
        height: 220px;
      }

      @media (min-width: $screen-md) {
        background-size: auto 100%;
      }

    }

  }

  .push-agency-city-name {
    padding: 20px 0;
  }

}

/* agent SARPGN */
body.sarpgn .push-agency-agent {

  .push-agency-people {

    .miss {
      //background: url('/images/sarpgn/sarpgn-miss@2x.png') no-repeat;
      background-size: auto 100%;
      background-position: bottom center;
      height: 220px;

      @media (min-width: $screen-sm) {
        background-position: bottom center;
        padding-bottom: 0;
        margin-top: 0;
        height: 200px;
      }

    }

  }

  .push-agency-cta {
    text-align: center;
    padding-top: 10px;

    @media (min-width: $screen-sm) {
      text-align: left;
    }
  }

}

body.sarpgn #collapseAgents {
  @extend .push-agency-bg;
  color: inherit;

  a {
    color: inherit;
  }
}
