ul {
  margin-left: 1.6em;
  margin-right: 1.6em;

  &.list-offres {
    list-style: none;
    padding: 0;

    hr {
      border: none;
      border-bottom: 1px solid $color-secondary-core-bundle;
    }

    p {
      margin: 15px 0;
    }

    .btn {
      &.btn-link {
        position: absolute;
        right: 0;
      }
    }
  }
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
}
