body.error-page {

  .main {
    background-color: #ebebeb;
    padding-top: 100px;
    margin-bottom: 3em;

    .section {
      margin-top: 3rem;
    }
    .push-navigation {
      background-color: white;
      text-align: left;
      padding-top: 2rem;
    }


    @include media(">=md") {
      padding-top: 196px;
    }
    .push-inner {
      padding: 11px;
      p {
        margin-top: 0;
        line-height: 1.3;
        font-size: 1rem;
      }
      p.cta {
        margin-top: auto;
      }
      span.icon-widget {
        width: 50px;
        border-radius: 26px 0 0 26px;
        font-size: .9375rem;
        background-color: #f0941d;
        color: white;
        margin: 10px auto;
        margin-bottom: 1rem;
        -webkit-box-shadow: 4px 8px 14px 0 rgba(216,221,230,.5);
        -moz-box-shadow: 4px 8px 14px 0 rgba(216,221,230,.5);
        box-shadow: 4px 8px 14px 0 rgba(216,221,230,.5);
      }

      span.icon-help {
        background-color: #f0941d;
      }

      span.icon-assistance {
        background-color: #0365a3;
      }

      span.icon-location {
        background-color: #6bc024;
      }


      span.icons-blue {
        color: $color-primary-core-bundle;
        display: block;
        font-weight: 700;
        text-decoration: none;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        -moz-transition: all .2s;
        transition: all .2s;
        padding-bottom: 1rem;
        .iicon {
          font-size : 2rem;
        }
        .iicon-logo, .iicon-user {
           width: 3.75rem;
           height: 3rem;
        }
        .iicon-logo.iicon-sarpsp-logo {
          width: 12rem;
          height: 3rem;
        }

        .iicon-habitation, .iicon-car {
          width: 4.75rem;
          height: 3rem;
        }


        .text {
          font-size: .875rem;
          text-transform: uppercase;
        }
      }
    }

    .btn {
      font-size: 1.05em !important;
    }

    ul li,
    ol li,
    p {
      color: #444444;
      line-height: 1.3;
      font-size: rem(18);
    }

    p {
      margin: 1.6em 0;
    }

    .inner {
      width: 800px;
      max-width: 100%;
      background-color: #fff;
      padding: 60px;
      margin: 30px auto;
      border-radius: 10px 0 10px 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
      color: #0374bc;

      @include media("<md") {
        padding: 20px;
      }

      .title {
        margin-bottom: 30px;
      }

      .btn {
        margin-top: 30px;
      }
    }

    h2 {
      font-size: 1.75rem !important;
    }
  }
}
body.error-page.sarpgn .main {
  @include media(">=md") {
    padding-top: 100px;
  }
}

body.error-page .main .inner.error-500 {
  margin: 0 auto 100px;
}
