.formula {
	.content {
		padding-top: 0;
		padding-bottom: 0;

        h2 {
          text-align: center;
          margin-bottom:20px;
        }

	}
	table {
		position: relative;

		caption {
			position: relative;
          	@include em-size(16);

			&:before {
				position: absolute;
				content: '';
				width: 74%;
				height: 60px;
				bottom: -311px;
				right: 0;
				z-index: 5;
				box-shadow: 0px -90px 120px 0 rgba(0, 0, 0, .2);
			}
			
			&:after {
				position: absolute;
				content: '';
				width: 300px;
				height: 60px;
				bottom: -311px;
				right: -300px;
				z-index: 6;
				background-color: #fff;
			}
		}
		
		i {
			font-style:normal;

			span {
				@include ir;
			}

			&.fa {
				@include em-size(28);

			}

			&.fa-underscore {
				position: relative;
				display: block;
				width: 100%;
				height: 20px;

				&:before {
					position: absolute;
					content: '';
					width: 16px;
					height: 3px;
					background-color: $colorOrange;
					font-weight: bold;
					left: 50%;
					margin-left: -10px;
					top: 10px;
				}
			}

		}

		td, th {
			&.col-space {
				width: 1%;
				padding: 0 !important;
				background-color: transparent;

				&:before {
					display: none;
				}
			}
		}
		td.col-space {
			background-color: #fff;
		}

		thead {
			th {
				position: relative;
				z-index: 10;
				vertical-align: top;
				padding-top: 30px;
				background-color: #fff;

				&:before {
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					height: 10px;
					width: 100%;
					background-color: lighten($colorOrange, 20%);
					border-radius: 10px 10px 0 0;
				}

				&:first-child {
					padding: 0;
					text-align: right;
					background-color: transparent;

					&:before{
						display: none;
					}
				}

				span {
					display: block;
					clear: both;

					&.formula-type {
						font-weight: normal;
						text-decoration: underline;
						line-height: 1.1em;
						margin-bottom: 5px;
						@include em-size(15);
					}
					&.formula-label {
						font-weight: bold;
						line-height: 1.1em;
						margin-bottom: 5px;
						@include em-size(27);
					}
					&.formula-description {
						font-weight: normal;
						font-style: italic;
						line-height: 1.2em;
						@include em-size(14);
					}
				}
			}
		}

		tbody {
			position: relative;
			z-index: 5;
			background-color: #fff;

			tr {
				border-bottom: 1px solid #fff;
			}

			td {
				width: 18%;
			}

			td[scope]{
				position: relative;
				z-index: 10;
				width: 24%;
				padding-right: 60px;
				line-height: 1.2em;
				vertical-align: middle;
				text-align: left;
				background-color: #c4c4c4;
				color: $colorAnthracite;
				border-radius: 10px 0 0 10px;

				span.help {
					position: absolute;
					right: 12px;
					top: 50%;
					background-color: $colorAnthracite;
					width: 30px;
					height: 30px;
					margin-top: -15px;
					text-align: center;
					line-height: 30px;
					border-radius: 15px;
					color: #c4c4c4;
					font-weight: bold;
					cursor: pointer;
					@include em-size(17);
					@include transition(color .2s);

					span.help-text {
						position: absolute;
						left: 47px;
						top: -8px;
						width: 240px;
						height: auto;
						border-radius: 10px;
						z-index: 15;
						display: inline-block;
						background-color: rgba(255, 255, 255, .9);
						padding: 15px;
						@include em-size(12);
						font-style: italic;
						font-weight: normal;
						line-height: 1.1em;
						color: $colorAnthracite;
						text-align: left;
					}

					&:hover {
						color: $colorOrange;

						span.help-text {
							color: $colorAnthracite;
						}
					}
				}
			}

			tr:nth-of-type(odd) td[scope] {
				background-color: #eeeeee;

				span.help {
					color: #eeeeee;

					span.help-text {
						color: $colorAnthracite;
					}

					&:hover {
						color: $colorOrange;

						span.help-text {
							color: $colorAnthracite;
						}
					}
				}
			}
		}
	}


    .legend {

      text-align: center;
      margin-top: -20px;
      margin-bottom: 40px;

      li {
        position: relative;
        display: inline-block;
        color: $colorOrange;
        margin-left: 20px;
        padding-left: 25px;


        &:first-child {
          font-style: italic;
          color: $colorText;
        }


        i {
          font-style:normal;

          &.fa {
            position: absolute;
            left:0;
            bottom:10px;
            @include em-size(35);
          }

          &.fa-underscore {
            span {
              @include ir;
            }

            &:before {
              position: absolute;
              content: '';
              width: 16px;
              height: 3px;
              background-color: #ff9600;
              font-weight: bold;
              left: 0;
              top: 0;
            }

          }

          &.fa-check {
            bottom:0;
            @include em-size(20);
            span {
              @include ir;
            }
          }

        }


      }


    }


	.action {
		text-align: center;
		padding-bottom: 60px;
	}

}

@media only screen and (max-width: $screen-md) {
	.formula {
		.content {
			.intro {
				@include em-size(15);
				padding: 0 20px;
			}
		}
		table {
			margin:0 auto 30px auto;
			display: block;

			caption:before {
				display: none;
			}

			td, th {
				&.col-space {
					display: none;
				}
			}
			i {
				font-style:normal;

				&.fa {
					@include em-size(20);

					span {
						@include ir;
					}
				}

				&.fa-underscore {

					&:before {
						margin-left: -9px;
					}
				}

			}
			thead {
				display: block;
			}
			tbody {
				display: block;
				z-index: 5;
				background-color: #fff;

				&:before {
					display: none;
				}

				tr {
					border-bottom: 1px solid #fff;
				}

				td,
				td[scope] {
					width: 100% !important;
					display: block !important;
					@include em-size(15);
				}
				td[scope] {
					padding: 12px 45px 12px 12px !important;
					text-align: center;
					border-radius: 0;
					background-color: $colorPrimary;
					color: #fff;

					span.help {
						background-color: #fff;
						color: $colorPrimary;

						span.help-text {
							position: absolute;
							left: -198px;
							top: 40px;
							background-color: $colorOrange;
							color: #fff;
						}
						
						&:hover {
							span.help-text {
								color: #fff;

								&:hover {
									color: #fff;
								}
							}
						}
					}
				}
				tr:nth-of-type(odd) td[scope] {
					background-color: $colorPrimary;
					color: #fff;

					span.help {
						color: $colorPrimary;

						&:hover {
							span.help-text {
								color: #fff;

								&:hover {
									color: #fff;
								}
							}
						}
					}
				}
			}

			&.formula_tab {
				td {
					vertical-align: middle;

					&:before {
						color: $colorAnthracite;
						font-weight: bold;
					}
				}
			}
		}
		.action {
			text-align: center;
			padding-bottom: 30px;
		}
	}
}