.section {
  text-align: left;

  img {
    max-width: 100%;
    height: auto;
  }

  // Pour vidéos
  iframe {
    border: 0;
    max-width: 100%;
  }

  .homepage & {
    margin-top: 3rem;
  }
}

.section-inner {
  position: relative;
  width: 910px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

// SECTION INTRO
.section-intro {
  margin: -30px auto 0 auto;

  @include media(">=lg") {
    margin-top: -60px;
  }

  .section-inner {
    background-color: #fff;
    border-top-left-radius: 10px;
    padding: 17px 27px;
    max-width: calc(100% - 34px);
    text-align: center;
  }

  .text-intro {
    font-size: rem(18);

    p {
      margin-bottom: 0;
    }

    @include media(">=md") {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

// SECTION avec fond gris
.section-grey {
  background-color: $section-background-core-bundle;

  .title.title-2-custom {
    span {
      background-color: $section-background-core-bundle;
    }
  }
}

// Si plusieurs accordéons se suivent,
// on réduit l'espace entre chaque section contenant un accordéon
.section-accordion {
  .section-inner {
    padding-bottom: 0;
  }

  & + .section-accordion {
    .section-inner {
      padding-top: 0;
    }
  }

  // Ajoute une marge à la section qui suit la dernière section-accordion
  & + .section:not(.section-accordion) {
    margin-top: 1rem;
  }
}

// Custom sections
#etudiants {
  margin-top: 10rem;

  .push-star {
    margin-top: -5rem;
  }
}

.section.section-cta {
  width: 100%;
}
