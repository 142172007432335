// Hide
.is-hidden {
  display: none !important;
}

// Show
.is-visible {
  display: block !important;
}

// Link
.is-link {
	cursor: pointer;
}

// Reset padding, margin, border
.no-padding-top {
  padding-top:0 !important;
}

.no-border-top {
  border-top:0 !important;
}

.no-margin-bottom {
  margin-bottom:0 !important;
}

// Underline
.underline {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Show desktop only
.is-desktop {
  display: none !important;
  pointer-events: none;
  touch-action: none;

  @media (min-width: $screen-md) {
    display: block !important;
    pointer-events: inherit;
    touch-action: inherit;
  }
}

// Show mobile only
.is-mobile {
  display: block !important;
  pointer-events: inherit;
  touch-action: none;

  @media (min-width: $screen-md) {
    display: none !important;
    pointer-events: inherit;
  }
}

// Disabled state
.is-disabled {
  pointer-events: none;
  touch-action: none;
}

// Error state
.is-error {
  color: $colorError !important;
}

// Success state
.is-success {
  color: $colorSuccess !important;
}
