table {
    width: 95%;
    margin:0 auto 60px auto;
    border-spacing:0;
}
table caption {
    caption-side: top;
    text-align:center;
    padding: 0 20px 60px 20px;
    line-height: 1.3em;
    width: 100%;
    clear: both;
    color: #3b3b3c;
    @include em-size(18);
}

/* Zebra striping */
tbody tr {
    background-color: #fff4e5;
}
tbody tr:nth-of-type(odd) {
    background-color: #fffaf2;
}
th {
    background-color: #fff;
    color: #3b3b3c;
    font-weight: bold;
}
th:first-child {
    border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
}
th:last-child {
    border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
}
td, th {
    padding: 15px 20px;
    border: 0;
    color: $colorAnthracite;
    text-align: left;
    @include em-size(18);
}
td {
    text-align: center;
    color: $colorOrange;
}

/* tableaux responsives */
@media only screen and (max-width: 991px) {
    /* Force table to not be like tables anymore */
    table {
        margin:0 auto 30px auto;
        display: block !important;

        caption,
		thead,
		tbody,
        th,
        td,
		td[scope],
		td[headers],
        tr {
            display: block !important;
			width: 100% !important;
        }

        caption {
            padding-bottom: 30px;
            @include em-size(15);
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tbody tr td,
        tbody tr:nth-of-type(odd) td {
            background-color: #fff;
            color: $colorOrange;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        td {
            /* Behave like a "row" */
            border: none;
            position: relative;
            color:$colorOrange;
            font-weight:bold;
            padding-left: 81%;
            padding-right: 0 !important;
            padding-bottom:20px;
            line-height:17px;
            white-space: normal;
            text-align:center;
            border-bottom: 1px solid #eee;

            &:before {
                /* Now like a table header */
                position: absolute;
                top: 10px;
                left: 10px;
                width: 19%;
                padding-right: 0;
                white-space: nowrap;
                text-align:left;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        td:before { content: attr(data-title); }
        .hidden_responsive { display:none !important; }

        tr:nth-of-type(odd) td,
        tr:nth-of-type(odd) td a {
            color:$colorOrange;
        }
        tr:nth-of-type(odd) td:before {
            color:$colorAnthracite !important;
            font-weight: bold !important;
        }

        tr:nth-of-type(even) td a,
        tr:nth-of-type(even) td a:hover {
            color:$colorOrange !important;
        }
    }
}
