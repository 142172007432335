.baseline-core-bundle {
  display: none;
  vertical-align: top;
  position: absolute;
  left: 20px;
  top: 55px;
  text-align: left;
  padding: 30px 0 0 0;
  line-height: 1.2;
  color: $color-primary-core-bundle;
  @include em-size(14);

  .header-inverted:not(.scrolling) & {
    color: #fff;
  }

  @include media("<sm") {
    body.homepage.is-connected & {
      left: 12px;
      top: 45px;
    }
  }

  strong {
    display: block;
    @include em-size(14);
  }

  @include media(">=sm") {
    display: inline-block;
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
    padding-left: 7px;

    body.homepage & {
      color: #fff;
    }

    .homepage.scrolling & {
      color: $color-primary-core-bundle;
      @include em-size(14);

      strong {
        display: block;
        @include em-size(14);
      }
      em {
        display: block;
      }
    }
  }

  @include media(">=md") {
    body.homepage & {
      @include em-size(23);

      strong {
        @include em-size(23);
      }
    }

    body.homepage.is-connected:not(.scrolling) & {
      padding-left: 20px;
      top: 5px;
    }
  }

  @include media(">=lg") {
    body.homepage & {
      @include em-size(29);

      strong {
        @include em-size(29);
      }
    }
  }
}
