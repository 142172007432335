.cookie {
  position: fixed;
  z-index:9999;
  width:250px;
  bottom:0;
  right:20px;
  padding: 30px 10px 10px 10px;
  background-color: rgba(255, 255, 255, .9);
  color: $colorBlue;
  box-shadow: 0 0 5px rgba(0,0,0,.4);
  @include em-size(12);
  text-align: left;

  a {
    text-decoration: underline;
  }

  .btn-ag-cookie-info {
    margin: 5px 0 0;
    display: block;
  }

  .btn-ag-cookie-ok {
    display: block;
    zoom: 1;
    cursor: pointer;
    margin-top: 10px;

    &:focus {
      background-color: #333;
      color: #fff;
      outline: 1px dotted #fff;
    }
  }

  .btn-ag-cookie-close {
    text-decoration: none;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    font-style: normal;
    font-size: 2em;
    line-height: 25px;
    background: none;
    cursor: pointer;
    border: none;

    &:focus {
      outline: 1px dotted #fff;
    }
  }
}
