.list-content {
  padding: inherit;
  margin: inherit;

  li {
    padding: inherit;
    margin: inherit;
    list-style: inherit;
  }
}

.list {
	padding: 0;
	margin: 0;
}

.list-item {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

/* FORMULES AUTOMOBILES */
.list-formulas {
	.list-item {
		padding-bottom: 30px;
		cursor: pointer;

		&:hover .formula-container > span.formula-link-more a {
			text-decoration: underline;
		}
	}

	.formula-container {
		display: block;
		padding: 25px 3px;
		background-color: #fff;
		border-radius: 10px;
		margin: 3px;
		border: 1px solid #c1c1c1;
		@include transition(height .2s);

		> span {
			display: block;
			padding: 0 22px;
			margin: 4px 0;

			&.formula-type {
				font-weight: normal;
				text-decoration: underline;
				line-height: 1.1em;
				margin-bottom: 5px;
				@include em-size(16);
			}

			&.formula-label {
				font-weight: bold;
				line-height: 1.1em;
				text-transform: uppercase;
				color: $colorAnthracite;
				margin-bottom: 5px;
				@include em-size(28);
			}

			&.formula-description {
				font-weight: normal;
				font-style: italic;
				line-height: 1.2em;
				margin-bottom: 7px;
				@include em-size(18);
			}

			&.formula-details {
				display: none;
				@include transition(all .2s);

				i {
					color: $colorOrange;
					display: inline-block;
					margin-right: 5px;
				}
			}

			&.formula-price {
				background-color: #eeeeee;
				margin: 20px 0;
				padding: 0 25px;
				text-align: center;
				font-weight: bold;
				color: $colorBlue;
				@include em-size(46);
				@include transition(color .2s);

				span {
					@include em-size(8);
				}
				sup {
					margin-left: 2px;
					font-weight: normal;
					@include em-size(10);
				}
			}

			&.formula-link-more {
				display: none;
				@include transition(all .2s);

				a {
					color: $colorPrimary;
					text-decoration: none;
					font-weight: bold;
					@include em-size(16);

					&:hover {
						text-decoration: underline;
					}
				}
			}

			&.formula-link-estimation {
				display: none;
				@include transition(all .2s);
				margin-bottom: 20px;

				a {
					@include em-size(18);
				}
			}

			&.formula-promotion {
				display: none;
				background-color: #eeeeee;
				margin: 20px 20px 10px 20px;
				padding: 10px;
				text-align: center;

				.formula-promotion-label {
					display: block;
					color: $colorOrange;
					font-weight: bold;
					@include em-size(24);
                    text-decoration: none;
				}

				.formula-promotion-text {
					display: block;
					line-height: 1.2em;
					@include em-size(15);
				}
			}
		}
	}

	.active,
	.selected {
		cursor: pointer;

		.formula-container {
			border: 4px solid #ebebeb;
			padding: 22px 0;
			box-shadow: 0 0 8px 4px rgba(0, 0, 0, .1);

			> span {
				&.formula-link-more {
					display: none !important;
				}

				&.formula-price {
					color: $colorOrange;
					margin: 0;
					background-color: transparent;
				}

				&.formula-link-estimation,
				&.formula-promotion,
				&.formula-details {
					display: block;
				}
			}
		}
	}

	.active {
		cursor: default;
	}
}

@media only screen and (min-width: $screen-md) {
	.list-formulas {
		@include clearfix;

		 .list-item {
			 display: inline-block;
			 width: 25%;
			 float: left;

			 .formula-container {
				 margin: 50px 3px 3px;
				 @include transition(margin .2s);
			 }

			 &.active,
			 &.selected {
				 .formula-container {
					 margin: 3px;
				 }
			 }
		}
	}
}

@media only screen and (min-width: $screen-lg) {
  .list-formulas {
    .list-item {
      .formula-container {
        min-height: 320px;
      }
    }
  }
}
