.agency-contact,
.agency-home,
.agency-localisation,
.agency-opening-hours,
.agency-job-offers,
.agency-news,
.agency-view {
  .footer-core-bundle{
    z-index: 4;
  }
  .container-inner {
    z-index: 5;
    background: url('../../images/mdp/agency-header-bg.png') no-repeat top center;
    background-size: cover;
  }

  #pre-footer {
    z-index: 2;
  }

  .iicon {
    stroke: none;
  }

  .content:first-child {
    position: relative;
    padding-bottom: 60px;

    h1 {
      color: $colorPrimary;
      font-weight: normal;
      text-align: center;
      text-transform: inherit;
      margin: 10px 0 7px;
      @include em-size(18);

      & strong {
        padding-top: 6px;
        display: block;
      }

      @media (min-width: $screen-sm) {
        margin-bottom: 15px;
        @include em-size(26);

        & strong {
          padding-top: 9px;
        }
      }
    }

    .agency-intro {
      text-align: center;
      width: 100%;
      display: block;
      margin: 110px 0 30px;

      .agency-intro-inner {
        display: inline-block;
        margin: 0 auto;
        max-width: 775px;
      }

      @media (min-width: $screen-lg) {
        padding: 45px 0 0;
        margin-bottom: 25px;
        margin-top: 130px;

        p {
          margin: 0 auto 20px;
        }
      }
    }

    .form-search {
      padding: 10px 20px;
      margin: 0 auto;
      max-width: 665px;

      .fields {
        position: relative;
      }

      label {
        display: block;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }

      input {
        border: 0;
        border-radius: 10px;
        padding: 20px 65px 20px 20px;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, .2);
        background-color: #fff;
        color: #333;

        &.loader-img {
          background-position: calc(100% - 60px) 20px;
        }
      }

      button {
        position: absolute;
        right: 8px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 6px;
        padding: 0;
        margin: 0;
        text-align: center;
        cursor: pointer;

        .iicon {
          margin-top: 1px;
          -moz-transform: scale(-1.3, 1.3);
          -webkit-transform: scale(-1.3, 1.3);
          -o-transform: scale(-1.3, 1.3);
          -ms-transform: scale(-1.3, 1.3);
          transform: scale(-1.3, 1.3);
          // @include scale(1.3);
        }
      }

      .autocomplete-no-find {
        margin-left: -20px;
      }

      @media (min-width: $screen-md) {
        margin-top: 10px;
        width: 650px;

        label {
          margin-bottom: 25px;
          text-align: left;
          padding-left: 5px;
          @include em-size(22);
        }

        input {
          padding: 25px 100px 25px 25px;
          font-weight: bold;
          @include em-size(18);

          &.loader-img {
            background-position: calc(100% - 68px) 25px;
          }
        }

        button {
          right: 15px;
          bottom: 15px;
        }
      }
    }
  }

  .agency-home-description {
    position: relative;
    padding: 20px 20px 220px 20px;
    background-color: #fff;
    text-align: left;

    &::after,
    &::before {
      position: absolute;
      top: 0;
      display: inline-block;
      width: 1000px;
      height: 100%;
      background-color: #fff;
      content: "";
    }

    &::after {
      right: -999px;
    }

    &::before {
      left: -999px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    img {
      position: absolute;
      right: 50%;
      margin-right: -120px;
      bottom: 0;
      pointer-events: none;
    }

    @media (min-width: $screen-sm) {
      padding: 50px 280px 40px 0;

      img {
        display: inline-block;
        right: 0;
        margin-right: 0;
      }
    }
  }

  .content {
    &.no-agency {
      padding: 0 0 40px;
      z-index: 2;

      .list-agency-title {
        color: red;
        @include em-size(16);
      }
    }

    &.list-agency {
      background-color: $colorLightGrey;

      .list-agency-title {
        color: $colorPrimary;
        @include em-size(16);
      }

      .push-agency-simple {
        border-radius: 10px 0 10px 0;
      }
    }
  }

  h2 {
    margin-top:20px;
  }
}

.agency-localisation,
.agency-news,
.agency-view,
.agency-job-offers,
.agency-opening-hours {
  .content {
    background-color: #fff;
  }

  .push-agency-details {
    background: none;
  }



  .page-content {
    padding-top: 100px;
  }

  @media (min-width: $screen-md) {
    .page-content {
      padding-top: 120px;
    }
  }

  @media (min-width: $screen-lg) {
    .page-content {
      padding-top: 150px;
    }
  }
}

.agency-news {
  @media (min-width: $screen-md) {
    .content {
      background-color: $colorLightGrey;
      background-image: none;
      text-align: center;
    }

    .news-block {
      width: 100%;
      display: block;;
      text-align: center;

      .news-block-inner {
        display: inline-block;
        margin: 0 auto;
        padding: 35px;
        width: 100%;
        max-width: 630px;
        text-align: center;
        border-radius: 15px 0 15px 0;
        background-color: #fff;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, .05);

        iframe {
          display: inline-block;
          margin: 0 auto;
          text-align: left;
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    .news-block {
      padding: 40px 0;

      .news-block-inner {
        max-width: 890px;
      }
    }
  }
}

.agency-view {
  #header {
    background-color: none;
  }

  .content {
    background-color: #fff;
  }
}

.agency-contact {
  .content:first-child {
    position: relative;
    z-index: 2;
    padding-bottom: 0;
  }

  .content-inner {
    margin-top: 30px;

    .content-text {
      position: relative;
      margin-top: 85px;
      z-index: 2;
      width: 100%;
      text-align: left;
      padding: 25px 15px 40px;
      background-color: #fff;
      border-radius: 15px 0 0 0;

      &::after,
      &::before {
        position: absolute;
        display: block;
        width: 1000px;
        height: 100%;
        top: 100px;
        content: '';
        background: #fff !important;
      }

      &::after {
        right: -999px;
      }

      &::before {
        left: -999px;
      }

      p.text-center {
        text-align: center;
      }

      p.notes {
        font-style: italic;
        padding-top: 10px;

        a {
          color: $colorText;
          transition: all .2s ease;

          &:hover {
            color: #000;
          }
        }
      }

      .breadcrumb {
        padding: 0;
        color: #666;

        a {
          color: #666;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      @media (min-width: $screen-md) {
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-top: 135px;

        p.notes {
          width: 100%;
          margin-left: 0;
        }
      }

      @media (min-width: $screen-lg) {
        width: calc(100% - 120px);
        margin-left: 60px;
      }
    }
  }

  h2 {
    margin-bottom: 10px;
    @include em-size(18);
    font-weight: normal;
    text-align: center;

    @media (min-width: $screen-sm) {
      @include em-size(28);
    }
  }

  form {
    .radio {
      display: inline-block;
      margin-right:20px;
    }

    .button-row {
      margin-top: 30px;

      @media (max-width: $screen-sm) {
        text-align: center;
      }
    }
  }
}

body.agency-view,
body.advisor {
  h2 {
    line-height: 112%;
  }

  .content {
    .btn {
      margin-top:10px;
    }
  }
}

.nav-agency {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  text-align: center;
  background-color: #fff;

  .toggle-menu {
    position: relative;
    cursor: pointer;
    display: block;
    height: 60px;
    z-index: 4;
    text-align: left;
    line-height: 60px;
    padding: 0 5px;
    font-weight: 600;
    text-transform: uppercase;
    color: $colorBlue;
    background-color: #fff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);
    @include em-size(16);

    .text {
      display: inline-block;
      width: calc(100% - 60px);
      text-align: center;
      height: 60px;
    }

    .iicon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      display: inline-block;
      width: 60px;
      height: 60px;
      pointer-events: none;
      text-align: center;
      background-color: #fff;
      border-left: 1px solid $colorGrey;

      &::before {
        position: absolute;
        right: 18px;
        top: 22px;
        font-weight: bold;
        color: $colorBlue;
        @include em-size(20);
        @include transition(all .2s);
      }
    }
  }

  ul {
    position: absolute;
    // top: 60px;
    display: block;
    left: 0;
    opacity: 0;
    padding: 0;
    z-index: 2;
    margin-bottom: 15px;
    pointer-events: none;
    width: 100%;
    text-align: center;
    background-color: #fff;
    border-bottom: 4px solid $colorLightGrey;
    @include translate3d(0, -20px, 0);
    @include transition(all .2s);
  }

  li {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    padding: 0;
  }

  a {
    position: relative;
    cursor: pointer;
    display: block;
    height: 60px;
    line-height: 60px;
    padding: 0 5px;
    font-weight: 600;
    color: $colorPrimary;
    text-decoration: none;
    @include em-size(16);

    &.active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: $colorGreen;
    }

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      background-color: $colorLightGrey;
    }

    &.active {
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        height: 4px;
        width: 100%;
        display: block;
        background-color: $colorGreen;
      }
    }
  }

  body.agency-tabs-opened & {
    .toggle-menu {
      .iicon::before {
        @include rotate(180deg);
      }
    }

    ul {
      position: relative;
      opacity: 1;
      pointer-events: inherit;
      @include translate3d(0, 0, 0);
    }
  }

  @media (min-width: $screen-md) {
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);

    .toggle-menu {
      display: none;
    }

    ul {
      position: relative;
      padding: 0;
      border: 0;
      margin: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 1;
      width: auto;
      pointer-events: inherit;
      @include translate3d(0, 0, 0);
    }

    li {
      display: inline-block;
      width: auto;
      min-width: 170px;
      height: 60px;
      justify-content: space-between;
      border-left: 1px solid $colorInputBg;

      &:last-child {
        border-right: 1px solid $colorInputBg;
      }
    }

    a {
      height: 60px;
      padding: 0 27px;
      text-transform: uppercase;
      line-height: 60px;
      font-weight: 600;

      &.active {
        position: relative;

        &::after {
          height: 5px;
          bottom: 0;
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    a {
      padding: 0 46px;
    }
  }
}

body.agency-job-offers {
  .content {
    background-color: #fff;
    border-top: 1px solid $colorLightGrey;
    text-align: center;

    .job-loader {
      text-align: center;
      margin: 30px;
    }

    #job-offers {
      p {
        margin: 15px 0;
        color: $colorTextBis;
        @include em-size(16);
      }
    }

    ul.list-offres {
      list-style: none;
      padding: 10px 20px;

      li {
        position: relative;
        text-align: left;
        margin-bottom: 75px;
      }

      h3 {
        color: $colorPrimary;
        margin: 10px 0 20px;
        line-height: 1.2;
        @include em-size(24);
      }

      span {
        display: block;

        &.date {
          color: $colorBlueLight;
          @include em-size(14);
        }

        &.infos {
          color: $colorPrimary;
          @include em-size(16);
        }
      }

      hr {
        border: none;
        border-bottom: 1px solid $colorSecondary;
      }

      .btn {
        &.btn-link {
          position: absolute;
          right: 0;
          color: $colorSecondary;
          text-decoration: none;
          font-weight: bold;
          padding: 10px 30px 10px 10px;

          .iicon {
            position: absolute;
            color: $colorSecondary;
            display: inline-block;
            right: 0;
            top: 12px;
            overflow: visible;
            transition: all .2s ease-out;
            @include em-size(26);
          }

          &:hover {
            .iicon {
              font-weight: bold;
              transform: translate3d(5px, 0, 0);
            }
          }
        }
      }

      @media (min-width: $screen-md) {
        padding: 10px 35px;
      }

      @media (min-width: $screen-lg) {
        padding: 10px 65px;
      }
    }
  }
}

.footer-core-bundle .agencie-link {
  display: none !important;
}

.cookie-core-bundle {
  display: none!important;
}

#tarteaucitronIcon {
  z-index: 1!important;
  #tarteaucitronManager {
    padding: 0 15px 65px 0!important;
  }
}

body:not(.agency-home) .header-core-bundle .header-inner-core-bundle {
  background-color: white;
}
