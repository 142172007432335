$accordion-width: 810px;

.js-expandmore {
  width: $accordion-width;
  max-width: 100%;
  margin: 10px auto 0 auto;
}

// Bouton
.js-expandmore-button {
  border: rem(2) solid $color-secondary-core-bundle;
  border-radius: 10px 0;
  padding: 22px 32px;
  background-color: #fff;
  text-align: left;
  width: 100%;
  font-weight: bold;
  color: $color-secondary-core-bundle;
  font-size: rem(18);
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  .iicon {
    margin-left: auto;
    min-width: 1.5rem;
    height: 1.5rem;
    transform: rotate(180deg);
    stroke-width: 10%;
    fill: $color-secondary-core-bundle;
    color: $color-secondary-core-bundle;
    stroke: $color-secondary-core-bundle;
  }

  // Ouvert
  &[aria-expanded="true"] {
    border-bottom: 0;
    border-bottom-right-radius: 0;

    .iicon {
      transform: rotate(0deg);
    }
  }
}

// Panel
.js-to_expand {
  width: $accordion-width;
  max-width: 100%;
  margin: -1px auto 10px auto;
  text-align: left;
  padding: 0 32px;

  &[aria-hidden="true"],
  &[data-hidden="true"] {
    display: none;
  }

  &:not([aria-hidden="true"]),
  &:not([data-hidden="true"]) {
    border: rem(2) solid $color-secondary-core-bundle;
    border-top: 0;
    border-radius: 0 0 10px 0;
  }
}

.js-to_expand_inner {
  padding-bottom: 22px;
}

// Animation d'ouverture et fermeture
.animated-expandmore__to_expand {
  display: block;
  overflow: hidden;
  opacity: 1;
  visibility: visible;

  // Ouvert
  &:not([data-hidden="true"]) {
    transition: visibility 0s ease, max-height 2s ease;
  }

  // Fermé
  &[data-hidden="true"] {
    display: block;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

// Si pas de JS
.expandmore__button[aria-expanded="false"] > .expandmore__symbol::before,
.expandmore__button[data-expanded="false"] > .expandmore__symbol::before {
  content: "+ ";
}

.expandmore__button[aria-expanded="true"] > .expandmore__symbol::before,
.expandmore__button[data-expanded="true"] > .expandmore__symbol::before {
  content: "− ";
}

.expandmore__button {
  background: none;
  font-size: inherit;
  color: inherit;
}

// Accordeon mobilite

.full-block-expand {
  width: 810px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 10px 20px #00000012;
  border-radius: 13px 0;
  overflow: hidden;

  div.js-to_expand {
    border: none;
    margin: 0;
  }

  .block-accordeon-mobilite {

    svg.iicon {
      color: #a2a2a2;
      stroke: #a2a2a2;
    }

    &.js-expandmore {

      margin: 0;

      button.js-expandmore-button[aria-expanded=false] {
        border: none;
      }

      button.js-expandmore-button[aria-expanded=true] {
        border: none;
        border-bottom: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

div.section-accordion div.section-inner {
  margin: 10px auto;
}
