// Float blocks bad behavior
.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// Screen reader only
.sr-only {
  border: 0 !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
