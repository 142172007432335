#content {
  position: relative !important;
}

.content {
  border-top:1px solid #dadada;
  padding: 30px 0 15px;
  border-top: 0;
  z-index: 3;

  body.homepage & {
      border-top:0;
      padding: 0;
  }

  body.quoterequest & {
      border-top:1px solid $colorOrange;
      padding: 0;
  }

  @media (min-width: $screen-sm) {
      padding: 40px 0;
  }
}

ul:not(.list-puce),
ol:not(.list-puce) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.personnage {
  display: block;
  width:176px;
  height: 200px;
  background: url('../../images/common/perso2X.png') no-repeat top center;
  background-size: 176px 200px;
}

.france {
  display: block;
  height: 239px;
  background: url('../../images/mdp/carte-france.svg') no-repeat top center;
  background-size: contain;
}

.notes {
  margin-top:30px;
  @include em-size(12);

  ul {
    margin-top: 10px;
    margin-left: 20px;
    padding-left: 0;
  }
}

a[href^=tel] {
  color: inherit;
  text-decoration:inherit;
}

/* column same height http://getbootstrap.com.vn/examples/equal-height-columns/*/
@media (min-width: $screen-md) {
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }
}

.image-right {
  float: right;
  padding-left: 15px;

  @media (max-width: $screen-sm) {
    float: none;
    padding: 10px;
  }
}

.image-left {
  float: left;
  padding-right: 15px;

  @media (max-width: $screen-sm) {
    float: none;
    padding: 10px;
  }
}

.container-separation {
  border-top:1px solid #dadada;
}

.iicon-picto-perso-EP {
  &:before {
    color: inherit !important;
  }
}
