.nav-top-core-bundle {
  position: relative;
  display: block;
  width: 100%;
  z-index: 7;
  height: 40px;
  overflow: visible; // to delete
  text-align: left;
  margin: 0;
  padding: 0;
  background-color: $grey-light-core-bundle;

  li {
    margin: 0;
    display: block;

    a {
      display: block;
      height: 40px;
      padding: 0 20px;
      line-height: 40px;
      color: $color-primary-core-bundle;
      text-decoration: none;
      @include em-size(13);
      @include transition(all .2s);

      &:hover {
        background-color: darken($grey-light-core-bundle, 5%);
      }

      &.active {
        background-color: $color-primary-core-bundle;
        color: #fff;
      }

      &.menu-item-top-empty {
        position: relative;
        font-weight: bold;

        .iicon {
          position: absolute;
          right: 20px;
          top: 13px;
          @include rotate(90deg);
          @include transition(all .2s);
        }

        &:hover {
          color: #fff;
          background-color: $color-primary-core-bundle;

          .iicon {
            top: 15px;
          }
        }
      }
    }

    &:not(.is-mobile) {
      opacity: 0;
      pointer-events: none;
    }
  }

  &:hover {
    li a.menu-item-top-empty {
      background-color: $color-primary-core-bundle;
      color: #fff;
    }
  }

  .nav-top-opened & {
    z-index: 10;

    li {
      a.menu-item-top-empty {
        position: relative;
        font-weight: bold;

        .iicon {
          @include rotate(270deg);
        }
      }

      &:not(.is-mobile) {
        opacity: 1;
        pointer-events: inherit;
        background-color: #fff;
      }
    }
  }

  @include media(">=md") {
    li {
      display: inline-block;

      a {
        width: 100%;
      }

      &:not(.is-mobile) {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  .search-form-in-menu-opened & {
    z-index: 3;
  }
}

.nav-icons-core-bundle {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 20px;
  @include transition(all .2s);

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  li {
    padding: 0 10px;
    margin: 0;
    list-style-type: none;
    @include transition(all .2s);

    a,
    button {
      color: $color-primary-core-bundle;
      padding: 0;
      display: block;
      font-weight: bold;
      text-decoration: none;
      @include transition(all .2s);

      .header-inverted:not(.scrolling) & {
        color: #fff;
      }

      .iicon {
        @include em-size(26);
      }

      span.text {
        padding-top: 2px;
        text-transform: uppercase;
        font-weight: bold;
        @include em-size(14);
      }
    }

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      background-color: transparent;
      margin: 0;

      span.text {
        padding-top: 8px;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @include media(">=md") {
    top: 55px;

    body:not(.homepage) &,
    .search-form-in-menu-opened &,
    .scrolling & {
      top: 22px;
    }
  }

  .homepage & {
    li.nav-icons-item-search {
      display: none;
    }
  }

  // Picto ESPACE PERSO orange si connecté
  li.nav-icons-item-user {
    .iicon {
      stroke: none;
    }

    a,
    button {
      .is-connected.scrolling &,
      .is-connected.cms & {
        color: $color-third-core-bundle;
      }
    }
  }
}

.header-inverted:not(.scrolling) {
  .logo-core-bundle {
    em {
      display: none;
    }

    strong {
      display: none;
    }
  }
}

.big-nav {
  position: relative;
  z-index: 35;
}

.bm-item-list {
  z-index: 35;
  padding: 60px 20px;
  min-height: 100%;

  > ul.bm-item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0 0 30px 0;
    min-height: 100%;
    overflow-y: auto;

    li {
      display: block;
      width: 100%;
    }

    li.button {

      a {
        margin: 10px auto;
        padding: 20px 0px;
        width: 100%;
        white-space: normal;
      }
    }

    a {
      cursor: pointer;
      position: relative;
      display: block;
      line-height: 1.2;
      color: #fff;
      text-decoration: none;
      @include transition(all .2s);

      &:hover {
        text-decoration: none;
      }
    }

    > li {
      &:not(.menu-item-logout):not(.menu-item-welcome):not(.button) {
        border-bottom: 1px solid rgba(255, 255, 255, .5);
      }

      > a {
        padding: 15px 0;
        font-weight: bold;
        text-transform: uppercase;
      }

      > ul {
        padding: 0;
        margin: 0;

        > li {
          a {
            padding: 10px;
            text-transform: uppercase;
            @include em-size(14);

            &:hover {
              text-decoration: underline;
            }
          }

          &:last-child {
            margin-bottom: 20px;
          }
        }
      }

      &.has-children {
        position: relative;

        > a:hover {
          padding-left: 10px;
        }

        > a .iicon {
          position: absolute;
          right: 10px;
          top: 18px;
          display: inline-block;
        }

        > ul {
          max-height: 0;
          transition: max-height .6s ease-out;
          overflow: hidden;
        }

        &.opened {
          > a:hover {
            padding-left: 0;
          }

          > a::after {
            position: absolute;
            content: "_";
            right: 11px;
            top: -14px;
            font-size: 35px;
          }

          > a .iicon {
            display: none !important;
          }

          > ul {
            max-height: 500px;
            transition: max-height .6s ease-in;
          }
        }
      }
    }

    @include media(">=md") {
      padding-bottom: 35px;
      margin-bottom: 50px;

      > li {
        > a {
          padding: 30px 0;
        }

        > ul {
          > li {
            a {
              padding: 20px;
            }
          }
        }

        &.has-children {
          > a .iicon {
            top: 32px;
          }

          &.opened {
            > a::after {
              top: 1px;
            }
          }
        }
      }
    }
  }

  .button {
    padding-top: 30px;
    .btn-skinny {
    background-color: $color-primary-core-bundle;
    border: 2px solid white;

      &:hover {
        background-color: white;
        color: $color-primary-core-bundle;
      }
    }
  }

  .bm-item > .button ~ .button {
    padding-top: 0;
  }

  @include media(">=md") {
    padding: 100px 100px 100px 60px;
  }
}
