.citation-mobilite {

  .citation-block {
    border-radius: 15px 0 15px 0;
    overflow: hidden;
    box-shadow: 0 10px 20px #00000012;

    .citation-banner {
      height: 120px;
    }

    .citation-bottom {
      display: flex;
      justify-content: center;

      .citation-block-text {
        position: relative;
        max-width: 400px;
        margin: 70px 50px;

        .citation-text {
          text-align: justify;
          font-size: 1.2em;
          font-weight: 600;
          color: #757575;
        }

        .citation-quotation-mark {
          width: 30px;
          height: auto;

          &.citation-quotation-mark-left {
            position: absolute;
            top: -20px;
            left: -40px;
          }

          &.citation-quotation-mark-right {
            position: absolute;
            bottom: -20px;
            right: -40px;
          }
        }
      }
    }
  }
}








