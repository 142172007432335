.container-core-bundle {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;

    @include media(">=xl") {
        padding: 0;
    }

    p {
        margin: 1.6em 0;
    }
}
