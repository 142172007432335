div.section-image-mobilite {
  div.section-inner {
    padding: 0;
  }

  .bloc-image-mobilite {
    position: relative;
    display: block;
    height: 200px;
    overflow: hidden;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    cursor: default;

    a {
      cursor: pointer;
    }

    &.bloc-image-mobilite-full-width {
      width: 100%;
    }

    .image-mobilite-picture {
      filter: brightness(80%);
      background-size: cover;
      background-position: center;
      display: block;
      height: 100%;
      transform: scale(1, 1);
      transition: transform 0.6s ease;
    }

    .image-mobilite-text-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      color: #fff;
      font-size: 1.9em;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1.1;
      word-break: break-word;
    }

    &:hover {
      .image-mobilite-picture {
        transform: scale(1.2, 1.2);
        transition: transform 5s ease;
      }
    }
  }
}

@media only screen and (max-width: $phablet) {
  div.section-image-mobilite {
    .bloc-image-mobilite {
      .image-mobilite-text-overlay {
        font-size: 1.7em;
      }
    }
  }
}

// DUAL IMAGE MOBILITE

div.section-dual-image-mobilite {
  div.section-inner {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .bloc-image-mobilite {
    position: relative;
    display: block;
    height: 200px;
    overflow: hidden;
    width: 50%;
    max-width: 100%;
    margin: 0;
    cursor: default;

    a {
      cursor: pointer;
    }

    .image-mobilite-picture {
      filter: brightness(80%);
      background-size: cover;
      background-position: center;
      display: block;
      height: 100%;
      transform: scale(1, 1);
      transition: transform 0.6s ease;
    }

    .image-mobilite-text-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      color: #fff;
      font-size: 1.9em;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1.1;
      word-break: break-word;
    }

    &:hover {
      .image-mobilite-picture {
        transform: scale(1.2, 1.2);
        transition: transform 5s ease;
      }
    }
  }
}

@media only screen and (max-width: $phablet) {
  div.section-dual-image-mobilite {
    .bloc-image-mobilite {
      .image-mobilite-text-overlay {
        font-size: 1.7em;
      }
      width: 100%;
    }
  }
}
