body.error-page {
  background-color: $colorLightGrey;

  .main {
    padding-top: 100px;
    padding-bottom: 2em;

    ul li,
    ol li,
    p {
      margin-top: 0;
      margin-bottom: 10px;
      color: $colorText;
      line-height: 1.3;
      font-size: rem(18, 1);
    }

    .inner {
      width: 800px;
      max-width: 100%;
      background-color: #fff;
      padding: 60px;
      margin: 30px auto;
      border-radius: 10px 0 10px 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
      color: #0374bc;

      .title {
        margin-bottom: 30px;
      }

      .btn-ag {
        margin-top: 30px;
      }
    }

    @media (min-width: $screen-md) {
      padding-top: 196px;

      .inner {
        padding: 40px 60px;
      }
    }
  }
}
