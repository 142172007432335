h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
}

.title {
  display: block;
  position: relative;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 20px;
  color: $color-primary-core-bundle;
}

.title-1 {
  font-size: rem(30);
}

.title-2 {
  font-size: rem(28);
}

.title-3 {
  font-size: rem(24);
}

.title-4 {
  color: $grey-dark-core-bundle;
  font-size: rem(20);
}

.title-5 {
  margin-bottom: 0;
  color: $grey-dark-core-bundle;
  font-size: rem(18);
}

// Titres avec traits de chaque côté
.title-2-custom {
  text-align: center;
  font-weight: bold;
  font-size: rem(23);
  display: flex !important;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 3px solid $color-primary-core-bundle;
    opacity: .7;
    margin: auto .5em; /* 0.5em : espace entre texte et bords */

    /* + IE */
    display: inline;
    height: 0;
  }

  strong {
    font-weight: inherit;
  }

  @include media(">=md") {
    font-size: rem(28);
  }
}

// Titres pour les pages CMS
body.cms {
  main {
    h2 {
      @extend .title;
      @extend .title-2;
    }

    h3 {
      @extend .title;
      @extend .title-3;
    }
  }
}

// Titres pour la HP instit
body.institutionnelle,
body.sonata-bc {
  .editable,
  .preview,
  .medium-editor-element,
  .section-wysiwyg {
    h2 {
      @extend .title;
      @extend .title-2;
    }

    h3 {
      @extend .title;
      @extend .title-3;
    }
  }
}
