.search-form-core-bundle, .search-form-sarpgn-core-bundle , .search-form-sarpsp-core-bundle {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 0 40px;
  margin: 0;
  z-index: 6;
  background-color: $color-primary-core-bundle;
  pointer-events: none;
  opacity: 0;
  text-align: center;
  @include transition(all .2s);
  @include translate3d(0, 0, 0);

  p {
    opacity: 0;
    margin: 1.25em 0;
  }

  form {
    position: relative;
    padding: 0;
    margin: 0;
    height: 72px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $shadow-core-bundle;
    transition: all .4s;

    select,
    input,
    textarea,
    button {
      border-radius: 0;
      padding: 0 25px;
      text-align: left;
      appearance: none;
      font-family: inherit;
      color: #222;
      background-color: transparent;
      border: 0;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $grey-dark-core-bundle;
      font-weight: bold;
      @include em-size(16);
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $grey-dark-core-bundle;
      font-weight: bold;
      @include em-size(16);
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: $grey-dark-core-bundle;
      font-weight: bold;
      @include em-size(16);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $grey-dark-core-bundle;
      font-weight: bold;
      @include em-size(16);
    }
  }

  .btn-close-search {
    display: block;
    position: fixed;
    top: 13px;
    right: 18px;
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
    color: #fff;
    font-size: 1.9rem;
    transform: rotate(45deg);
    cursor: pointer;
  }

  ul.direct-links {
    display: none;
  }

  [type="text"] {
    display: block;
    width: 100%;
    height: 72px;
    font-weight: bold;
    padding-right: 75px;
    line-height: inherit;
    outline: 0;
    @include em-size(20);

    & + .iicon-search {
      position: absolute;
      right: 18px;
      bottom: 16px;
      z-index: 13;
      overflow: hidden;
      pointer-events: none;
      stroke: none;
      transform: rotate(-90deg);
      color: #fff;
      background-color: $color-secondary-core-bundle;
      width: 38px;
      height: 38px;
      padding: 7px;
      border-radius: 5px;
    }
  }

  @include media(">=md") {
    height: auto;
    min-height: 100vh;
    top: 130px;
    padding: 70px 0 100px;

    p {
      color: #fff;
      text-align: left;
      font-weight: bold;
      padding-top: 10px;
      margin-bottom: 30px;
      margin-top: 0;
      line-height: 1;
      opacity: 1;
      @include em-size(24);
    }

    .container-core-bundle {
      text-align: center;

      .inner {
        position: relative;
        display: block;
        margin: 0 auto;
        text-align: left;

        .btn-close-search {
          top: 40px;
          right: 45px;
        }
      }
    }
  }

  @include media(">=lg") {
    .container-core-bundle {
      .inner {
        display: inline-block;
        width: 800px;
      }
    }
  }

  @media screen and (min-width: 992px) and (min-height: 700px) {
    .container-core-bundle {
      .inner {
        width: 800px;

        ul.direct-links {
          display: block;
          text-align: left;
          list-style-type: none;
          padding: 70px 50px 0;
          margin: 0;

          li {
            padding-left: 80px;
            padding-right: 50px;
            margin-bottom: 45px;
            line-height: 1.4;

            a {
              position: relative;
              display: block;
              line-height: 1.4;
              color: #fff;
              text-decoration: none;
              @include em-size(16);

              .iicon {
                position: absolute;
                left: -66px;
                display: inline-block;
                top: 50%;
                margin-top: -18px;
                stroke: none !important;
                @include em-size(36);

                &.iicon-campingcar {
                  left: -70px;
                  margin-top: -18px;
                  @include em-size(33);
                }

                &.iicon-arrow {
                  left: inherit;
                  right: 0;
                  margin-top: -12px;
                  margin-right: -55px;
                  fill: #fff;
                  @include transition(all .2s ease);
                  @include em-size(24);
                }
              }

              &::after {
                position: absolute;
                right: 5px;
                top: 50%;
                margin-top: -12px;
                font-weight: normal;
                @include em-size(20);
                @include transition(all .2s);
              }

              &:hover {
                .iicon-arrow {
                  margin-right: -65px;
                }

                &::after {
                  right: 0;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  // PAGE INTERNE / OUVERTURE MOTEUR RECHERCHE
  .search-form-in-menu-opened & {
    pointer-events: inherit;
    opacity: 1;
    @include transition(all .2s);
    @include translate3d(0, 0, 0);
  }
}

// SITE GLOBAL / HP
.site-global.homepage {
  .search-form-core-bundle, .search-form-sarpgn-core-bundle, .search-form-sarpsp-core-bundle {
    position: absolute;
    z-index: 1;
    top: calc(100vh - 162px);
    height: auto;
    padding: 0;
    width: 100%;
    background-color: transparent;
    pointer-events: inherit;
    opacity: 1;
    @include transition(all 0s 0s linear);
    @include translate3d(0, 0, 0);

    .container-core-bundle {
      position: relative;
      max-width: 1200px;
    }

    .inner {
      padding: 0;
      margin: 0;
      float: right;
      width: 100%;
    }

    p,
    .btn-close-search,
    ul.direct-links {
      display: none;
    }

    &.opened {
      z-index: 8 !important;
    }

    @include media(">=lg") {
      top: calc(100vh - 202px);
      left: inherit;
      right: 50px;
      z-index: 1;

      .inner {
        max-width: 500px;
      }
    }

    @include media(">=xl") {
      top: calc(100vh - 232px);

      .inner {
        max-width: 660px;
      }
    }

    @include media(">=xxl") {
      top: calc(100vh - 262px);
    }

    @media screen and (max-height: 600px) and (min-width: 1024px) {
      top: calc(100vh - 132px);
    }
  }

  // SITE GLOBAL / HP / MOBILE : au focus sur le moteur de recherche
  @include media("<lg") {
    .home-intro {
      &::before {
        position: fixed;
        z-index: 1;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        touch-action: none;
        background: $color-primary-core-bundle;
        @include transition(all .3s ease);
      }
    }

    &.search-form-in-menu-opened {
      overflow: hidden;
      height: 100vh;
      min-height: auto;

      & > .header-core-bundle .nav-top-core-bundle,
      & > .header-core-bundle .header-inner-core-bundle,
      & > .footer-core-bundle {
        display: none;
      }

      .home-intro {
        &::before {
          opacity: 1;
          pointer-events: inherit;
          touch-action: inherit;
        }
      }

      .search-form-core-bundle, .search-form-sarpgn-core-bundle, .search-form-sarpsp-core-bundle {
        left: 20px;
        right: inherit;
        bottom: inherit;
        top: 65px;
        width: calc(100% - 40px);

        .container-core-bundle {
          padding: 0;
        }

        .btn-close-search {
          display: block;
          top: -52px;
          right: -2px;
        }
      }
    }
  }
}

// Autocomplete
.search-form-autocomplete {
  position: absolute;
  padding: 10px 0;
  top: 72px;
  border-radius: 8px 8px 0 0;
  z-index: 12;
  display: block;
  width: 100%;
  max-height: calc(100vh - 175px);
  overflow: hidden auto;
  background-color: #fff;
  pointer-events: none;
  opacity: 0;
  @include transition(all .2s);

  ul {
    text-align: left;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
      font-weight: normal;
      text-align: left;
      color: #222;
      @include em-size(16);

      a:not(.btn) {
        text-decoration: none;
        color: #222;
        display: block;
        @include transition(all .2s);

        strong {
          font-weight: bold;
        }

        &:hover {
          color: $color-primary-core-bundle;
          background-color: $grey-light-core-bundle;
        }
      }
    }

    &.search-results {
      li {
        color: #222;
        padding: 0 !important;

        a {
          margin: 2px 0;
          padding: 7px 25px;
        }

        &:last-child {
          margin-bottom: 10px;
          border-bottom: 1px solid $grey-light-core-bundle;

          a {
            margin-bottom: 10px;
          }
        }

        &.no-result {
          font-style: italic;
          padding: 7px 25px 17px !important;
          color: #666;
        }
      }
    }

    &.search-links {
      padding: 10px 25px;

      li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;

        a {
          text-align: left !important;
          text-decoration: none;
          display: inline-block;
          cursor: pointer;
          border-radius: 8px;
          letter-spacing: normal;
          padding: 6px 15px;
          font-weight: bold;
          line-height: 1.3;
          text-transform: uppercase;
          text-align: center;
          color: $color-primary-core-bundle;
          border: 2px solid $color-primary-core-bundle;
          background-color: #fff;
          font-size: rem(16);
          transition: all .2s;

          &:focus,
          &:hover {
            text-decoration: none;
            opacity: 1;
            color: #fff;
            border-color: $color-primary-core-bundle;
            background-color: $color-primary-core-bundle;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @include media(">=md") {
    max-height: calc(100vh - 50%);
    border-radius: 8px 8px 0 0;

    ul.search-results {
      li {
        padding: 10px 25px;
      }
    }

    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $grey-light-core-bundle;
      content: "";
      bottom: 8px;
      left: 0;
    }
  }

  @include media(">=lg") {
    .agency,
    .site-global.homepage & {
      top: inherit;
      bottom: 72px;
    }
  }

  @media screen and (max-height: 700px) and (min-width: 1024px) {
    max-height: calc(100vh - 75%);
  }
}

// Autocomplete opened
.search-form-core-bundle.opened, .search-form-sarpgn-core-bundle.opened, .search-form-sarpsp-core-bundle.opened {
  form {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .45);
  }

  .search-form-autocomplete {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .45);
    opacity: 1;
    pointer-events: inherit;
  }

  // Bordure blanche entre le champ de recherche et les résultats
  // pour masquer le box-shadow
  .inner {
    &::before {
      content: "";
      border-radius: 0;
      width: 100%;
      height: 20px;
      position: absolute;
      z-index: 30;
      top: 100px;
      left: 0;
      background-color: #fff;
    }

    &::after {
      content: "";
      border-radius: 0;
      width: 100%;
      height: 1px;
      position: absolute;
      z-index: 30;
      top: 118px;
      left: 0;
      background-color: $grey-light-core-bundle;
    }
  }

  .site-global.homepage & {
    .inner {
      &::before {
        top: 64px;
      }

      &::after {
        top: 72px;
      }
    }
  }

  .agency & {
    .inner {
      &::before,
      &::after {
        width: calc(100% - 40px);
        left: 20px;
      }

      &::before {
        top: 128px;
      }

      &::after {
        top: 135px;
      }
    }
    .inner.contact-or-meeting-page {
      &::before {
        content: "";
        border-radius: 0;
        width: 100%;
        height: 20px;
        position: absolute;
        z-index: 30;
        top: 100px;
        left: 0;
        background-color: #fff;
      }

      &::after {
        content: "";
        border-radius: 0;
        width: 100%;
        height: 1px;
        position: absolute;
        z-index: 30;
        top: 118px;
        left: 0;
        background-color: $grey-light-core-bundle;
      }
    }
  }

  @include media(">=md") {
    .search-form-autocomplete {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }

    .inner {
      &::before,
      &::after {
        width: calc(100% - 80px);
        left: 40px;
        top: 128px;
      }

      &::after {
        top: 135px;
      }
    }


    .agency &,
    .site-global.homepage & {
      .inner {
        &::before,
        &::after {
          width: 100%;
          left: 0;
        }
      }
      .inner.contact-or-meeting-page {
        &::before,
        &::after {
          width: calc(100% - 80px);
          left: 40px;
          top: 128px;
        }

        &::after {
          top: 135px;
        }
      }
    }
  }

  @include media(">=lg") {
    .inner {
      &::before,
      &::after {
        width: calc(100% - 80px);
        left: 40px;
      }
    }

    .site-global.homepage & {
      .search-form-autocomplete {
        padding-bottom: 5px;

        &::after {
          display: block;
          bottom: 2px;
        }
      }

      .inner {
        &::before {
          top: inherit;
          bottom: 54px;
          width: 100%;
          left: 0;
        }

        &::after {
          display: none;
        }
      }

      .inner.contact-or-meeting-page {
        &::before,
        &::after {
          width: calc(100% - 80px);
          left: 40px;
        }
      }
    }
  }

  @include media(">=xl") {
    .inner {
      &::before,
      &::after {
        width: calc(100% - 140px);
        left: 70px;
      }
    }

    .agency &,
    .site-global.homepage & {
      .inner {
        &::before {
          width: 100%;
          left: 0;
        }
      }

      .inner.contact-or-meeting-page {
        &::before,
        &::after {
          width: calc(100% - 140px);
          left: 70px;
        }
      }
    }
  }
}
.sarpgn-website.cms .search-form-core-bundle,
.sarpsp-website.cms .search-form-core-bundle,
.sarpgn-website.agency .search-form-core-bundle,
.sarpsp-website.agency .search-form-core-bundle {
  @include media(">=md") {
    top: 85px !important;
  }
}
