.breadcrumb:not(.breadcrumb-sonata) {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  li {
    display: inline-block;
    vertical-align: top;
    padding-right: 3px;
    font-weight: normal;
    color: $grey-dark-core-bundle;
    font-size: rem(10);

    &:not(:last-child)::after {
      content: ">";
      padding-left: 3px;
      font-weight: bold;
    }
  }

  a {
    font-weight: bold;
    color: $grey-dark-core-bundle;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
