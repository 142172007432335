.cookie-core-bundle {
  position: fixed;
  z-index: 9999;
  width: 250px;
  bottom: 0;
  right: 20px;
  padding: 30px 10px 10px 10px;
  background: rgba(255, 255, 255, .9);
  color: $color-primary-core-bundle;
  box-shadow: $shadow-core-bundle;
  text-align: left;
  @include em-size(12);

  &.hidden {
    display: none;
  }

  p {
    margin: 0 0 10px;
    line-height: 17px;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: darken($color-primary-core-bundle, 20%);
    }
  }

  &.cookie-accepted {
    display: none;
  }

  .btn-cookie-info {
    margin: 5px 0 0;
    display: block;
    color: $color-primary-core-bundle;
  }

  .btn-cookie-ok {
    letter-spacing: normal;
    transition: all .2s;
    display: block;
    zoom: 1;
    cursor: pointer;
    margin-top: 10px;
    line-height: 110%;
    padding: 4px 12px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    white-space: normal;
    color: #fff;
    background-color: $color-primary-core-bundle;
    border: 1px dotted transparent;
    border-radius: 10px;
    @include em-size(12);
  }

  .btn-cookie-close {
    text-decoration: none;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    font-style: normal;
    font-size: 2em;
    line-height: 25px;
    background: none;
    cursor: pointer;
    border: none;
    color: $color-primary-core-bundle;
  }
}
