main .banner {
  margin: 110px 0 0 0;
}

main .banner-sarpgn {
  margin: 85px 0 0 0 !important;
}

main .banner-sarpsp {
  margin: 85px 0 0 0 !important;
}

.banner, .banner-sarpgn, .banner-sarpsp {
  height: 110px;

  padding: 0;
  overflow: hidden;

  // si image
  &:not(.is-empty) {
    @include media(">=sm") {
      height: 170px;
    }

    @include media(">=md") {
      height: 220px;
    }

    @include media(">=lg") {
      height: 280px;
    }

    @include media(">=xxl") {
      height: 330px;
    }
  }

  // si pas d'image > dégradé bleu (la class "is-empty" est ajouté en JS si pas d'image uploadée)
  &.is-empty {
    background: linear-gradient(to right, $color-primary-core-bundle 0%, #0788da 100%);

    @include media(">=xxl") {
      height: 200px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  //BANNIERES CMS
  &.banner-cms {
    .parallax {
      overflow: hidden;
      height: 420px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        height: 100%;
        min-width: 100%;
        object-fit: cover;

        @include media(">=xxl") {
          margin-top: -100px;
        }
      }
    }
  }

  //BANNIERES SASS
  &.banner-sass {
    height: 400px !important;
    position: relative;

    .parallax {
      overflow: hidden;
      height: 100%;
      position: relative;

      img {
        position: absolute;
        height: 100%;
        min-width: 100%;
        object-fit: cover;
      }

      &.compat-object-fit {
        background-size: cover;
        background-position: center center;

        img {
          display: none;
        }
      }
    }

    &::before {
      position: absolute;
      z-index: 2;
      left: 0;
      content: "";
      height: 100%;
      width: 100%;
      background-image:
        linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 0%,
          rgba(94, 94, 94, 0) 100%
        );
      opacity: .6;
    }

    .banner-inner {
      position: absolute;
      z-index: 3;
      width: 90%;
      bottom: 30px;
      left: 5%;
    }
  }

  //BANNIERES FULL
  &.banner-full {
    position: relative;
    font-family: $font-primary-core-bundle;
    height: 100vh;
    margin-top: 0;

    .parallax {
      overflow: hidden;
      height: 100%;
      position: relative;

      img {
        position: absolute;
        height: 100%;
        min-width: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: rem(24);
      padding-bottom: 0;
      display: inline-block;

      .first-line {
        margin-bottom: 10px;
        display: block;
      }

      .banner-subtitle {
        font-size: rem(30);
        position: relative;
      }

      @include media(">=sm") {
        padding-bottom: 40px;
        font-size: rem(35);
        margin: 0 auto;

        .banner-subtitle {
          font-size: rem(50);
        }
      }

      @include media(">=lg") {
        padding-bottom: 80px;
        font-size: rem(50);
        text-align: left;

        .banner-subtitle {
          font-size: rem(72);
        }
      }
    }

    .banner-inner {
      position: absolute;
      z-index: 3;
      width: 90%;
      bottom: 30px;
      left: 5%;
    }
  }
}

.banner-full {
  .parallax {
    overflow: hidden;
    height: 100vh;
    position: relative;

    img {
      position: absolute;
      height: 120vh;
      min-width: 100%;
    }
  }
}

.banner-sass {
  .parallax {
    overflow: hidden;
    height: 400px;
    position: relative;

    img {
      position: absolute;
      height: 500px;
      min-width: 100%;
    }
  }
}
