$colorAnthracite: black;
$colorOrange: #ff9600;
$colorText: black;

.formula {
  text-align: center;
  .content {
    padding-top: 0;
    padding-bottom: 0;

    h2 {
      margin-bottom: 20px;
    }
  }

  table {
    position: relative;

    caption {
      position: relative;
      @include em-size(16);

      &:before {
        position: absolute;
        content: "";
        width: 74%;
        height: 60px;
        bottom: -311px;
        right: 0;
        z-index: 5;
        box-shadow: 0 -90px 120px 0 rgba(0, 0, 0, .2);
      }

      &:after {
        position: absolute;
        content: "";
        width: 300px;
        height: 60px;
        bottom: -311px;
        right: -300px;
        z-index: 5;
        background-color: #fff;
      }
    }

    i {
      font-style: normal;


      &.fa {
        @include em-size(28);
      }

      &.fa-underscore {
        position: relative;
        display: block;
        width: 100%;
        height: 20px;

        &:before {
          position: absolute;
          content: "";
          width: 16px;
          height: 3px;
          background-color: #fec05a;
          font-weight: bold;
          left: 50%;
          margin-left: -10px;
          top: 10px;
        }
      }
    }

    td,
    th {
      &.col-space {
        width: 1%;
        padding: 0 !important;
        background-color: transparent;

        &:before {
          display: none;
        }
      }
    }

    td.col-space {
      background-color: #fff;
    }

    thead {
      th {
        position: relative;
        z-index: 2;
        vertical-align: top;
        padding-top: 30px;
        background-color: #fff;

        &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 10px;
          width: 100%;
          background-color: lighten(#fec05a, 20%);
          border-radius: 10px 10px 0 0;
        }

        &:first-child {
          padding: 0;
          text-align: right;
          background-color: transparent;

          &:before {
            display: none;
          }
        }

        span {
          display: block;
          clear: both;

          &.formula-type {
            font-weight: normal;
            text-decoration: underline;
            line-height: 1.1em;
            margin-bottom: 5px;
            @include em-size(15);
          }

          &.formula-label {
            font-weight: bold;
            line-height: 1.1em;
            margin-bottom: 5px;
            @include em-size(27);
          }

          &.formula-description {
            font-weight: normal;
            font-style: italic;
            line-height: 1.2em;
            @include em-size(14);
          }
        }
      }
    }

    tbody {
      position: relative;
      z-index: 5;
      background-color: #fff;

      tr {
        border-bottom: 1px solid #fff;
      }

      td {
        width: 18%;
      }

      td[scope] {
        position: relative;
        z-index: 2;
        width: 24%;
        padding-right: 60px;
        line-height: 1.2em;
        vertical-align: middle;
        text-align: left;
        background-color: #c4c4c4;
        color: $colorAnthracite;
        border-radius: 10px 0 0 10px;

        span.help {
          position: absolute;
          right: 12px;
          top: 50%;
          background: $colorAnthracite;
          width: 30px;
          height: 30px;
          margin-top: -15px;
          text-align: center;
          line-height: 30px;
          border-radius: 15px;
          color: #c4c4c4;
          font-weight: bold;
          cursor: pointer;
          @include em-size(17);
          @include transition(color .2s);

          span.help-text {
            position: absolute;
            left: 47px;
            top: -8px;
            width: 240px;
            height: auto;
            border-radius: 10px;
            z-index: 5;
            display: inline-block;
            background-color: rgba(255, 255, 255, .9);
            padding: 15px;
            @include em-size(12);

            font-style: italic;
            font-weight: normal;
            line-height: 1.1em;
            color: $colorAnthracite;
            text-align: left;
          }

          &:hover {
            color: #fec05a;

            span.help-text {
              color: $colorAnthracite;
            }
          }
        }
      }

      tr:nth-of-type(odd) td[scope] {
        background-color: #eee;

        span.help {
          color: #eee;

          span.help-text {
            color: $colorAnthracite;
          }

          &:hover {
            color: $colorOrange;

            span.help-text {
              color: $colorAnthracite;
            }
          }
        }
      }
    }
  }

  .legend {
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;

    li {
      position: relative;
      display: inline-block;
      color: $colorOrange;
      margin-left: 20px;
      padding-left: 25px;

      &:first-child {
        font-style: italic;
        color: $colorText;
      }

      i {
        font-style: normal;

        &.fa {
          position: absolute;
          left: 0;
          bottom: 10px;
          @include em-size(35);
        }

        &.fa-underscore {

          &:before {
            position: absolute;
            content: "";
            width: 16px;
            height: 3px;
            background-color: #ff9600;
            font-weight: bold;
            left: 0;
            top: 0;
          }
        }

        &.fa-check {
          bottom: 0;
          @include em-size(20);

        }
      }
    }
  }

  .note-pdf {
    margin-bottom: 30px;

    a {
      text-decoration: underline;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  .action {
    text-align: center;
    padding-bottom: 60px;
  }
}


.formula {
  margin: 40px 0px 0px 0px;
}

.col-md-2ths,
.col-md-3ths,
.col-md-4ths,
.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.detail-formula:before {
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: 10px;
  height: 10px;
  background-color: #fec05a;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}

.detail-push-formula:before {
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: 0;
  height: 10px;
  background-color: #6BC024;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}

.space-top {
  height: 10px;
  display: block;
}

.detail-formula {
  margin-top: 10px;
  box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
  width: 100%;

  .texte-formula {
    color: #fec05a;
  }

  .separator-formula {
    height: 4px;
    width: 23px;
    margin: 20px auto;
    background-color: #fec05a;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
  }

  .wysiwyg-formula, .wysiwyg-formula-horizontal {
    text-align: left;

    ul li:before {
      display: inline-block;
      transform: rotate(45deg);
      width: 8px;
      height: 15px;
      content:"";
      margin: 0 10px 0 10px;
    }

    ul {
      display: table;
      li {
        display: table-row;
        margin: 10px 0;
        font-size: 15px;

        span {
          vertical-align: middle;
          display: table-cell;
          padding-bottom: 13px;
        }
      }
    }
  }
}
.wysiwyg-formula-horizontal {
  padding-left: 17px !important;
  p {
    margin: 1em 0;
  }
}

.detail-push-formula {
  margin-top: 10px;
  border-right: 5px solid #6BC024;
  border-left: 5px solid #6BC024;
  border-bottom: 5px solid #6BC024;
  box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
  width: 100%;

  .texte-formula {
    color: #6BC024;
  }

  .separator-formula {
    height: 4px;
    width: 23px;
    margin: 20px auto;
    background-color: #6BC024;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
  }

  .wysiwyg-formula {
    text-align: left;

    ul li:before {
      display: inline-block;
      transform: rotate(45deg);
      width: 8px;
      height: 15px;
      content:"";
      margin: 0 10px 0 10px;
     }

    ul {
      display: table;
      li {
        display: table-row;
        margin: 10px 0;
        font-size: 15px;

        span {
          vertical-align: middle;
          display: table-cell;
          padding-bottom: 13px;
        }
      }
    }
  }
}

h2.title-2-custom span {
  font-size: 1.7em;
  margin-bottom: 15px;
}

.icon-formula {
  padding-top: 20px;
}

.icons-why-formula {
  height: 50px;
  margin-bottom: 15px;
}

.titre-formula {
  font-weight: bold;
  font-size: 25px;
  padding: 0 35px 10px 35px;
}

.titre-formula-horizontal {
  font-weight: bold;
  font-size: 25px;
  padding: 0 35px 0 15px;
}

.push-formula {
  padding: 10px;
}


