.loader-wrapper {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  margin: 10px auto;
}

.loader {
  animation: loader 1.5s linear infinite;
  clip: rect(0, 80px, 80px, 40px);
  height: 80px;
  width: 80px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);

  &:after {
    animation: loader2 1.5s ease-in-out infinite;
    clip: rect(0, 80px, 80px, 40px);
    content:'';
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: absolute;
  }

}

/* spinner for button */
.spinner {
  margin: 2px 10px 0 0;
  height: 15px;
  width: 15px;
  display: inline-block;
  animation: rotate 0.8s infinite linear;
  border: 1px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  float:left;
}
