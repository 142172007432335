.flash-messages {
    padding: 40px 0 10px;

    .alert {
        position: relative;
        padding: 20px 40px 20px 20px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        background-color: $colorOrange;

        p {
            margin: 0;
            line-height: 1.4;
        }

        button.close {
            position: absolute;
            right: 20px;
            top: 50%;
            padding: 0;
            margin: -9px 0 0;
            cursor: pointer;
            display: inline-block;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 0;
            background-color: transparent;

            .iicon-close-popin::before {
                color: #fff;
            }
        }
    }
}
