blockquote {
  display: block;
  margin: 10px auto;
  font-style: italic;
  font-weight: 600;
  text-align: center;
  color: $grey-verydark-core-bundle;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: rem(.3);

  &::before {
    content: "« ";
  }

  &::after {
    content: " »";
  }
}
