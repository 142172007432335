.widget-core-bundle {
  position: fixed;
  display: inline-block;
  left: 0;
  bottom: 0;
  width: 33.33%;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  letter-spacing: normal;
  font-weight: bold;
  line-height: 1.3;
  z-index: 6;
  background-color: $color-secondary-core-bundle;
  color: #fff !important;
  font-size: rem(12);
  transform: translate3d(0, 100%, 0);
  transition: all .2s .1s;

  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff !important;
  }

  span.text {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    padding-top: 30px;

    .iicon {
      position: absolute;
      left: 50%;
      margin-left: -13px;
      top: -3px;
      stroke: none;
      width: 1.8rem;
      height: 1.8rem;
    }

    .mobile-hide {
      display: none;
    }
  }

  &.widget-core-bundle-assistance {
    left: 33.33%;
    background-color: darken($color-primary-core-bundle, 5%);
  }

  &.widget-core-bundle-help {
    left: 66.66%;
    background-color: $color-third-core-bundle;
  }

  .scrolling & {
    transform: translate3d(0, 0, 0);
  }

  @include media(">=md") {
    right: 0;
    top: 240px;
    bottom: inherit;
    left: inherit;
    width: 300px;
    border-radius: 26px 0 0 26px;
    padding: 18px;
    font-size: rem(15);
    transition: all .2s;
    transform: translate3d(240px, 0, 0);

    span.text {
      padding-top: 0;
      padding-left: 45px;
      width: 100%;
      text-align: left;

      .iicon {
        left: 3px;
        top: -5px;
        margin-left: 0;
        font-size: rem(32);
      }

      .mobile-hide {
        display: inline-block;
      }
    }

    .site-global &:focus,
    .site-global &:hover {
      transform: translate3d(15px, 0, 0);
    }

    &.widget-core-bundle-location-agencie-mine,
    &.widget-core-bundle-location-agencie {
      span.text .iicon {
        left: -1px;
        top: -4px;
      }
    }

    &.widget-core-bundle-location-agencie-mine {
      span.text-behavior {
        span {
          display: inline-block;
          padding-right: 6px;
        }
      }
    }

    &.widget-core-bundle-assistance {
      left: inherit;
      top: 305px;

      span.text .iicon {
        left: -1px;
        top: -4px;
      }
    }

    &.widget-core-bundle-help {
      left: inherit;
      top: 370px;

      span.text .iicon {
        left: -1px;
        top: -5px;
      }
    }

    &:hover {
      transform: translate3d(15px, 0, 0);
    }

    .scrolling & {
      transform: translate3d(240px, 0, 0);

      &:hover {
        transform: translate3d(15px, 0, 0);
      }
    }
  }
}
