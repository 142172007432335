.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  display: block;
  visibility: hidden;
  border-radius: 6px;
  padding: 3px 10px;
  background-color: #3b3b3c;
  color: #fff;
  text-align: center;
  transition: all .3s;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -35px;
  left: -40px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #3b3b3c transparent transparent transparent;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
