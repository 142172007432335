.modal-open {
  height: 100vh;
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.fade .modal-dialog {
    -webkit-transition: .2s ease;
    transition: .2s ease;
    opacity: 0;
    @include translate3d(-50%, -110%, 0);
  }

  &.in .modal-dialog {
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    opacity: 1;
    @include translate3d(-50%, 0, 0);
  }

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: absolute;
  left: 50%;
  width: auto;
  margin-top: 40px;
  background-color: #fff;
}

.modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgb(239, 239, 239);
  transition: all .2s ease;

  &.in {
    opacity: .9;
  }
}

.modal-content {
  border-radius: 0;
  border:0;
  box-shadow: 1px -4px 16px rgba(0, 0, 0, .1);
}

.modal-header {
  position: relative;
  border-bottom: 0;
  height: 60px;

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: .4;
    @include transition(all .2s);

    .iicon {
      &::before {
        color: $colorPrimary;
        font-size: 22px;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

.modal-ajax {
  padding: 20px;
}

@media (min-width: $screen-lg) {
  .modal-lg {
    width: 900px;
  }
}

/* Modal Assistance */
.modal-content-assistance {
  text-align: center;
  font-family: $fontPrimary;

  .iicon-assistance-popin {
    color: $colorBlue;
    margin: 5px auto 20px;
    display: inline-block;
    overflow: visible;
    @include em-size(50);
  }

  p {
    color: $colorBlue;
    margin-top: 0;
    margin-bottom: 5px;
    @include em-size(15);
  }

  .fidelia {
    color: $colorBlue;
    line-height: 1.2;
    margin-top: 15px;
    @include em-size(24);
  }

  .btn-ag {
    padding: 5px 10px;
    display: block;
    width: 300px;
    clear: both;
    max-width: 100%;
    margin: 20px auto 40px;
    @include em-size(18);

    @media (min-width: $screen-sm) {
      padding: 10px 20px;
    }
  }
}
