@charset "UTF-8";
/*
Calcul de la font PX to EM
*/
/*
Créer une transition entre deux états d'un élément
$arguments		- Paramètres de la transition
*/
/*
Gérer la transparence/opacité d'un élément
$opacity: 		- Opacité d'un élément de 0 (transparent) à 1 (opaque)
*/
/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
$y 				- Axe des ordonnées Y
$z 				- Axe de la profondeur Z
*/
/*
Gérer la rotation d'un élément
$deg			- Rotation en degrés d'un élément (unité = deg)
*/
/*
Gérer la rotation d'un élément
$ratio 			- Ratio scale (0.5 = 50%, 1 = 100%, 1.5 = 150%)
*/
/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
*/
/*
Gérer la position et le déplacement sur 3 axes d'un élément
$y   			- Axe des ordonnées Y
*/
/*
Dégradé vertical
$start-color 	- Couleur de départ (default = #555)
$end-color		- Couleur de fin (default = #333) : couleur également utilisée en fallback
$start-percent 	- Niveau de départ de la couleur top en pourcentage (default = 0%)
$end-percent 	- Niveau de fin de la couleur bottom en pourcentage (default = 100%)
*/
/*
Dégradé horizontal
$start-color 	- Couleur de départ (default = #555)
$end-color 		- Couleur de fin (default = #333) : couleur également utilisée en fallback
$start-percent 	- Niveau de départ de la couleur top en pourcentage (default = 0%)
$end-percent  	- Niveau de fin de la couleur bottom en pourcentage (default = 100%)
*/
/*
Dégradé radial
$start-color 	- Couleur de départ
$end-color 		- Couleur de fin : couleur également utilisée en fallback
*/
/*
Box-sizing
$type 			- Valeur de la propriétée box-sizing (border-box par défaut)
*/
/*
Gérer l'inclinaison d'un élément
$deg 			- Inclinaison en degrés d'un élément (unité = deg)
*/
/*
Gérer l'inclinaison d'un élément sur l'axe des ordonnées
$deg  			- Inclinaison en degrés d'un élément (unité = deg) sur l'axe des ordonnées
*/
/*
Gérer le flou d'un élément
$dist 			- Valeur en px du flou sur un élément
*/
/*
Gérer le retour à la ligne après des éléménrts flottants
*/
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.sr-only {
  border: 0 !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.baseline-core-bundle {
  display: none;
  vertical-align: top;
  position: absolute;
  left: 20px;
  top: 55px;
  text-align: left;
  padding: 30px 0 0 0;
  line-height: 1.2;
  color: #0374bc;
  font-size: 14;
  font-size: 0.875rem;
}
.header-inverted:not(.scrolling) .baseline-core-bundle {
  color: #fff;
}
@media (max-width: 567px) {
  body.homepage.is-connected .baseline-core-bundle {
    left: 12px;
    top: 45px;
  }
}
.baseline-core-bundle strong {
  display: block;
  font-size: 14;
  font-size: 0.875rem;
}
@media (min-width: 568px) {
  .baseline-core-bundle {
    display: inline-block;
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
    padding-left: 7px;
  }
  body.homepage .baseline-core-bundle {
    color: #fff;
  }
  .homepage.scrolling .baseline-core-bundle {
    color: #0374bc;
    font-size: 14;
    font-size: 0.875rem;
  }
  .homepage.scrolling .baseline-core-bundle strong {
    display: block;
    font-size: 14;
    font-size: 0.875rem;
  }
  .homepage.scrolling .baseline-core-bundle em {
    display: block;
  }
}
@media (min-width: 768px) {
  body.homepage .baseline-core-bundle {
    font-size: 23;
    font-size: 1.4375rem;
  }
  body.homepage .baseline-core-bundle strong {
    font-size: 23;
    font-size: 1.4375rem;
  }
  body.homepage.is-connected:not(.scrolling) .baseline-core-bundle {
    padding-left: 20px;
    top: 5px;
  }
}
@media (min-width: 992px) {
  body.homepage .baseline-core-bundle {
    font-size: 29;
    font-size: 1.8125rem;
  }
  body.homepage .baseline-core-bundle strong {
    font-size: 29;
    font-size: 1.8125rem;
  }
}

.cookie-core-bundle {
  position: fixed;
  z-index: 9999;
  width: 250px;
  bottom: 0;
  right: 20px;
  padding: 30px 10px 10px 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #0374bc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  font-size: 12;
  font-size: 0.75rem;
}
.cookie-core-bundle.hidden {
  display: none;
}
.cookie-core-bundle p {
  margin: 0 0 10px;
  line-height: 17px;
}
.cookie-core-bundle a {
  text-decoration: underline;
}
.cookie-core-bundle a:hover {
  color: #013658;
}
.cookie-core-bundle.cookie-accepted {
  display: none;
}
.cookie-core-bundle .btn-cookie-info {
  margin: 5px 0 0;
  display: block;
  color: #0374bc;
}
.cookie-core-bundle .btn-cookie-ok {
  letter-spacing: normal;
  transition: all 0.2s;
  display: block;
  zoom: 1;
  cursor: pointer;
  margin-top: 10px;
  line-height: 110%;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  white-space: normal;
  color: #fff;
  background-color: #0374bc;
  border: 1px dotted transparent;
  border-radius: 10px;
  font-size: 12;
  font-size: 0.75rem;
}
.cookie-core-bundle .btn-cookie-close {
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  font-style: normal;
  font-size: 2em;
  line-height: 25px;
  background: none;
  cursor: pointer;
  border: none;
  color: #0374bc;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  display: block;
  visibility: hidden;
  border-radius: 6px;
  padding: 3px 10px;
  background-color: #3b3b3c;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -35px;
  left: -40px;
}
.tooltiptext::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 100%, 0);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #3b3b3c transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.bm-menu {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.bm-burger-button {
  z-index: 1000;
  position: absolute;
  width: 30px;
  height: 28px;
  text-align: center;
  right: 20px;
  top: 21px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.bm-burger-button:hover {
  opacity: 1;
}
.bm-burger-button > button {
  background: transparent !important;
  z-index: 1 !important;
  left: -20px !important;
  top: -10px !important;
  width: calc(100% + 40px) !important;
  height: calc(100% + 40px) !important;
}

.bm-burger-bars {
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
  opacity: 1;
  background: #0374bc;
  height: 3px !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.bm-burger-bars:nth-of-type(2) {
  top: 40%;
}
.bm-burger-bars:nth-of-type(3) {
  top: 80%;
}
.header-inverted:not(.scrolling) .bm-burger-bars {
  background: #fff;
}

.bm-item-list {
  height: 100%;
}
.bm-item-list #toggler {
  display: none;
  -webkit-transform: translate3d(-400px, -400px, 0);
  -ms-transform: translate3d(-400px, -400px, 0);
  transform: translate3d(-400px, -400px, 0);
}

.burger-menu-mainnav,
.burger-menu-profile {
  position: fixed;
  top: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 1100;
  background-color: #0374bc;
  text-align: left;
  transform: translate3d(100%, 0, 0);
  transition: all 0.5s;
}

.burger-menu-profile {
  background-color: #f0941d;
}

.bm-overlay {
  display: block;
  position: fixed;
  pointer-events: none;
  touch-action: none;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s;
}

.body-menu-navigation-opened {
  overflow: hidden;
}
.body-menu-navigation-opened .burger-menu-mainnav {
  transform: translate3d(0, 0, 0);
}
.body-menu-navigation-opened .bm-overlay-navigation {
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.body-menu-profile-opened {
  overflow: hidden;
}
.body-menu-profile-opened .burger-menu-profile {
  transform: translate3d(0, 0, 0);
}
.body-menu-profile-opened .bm-overlay-profile {
  opacity: 1;
  width: 100%;
  height: 100vh;
}

.bm-cross-button {
  position: absolute;
  z-index: 10;
  width: 24px;
  height: 24px;
  right: 20px;
  top: 15px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.bm-cross-button .bm-cross-wrapper {
  position: absolute;
  top: 6px;
  right: 14px;
}
.bm-cross-button .bm-cross {
  position: absolute;
  width: 3px;
  height: 30px;
  transform: rotate(45deg);
  background: #fff;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.bm-cross-button .bm-cross:nth-of-type(2) {
  transform: rotate(-45deg);
}
.bm-cross-button > button {
  position: absolute;
  margin: 0;
  padding: 0;
  border: none;
  text-indent: -9999px;
  outline: none;
  cursor: pointer;
  background: transparent;
  z-index: 1;
  left: -20px;
  top: -10px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
}

.burger-menu-profile .bm-item-list {
  padding-top: 70px;
}
@media (min-width: 768px) {
  .burger-menu-profile .bm-item-list {
    padding-top: 150px;
  }
}
.burger-menu-profile .bm-item-list > ul.bm-item {
  display: block;
  outline: none;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li {
  /* bienvenue */
  /* Btn Déconnexion */
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a {
  position: relative;
  padding-right: 60px;
  text-transform: inherit;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a span.reference {
  display: block;
  font-weight: normal;
  padding-top: 10px;
  font-size: 14;
  font-size: 0.875rem;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a span.lastname {
  text-transform: uppercase;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a span.firstname {
  text-transform: capitalize;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a .iicon {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -12px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li > a:hover::after {
  right: 0;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li.menu-item-welcome {
  margin-bottom: 10px;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", Arial, Roboto, sans-serif;
  font-weight: 700;
  font-size: 27;
  font-size: 1.6875rem;
}
@media (min-width: 768px) {
  .burger-menu-profile .bm-item-list > ul.bm-item > li.menu-item-welcome {
    margin-bottom: 30px;
  }
}
.burger-menu-profile .bm-item-list > ul.bm-item > li.menu-item-logout {
  margin-top: 20px;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li.menu-item-logout > a .firstname {
  margin-left: 30px;
  text-transform: uppercase;
}
.burger-menu-profile .bm-item-list > ul.bm-item > li.menu-item-logout > a .iicon {
  right: inherit;
  stroke: none;
  width: 1.4rem;
  height: 1.4rem;
}

@media (min-width: 768px) {
  .bm-overlay {
    top: 40px !important;
    height: calc(100vh - 40px) !important;
  }
  .bm-burger-button {
    right: 60px;
    top: 59px;
    height: 24px;
  }
  .bm-burger-button::after {
    position: absolute;
    left: -7px;
    top: 26px;
    font-weight: bold;
    color: #0374bc;
    content: "MENU";
    font-size: 14;
    font-size: 0.875rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .header-inverted:not(.scrolling) .bm-burger-button::after {
    color: #fff;
  }
  .search-form-in-menu-opened .bm-burger-button, .scrolling .bm-burger-button, body:not(.homepage) .bm-burger-button {
    top: 27px;
  }
  .search-form-in-menu-opened .bm-burger-button::after, .scrolling .bm-burger-button::after, body:not(.homepage) .bm-burger-button::after {
    color: #0374bc;
  }
  .search-form-in-menu-opened .bm-burger-bars, .scrolling .bm-burger-bars, body:not(.homepage) .bm-burger-bars {
    background: #0374bc;
  }
  .burger-menu-mainnav,
  .burger-menu-profile {
    top: 40px !important;
    height: calc(100vh - 40px) !important;
    width: 600px !important;
  }
  .bm-cross-button {
    right: 62px !important;
    top: 52px !important;
  }
  .bm-cross-button::after {
    position: absolute;
    left: -15px;
    top: 36px;
    font-weight: bold;
    color: #fff;
    content: "FERMER";
    font-size: 14;
    font-size: 0.875rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
}
.container-core-bundle {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}
@media (min-width: 1200px) {
  .container-core-bundle {
    padding: 0;
  }
}
.container-core-bundle p {
  margin: 1.6em 0;
}

.search-form-core-bundle, .search-form-sarpgn-core-bundle, .search-form-sarpsp-core-bundle {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 0 40px;
  margin: 0;
  z-index: 6;
  background-color: #0374bc;
  pointer-events: none;
  opacity: 0;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.search-form-core-bundle p, .search-form-sarpgn-core-bundle p, .search-form-sarpsp-core-bundle p {
  opacity: 0;
  margin: 1.25em 0;
}
.search-form-core-bundle form, .search-form-sarpgn-core-bundle form, .search-form-sarpsp-core-bundle form {
  position: relative;
  padding: 0;
  margin: 0;
  height: 72px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.4s;
}
.search-form-core-bundle form select,
.search-form-core-bundle form input,
.search-form-core-bundle form textarea,
.search-form-core-bundle form button, .search-form-sarpgn-core-bundle form select,
.search-form-sarpgn-core-bundle form input,
.search-form-sarpgn-core-bundle form textarea,
.search-form-sarpgn-core-bundle form button, .search-form-sarpsp-core-bundle form select,
.search-form-sarpsp-core-bundle form input,
.search-form-sarpsp-core-bundle form textarea,
.search-form-sarpsp-core-bundle form button {
  border-radius: 0;
  padding: 0 25px;
  text-align: left;
  appearance: none;
  font-family: inherit;
  color: #222;
  background-color: transparent;
  border: 0;
}
.search-form-core-bundle form ::-webkit-input-placeholder, .search-form-sarpgn-core-bundle form ::-webkit-input-placeholder, .search-form-sarpsp-core-bundle form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #757575;
  font-weight: bold;
  font-size: 16;
  font-size: 1rem;
}
.search-form-core-bundle form ::-moz-placeholder, .search-form-sarpgn-core-bundle form ::-moz-placeholder, .search-form-sarpsp-core-bundle form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #757575;
  font-weight: bold;
  font-size: 16;
  font-size: 1rem;
}
.search-form-core-bundle form :-ms-input-placeholder, .search-form-sarpgn-core-bundle form :-ms-input-placeholder, .search-form-sarpsp-core-bundle form :-ms-input-placeholder {
  /* IE 10+ */
  color: #757575;
  font-weight: bold;
  font-size: 16;
  font-size: 1rem;
}
.search-form-core-bundle form :-moz-placeholder, .search-form-sarpgn-core-bundle form :-moz-placeholder, .search-form-sarpsp-core-bundle form :-moz-placeholder {
  /* Firefox 18- */
  color: #757575;
  font-weight: bold;
  font-size: 16;
  font-size: 1rem;
}
.search-form-core-bundle .btn-close-search, .search-form-sarpgn-core-bundle .btn-close-search, .search-form-sarpsp-core-bundle .btn-close-search {
  display: block;
  position: fixed;
  top: 13px;
  right: 18px;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  color: #fff;
  font-size: 1.9rem;
  transform: rotate(45deg);
  cursor: pointer;
}
.search-form-core-bundle ul.direct-links, .search-form-sarpgn-core-bundle ul.direct-links, .search-form-sarpsp-core-bundle ul.direct-links {
  display: none;
}
.search-form-core-bundle [type=text], .search-form-sarpgn-core-bundle [type=text], .search-form-sarpsp-core-bundle [type=text] {
  display: block;
  width: 100%;
  height: 72px;
  font-weight: bold;
  padding-right: 75px;
  line-height: inherit;
  outline: 0;
  font-size: 20;
  font-size: 1.25rem;
}
.search-form-core-bundle [type=text] + .iicon-search, .search-form-sarpgn-core-bundle [type=text] + .iicon-search, .search-form-sarpsp-core-bundle [type=text] + .iicon-search {
  position: absolute;
  right: 18px;
  bottom: 16px;
  z-index: 13;
  overflow: hidden;
  pointer-events: none;
  stroke: none;
  transform: rotate(-90deg);
  color: #fff;
  background-color: #6bc024;
  width: 38px;
  height: 38px;
  padding: 7px;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .search-form-core-bundle, .search-form-sarpgn-core-bundle, .search-form-sarpsp-core-bundle {
    height: auto;
    min-height: 100vh;
    top: 130px;
    padding: 70px 0 100px;
  }
  .search-form-core-bundle p, .search-form-sarpgn-core-bundle p, .search-form-sarpsp-core-bundle p {
    color: #fff;
    text-align: left;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 30px;
    margin-top: 0;
    line-height: 1;
    opacity: 1;
    font-size: 24;
    font-size: 1.5rem;
  }
  .search-form-core-bundle .container-core-bundle, .search-form-sarpgn-core-bundle .container-core-bundle, .search-form-sarpsp-core-bundle .container-core-bundle {
    text-align: center;
  }
  .search-form-core-bundle .container-core-bundle .inner, .search-form-sarpgn-core-bundle .container-core-bundle .inner, .search-form-sarpsp-core-bundle .container-core-bundle .inner {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: left;
  }
  .search-form-core-bundle .container-core-bundle .inner .btn-close-search, .search-form-sarpgn-core-bundle .container-core-bundle .inner .btn-close-search, .search-form-sarpsp-core-bundle .container-core-bundle .inner .btn-close-search {
    top: 40px;
    right: 45px;
  }
}
@media (min-width: 992px) {
  .search-form-core-bundle .container-core-bundle .inner, .search-form-sarpgn-core-bundle .container-core-bundle .inner, .search-form-sarpsp-core-bundle .container-core-bundle .inner {
    display: inline-block;
    width: 800px;
  }
}
@media screen and (min-width: 992px) and (min-height: 700px) {
  .search-form-core-bundle .container-core-bundle .inner, .search-form-sarpgn-core-bundle .container-core-bundle .inner, .search-form-sarpsp-core-bundle .container-core-bundle .inner {
    width: 800px;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links {
    display: block;
    text-align: left;
    list-style-type: none;
    padding: 70px 50px 0;
    margin: 0;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li {
    padding-left: 80px;
    padding-right: 50px;
    margin-bottom: 45px;
    line-height: 1.4;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a {
    position: relative;
    display: block;
    line-height: 1.4;
    color: #fff;
    text-decoration: none;
    font-size: 16;
    font-size: 1rem;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon {
    position: absolute;
    left: -66px;
    display: inline-block;
    top: 50%;
    margin-top: -18px;
    stroke: none !important;
    font-size: 36;
    font-size: 2.25rem;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-campingcar, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-campingcar, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-campingcar {
    left: -70px;
    margin-top: -18px;
    font-size: 33;
    font-size: 2.0625rem;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-arrow, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-arrow, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a .iicon.iicon-arrow {
    left: inherit;
    right: 0;
    margin-top: -12px;
    margin-right: -55px;
    fill: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 24;
    font-size: 1.5rem;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a::after, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a::after, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a::after {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -12px;
    font-weight: normal;
    font-size: 20;
    font-size: 1.25rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a:hover .iicon-arrow, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a:hover .iicon-arrow, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a:hover .iicon-arrow {
    margin-right: -65px;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li a:hover::after, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li a:hover::after, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li a:hover::after {
    right: 0;
  }
  .search-form-core-bundle .container-core-bundle .inner ul.direct-links li:last-child, .search-form-sarpgn-core-bundle .container-core-bundle .inner ul.direct-links li:last-child, .search-form-sarpsp-core-bundle .container-core-bundle .inner ul.direct-links li:last-child {
    margin-bottom: 0;
  }
}
.search-form-in-menu-opened .search-form-core-bundle, .search-form-in-menu-opened .search-form-sarpgn-core-bundle, .search-form-in-menu-opened .search-form-sarpsp-core-bundle {
  pointer-events: inherit;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.site-global.homepage .search-form-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle {
  position: absolute;
  z-index: 1;
  top: calc(100vh - 162px);
  height: auto;
  padding: 0;
  width: 100%;
  background-color: transparent;
  pointer-events: inherit;
  opacity: 1;
  -webkit-transition: all 0s 0s linear;
  transition: all 0s 0s linear;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.site-global.homepage .search-form-core-bundle .container-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle .container-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle .container-core-bundle {
  position: relative;
  max-width: 1200px;
}
.site-global.homepage .search-form-core-bundle .inner, .site-global.homepage .search-form-sarpgn-core-bundle .inner, .site-global.homepage .search-form-sarpsp-core-bundle .inner {
  padding: 0;
  margin: 0;
  float: right;
  width: 100%;
}
.site-global.homepage .search-form-core-bundle p,
.site-global.homepage .search-form-core-bundle .btn-close-search,
.site-global.homepage .search-form-core-bundle ul.direct-links, .site-global.homepage .search-form-sarpgn-core-bundle p,
.site-global.homepage .search-form-sarpgn-core-bundle .btn-close-search,
.site-global.homepage .search-form-sarpgn-core-bundle ul.direct-links, .site-global.homepage .search-form-sarpsp-core-bundle p,
.site-global.homepage .search-form-sarpsp-core-bundle .btn-close-search,
.site-global.homepage .search-form-sarpsp-core-bundle ul.direct-links {
  display: none;
}
.site-global.homepage .search-form-core-bundle.opened, .site-global.homepage .search-form-sarpgn-core-bundle.opened, .site-global.homepage .search-form-sarpsp-core-bundle.opened {
  z-index: 8 !important;
}
@media (min-width: 992px) {
  .site-global.homepage .search-form-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle {
    top: calc(100vh - 202px);
    left: inherit;
    right: 50px;
    z-index: 1;
  }
  .site-global.homepage .search-form-core-bundle .inner, .site-global.homepage .search-form-sarpgn-core-bundle .inner, .site-global.homepage .search-form-sarpsp-core-bundle .inner {
    max-width: 500px;
  }
}
@media (min-width: 1200px) {
  .site-global.homepage .search-form-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle {
    top: calc(100vh - 232px);
  }
  .site-global.homepage .search-form-core-bundle .inner, .site-global.homepage .search-form-sarpgn-core-bundle .inner, .site-global.homepage .search-form-sarpsp-core-bundle .inner {
    max-width: 660px;
  }
}
@media (min-width: 1440px) {
  .site-global.homepage .search-form-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle {
    top: calc(100vh - 262px);
  }
}
@media screen and (max-height: 600px) and (min-width: 1024px) {
  .site-global.homepage .search-form-core-bundle, .site-global.homepage .search-form-sarpgn-core-bundle, .site-global.homepage .search-form-sarpsp-core-bundle {
    top: calc(100vh - 132px);
  }
}
@media (max-width: 991px) {
  .site-global.homepage .home-intro::before {
    position: fixed;
    z-index: 1;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    background: #0374bc;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .site-global.homepage.search-form-in-menu-opened {
    overflow: hidden;
    height: 100vh;
    min-height: auto;
  }
  .site-global.homepage.search-form-in-menu-opened > .header-core-bundle .nav-top-core-bundle, .site-global.homepage.search-form-in-menu-opened > .header-core-bundle .header-inner-core-bundle, .site-global.homepage.search-form-in-menu-opened > .footer-core-bundle {
    display: none;
  }
  .site-global.homepage.search-form-in-menu-opened .home-intro::before {
    opacity: 1;
    pointer-events: inherit;
    touch-action: inherit;
  }
  .site-global.homepage.search-form-in-menu-opened .search-form-core-bundle, .site-global.homepage.search-form-in-menu-opened .search-form-sarpgn-core-bundle, .site-global.homepage.search-form-in-menu-opened .search-form-sarpsp-core-bundle {
    left: 20px;
    right: inherit;
    bottom: inherit;
    top: 65px;
    width: calc(100% - 40px);
  }
  .site-global.homepage.search-form-in-menu-opened .search-form-core-bundle .container-core-bundle, .site-global.homepage.search-form-in-menu-opened .search-form-sarpgn-core-bundle .container-core-bundle, .site-global.homepage.search-form-in-menu-opened .search-form-sarpsp-core-bundle .container-core-bundle {
    padding: 0;
  }
  .site-global.homepage.search-form-in-menu-opened .search-form-core-bundle .btn-close-search, .site-global.homepage.search-form-in-menu-opened .search-form-sarpgn-core-bundle .btn-close-search, .site-global.homepage.search-form-in-menu-opened .search-form-sarpsp-core-bundle .btn-close-search {
    display: block;
    top: -52px;
    right: -2px;
  }
}

.search-form-autocomplete {
  position: absolute;
  padding: 10px 0;
  top: 72px;
  border-radius: 8px 8px 0 0;
  z-index: 12;
  display: block;
  width: 100%;
  max-height: calc(100vh - 175px);
  overflow: hidden auto;
  background-color: #fff;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.search-form-autocomplete ul {
  text-align: left;
  padding: 0;
  margin: 0;
}
.search-form-autocomplete ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-align: left;
  color: #222;
  font-size: 16;
  font-size: 1rem;
}
.search-form-autocomplete ul li a:not(.btn) {
  text-decoration: none;
  color: #222;
  display: block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.search-form-autocomplete ul li a:not(.btn) strong {
  font-weight: bold;
}
.search-form-autocomplete ul li a:not(.btn):hover {
  color: #0374bc;
  background-color: #ebebeb;
}
.search-form-autocomplete ul.search-results li {
  color: #222;
  padding: 0 !important;
}
.search-form-autocomplete ul.search-results li a {
  margin: 2px 0;
  padding: 7px 25px;
}
.search-form-autocomplete ul.search-results li:last-child {
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
.search-form-autocomplete ul.search-results li:last-child a {
  margin-bottom: 10px;
}
.search-form-autocomplete ul.search-results li.no-result {
  font-style: italic;
  padding: 7px 25px 17px !important;
  color: #666;
}
.search-form-autocomplete ul.search-links {
  padding: 10px 25px;
}
.search-form-autocomplete ul.search-links li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-form-autocomplete ul.search-links li a {
  text-align: left !important;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: normal;
  padding: 6px 15px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  color: #0374bc;
  border: 2px solid #0374bc;
  background-color: #fff;
  font-size: 1rem;
  transition: all 0.2s;
}
.search-form-autocomplete ul.search-links li a:focus, .search-form-autocomplete ul.search-links li a:hover {
  text-decoration: none;
  opacity: 1;
  color: #fff;
  border-color: #0374bc;
  background-color: #0374bc;
}
.search-form-autocomplete ul.search-links li:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .search-form-autocomplete {
    max-height: calc(100vh - 50%);
    border-radius: 8px 8px 0 0;
  }
  .search-form-autocomplete ul.search-results li {
    padding: 10px 25px;
  }
  .search-form-autocomplete::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
    content: "";
    bottom: 8px;
    left: 0;
  }
}
@media (min-width: 992px) {
  .search-form-autocomplete .agency, .site-global.homepage .search-form-autocomplete {
    top: inherit;
    bottom: 72px;
  }
}
@media screen and (max-height: 700px) and (min-width: 1024px) {
  .search-form-autocomplete {
    max-height: calc(100vh - 75%);
  }
}

.search-form-core-bundle.opened form, .search-form-sarpgn-core-bundle.opened form, .search-form-sarpsp-core-bundle.opened form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
}
.search-form-core-bundle.opened .search-form-autocomplete, .search-form-sarpgn-core-bundle.opened .search-form-autocomplete, .search-form-sarpsp-core-bundle.opened .search-form-autocomplete {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
  opacity: 1;
  pointer-events: inherit;
}
.search-form-core-bundle.opened .inner::before, .search-form-sarpgn-core-bundle.opened .inner::before, .search-form-sarpsp-core-bundle.opened .inner::before {
  content: "";
  border-radius: 0;
  width: 100%;
  height: 20px;
  position: absolute;
  z-index: 30;
  top: 100px;
  left: 0;
  background-color: #fff;
}
.search-form-core-bundle.opened .inner::after, .search-form-sarpgn-core-bundle.opened .inner::after, .search-form-sarpsp-core-bundle.opened .inner::after {
  content: "";
  border-radius: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 30;
  top: 118px;
  left: 0;
  background-color: #ebebeb;
}
.site-global.homepage .search-form-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::before {
  top: 64px;
}
.site-global.homepage .search-form-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::after {
  top: 72px;
}
.agency .search-form-core-bundle.opened .inner::before, .agency .search-form-core-bundle.opened .inner::after, .agency .search-form-sarpgn-core-bundle.opened .inner::before, .agency .search-form-sarpgn-core-bundle.opened .inner::after, .agency .search-form-sarpsp-core-bundle.opened .inner::before, .agency .search-form-sarpsp-core-bundle.opened .inner::after {
  width: calc(100% - 40px);
  left: 20px;
}
.agency .search-form-core-bundle.opened .inner::before, .agency .search-form-sarpgn-core-bundle.opened .inner::before, .agency .search-form-sarpsp-core-bundle.opened .inner::before {
  top: 128px;
}
.agency .search-form-core-bundle.opened .inner::after, .agency .search-form-sarpgn-core-bundle.opened .inner::after, .agency .search-form-sarpsp-core-bundle.opened .inner::after {
  top: 135px;
}
.agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before {
  content: "";
  border-radius: 0;
  width: 100%;
  height: 20px;
  position: absolute;
  z-index: 30;
  top: 100px;
  left: 0;
  background-color: #fff;
}
.agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after {
  content: "";
  border-radius: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 30;
  top: 118px;
  left: 0;
  background-color: #ebebeb;
}
@media (min-width: 768px) {
  .search-form-core-bundle.opened .search-form-autocomplete, .search-form-sarpgn-core-bundle.opened .search-form-autocomplete, .search-form-sarpsp-core-bundle.opened .search-form-autocomplete {
    padding-bottom: 0;
  }
  .search-form-core-bundle.opened .search-form-autocomplete::after, .search-form-sarpgn-core-bundle.opened .search-form-autocomplete::after, .search-form-sarpsp-core-bundle.opened .search-form-autocomplete::after {
    display: none;
  }
  .search-form-core-bundle.opened .inner::before, .search-form-core-bundle.opened .inner::after, .search-form-sarpgn-core-bundle.opened .inner::before, .search-form-sarpgn-core-bundle.opened .inner::after, .search-form-sarpsp-core-bundle.opened .inner::before, .search-form-sarpsp-core-bundle.opened .inner::after {
    width: calc(100% - 80px);
    left: 40px;
    top: 128px;
  }
  .search-form-core-bundle.opened .inner::after, .search-form-sarpgn-core-bundle.opened .inner::after, .search-form-sarpsp-core-bundle.opened .inner::after {
    top: 135px;
  }
  .agency .search-form-core-bundle.opened .inner::before, .agency .search-form-core-bundle.opened .inner::after, .site-global.homepage .search-form-core-bundle.opened .inner::before, .site-global.homepage .search-form-core-bundle.opened .inner::after, .agency .search-form-sarpgn-core-bundle.opened .inner::before, .agency .search-form-sarpgn-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::after, .agency .search-form-sarpsp-core-bundle.opened .inner::before, .agency .search-form-sarpsp-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::after {
    width: 100%;
    left: 0;
  }
  .agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after {
    width: calc(100% - 80px);
    left: 40px;
    top: 128px;
  }
  .agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after {
    top: 135px;
  }
}
@media (min-width: 992px) {
  .search-form-core-bundle.opened .inner::before, .search-form-core-bundle.opened .inner::after, .search-form-sarpgn-core-bundle.opened .inner::before, .search-form-sarpgn-core-bundle.opened .inner::after, .search-form-sarpsp-core-bundle.opened .inner::before, .search-form-sarpsp-core-bundle.opened .inner::after {
    width: calc(100% - 80px);
    left: 40px;
  }
  .site-global.homepage .search-form-core-bundle.opened .search-form-autocomplete, .site-global.homepage .search-form-sarpgn-core-bundle.opened .search-form-autocomplete, .site-global.homepage .search-form-sarpsp-core-bundle.opened .search-form-autocomplete {
    padding-bottom: 5px;
  }
  .site-global.homepage .search-form-core-bundle.opened .search-form-autocomplete::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .search-form-autocomplete::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .search-form-autocomplete::after {
    display: block;
    bottom: 2px;
  }
  .site-global.homepage .search-form-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::before {
    top: inherit;
    bottom: 54px;
    width: 100%;
    left: 0;
  }
  .site-global.homepage .search-form-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::after {
    display: none;
  }
  .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after {
    width: calc(100% - 80px);
    left: 40px;
  }
}
@media (min-width: 1200px) {
  .search-form-core-bundle.opened .inner::before, .search-form-core-bundle.opened .inner::after, .search-form-sarpgn-core-bundle.opened .inner::before, .search-form-sarpgn-core-bundle.opened .inner::after, .search-form-sarpsp-core-bundle.opened .inner::before, .search-form-sarpsp-core-bundle.opened .inner::after {
    width: calc(100% - 140px);
    left: 70px;
  }
  .agency .search-form-core-bundle.opened .inner::before, .site-global.homepage .search-form-core-bundle.opened .inner::before, .agency .search-form-sarpgn-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner::before, .agency .search-form-sarpsp-core-bundle.opened .inner::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner::before {
    width: 100%;
    left: 0;
  }
  .agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpgn-core-bundle.opened .inner.contact-or-meeting-page::after, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before, .agency .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::before, .site-global.homepage .search-form-sarpsp-core-bundle.opened .inner.contact-or-meeting-page::after {
    width: calc(100% - 140px);
    left: 70px;
  }
}

@media (min-width: 768px) {
  .sarpgn-website.cms .search-form-core-bundle,
  .sarpsp-website.cms .search-form-core-bundle,
  .sarpgn-website.agency .search-form-core-bundle,
  .sarpsp-website.agency .search-form-core-bundle {
    top: 85px !important;
  }
}

.iicon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  stroke: currentColor;
  overflow: hidden;
}

.iicon-no-stroke {
  stroke: none;
}

.logo-core-bundle {
  text-align: left;
  padding: 10px 10px 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.logo-core-bundle a {
  display: block;
  text-decoration: none;
}
.logo-core-bundle img.logo-image {
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.logo-core-bundle .bloc-logo {
  position: relative;
  height: 100%;
}
.logo-core-bundle img.logo-sarpsp-white {
  display: none;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.logo-core-bundle img.logo-sarpsp-white-without-baseline {
  display: block;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.logo-core-bundle img.logo-sarpsp-color-without-baseline {
  display: none;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
body:not(.homepage) .logo-core-bundle, .homepage.scrolling .logo-core-bundle, .search-form-in-menu-opened .logo-core-bundle {
  padding: 20px 10px 15px 20px;
}
body:not(.homepage) .logo-core-bundle img.logo-sarpsp-white, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-white, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-white {
  display: none;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
body:not(.homepage) .logo-core-bundle img.logo-sarpsp-white-without-baseline, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-white-without-baseline, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-white-without-baseline {
  display: none;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
body:not(.homepage) .logo-core-bundle img.logo-sarpsp-color-without-baseline, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-color-without-baseline, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-color-without-baseline {
  display: block;
  width: auto;
  height: 50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
@media (min-width: 768px) {
  .logo-core-bundle {
    padding: 20px;
  }
  .homepage .logo-core-bundle {
    padding: 40px 10px 40px 60px;
  }
  .homepage .logo-core-bundle img.logo-image {
    width: 95px;
    height: 95px;
  }
  .homepage .logo-core-bundle .container-nav {
    height: 175px;
  }
  .homepage .logo-core-bundle img.logo-sarpsp-white {
    position: absolute;
    display: block;
    width: auto;
    height: 120px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .homepage .logo-core-bundle img.logo-sarpsp-white-without-baseline {
    display: none;
    width: auto;
    height: 50px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .homepage .logo-core-bundle img.logo-sarpsp-color-without-baseline {
    position: absolute;
    display: block;
    opacity: 0;
    width: auto;
    height: 85px;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
  }
  body:not(.homepage) .logo-core-bundle, .homepage.scrolling .logo-core-bundle, .search-form-in-menu-opened .logo-core-bundle {
    padding: 20px 10px 15px 60px;
  }
  body:not(.homepage) .logo-core-bundle img.logo-image, .homepage.scrolling .logo-core-bundle img.logo-image, .search-form-in-menu-opened .logo-core-bundle img.logo-image {
    width: 50px;
    height: 50px;
  }
  body:not(.homepage) .logo-core-bundle .container-nav, .homepage.scrolling .logo-core-bundle .container-nav, .search-form-in-menu-opened .logo-core-bundle .container-nav {
    height: 55px;
  }
  body:not(.homepage) .logo-core-bundle img.logo-sarpsp-white, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-white, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-white {
    opacity: 0;
    position: absolute;
    display: block;
    width: auto;
    height: 60px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  body:not(.homepage) .logo-core-bundle img.logo-sarpsp-white-without-baseline, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-white-without-baseline, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-white-without-baseline {
    display: none;
    width: auto;
    height: 50px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  body:not(.homepage) .logo-core-bundle img.logo-sarpsp-color-without-baseline, .homepage.scrolling .logo-core-bundle img.logo-sarpsp-color-without-baseline, .search-form-in-menu-opened .logo-core-bundle img.logo-sarpsp-color-without-baseline {
    opacity: 1;
    position: absolute;
    display: block;
    width: auto;
    height: 60px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
}

.nav-top-core-bundle {
  position: relative;
  display: block;
  width: 100%;
  z-index: 7;
  height: 40px;
  overflow: visible;
  text-align: left;
  margin: 0;
  padding: 0;
  background-color: #ebebeb;
}
.nav-top-core-bundle li {
  margin: 0;
  display: block;
}
.nav-top-core-bundle li a {
  display: block;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  color: #0374bc;
  text-decoration: none;
  font-size: 13;
  font-size: 0.8125rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nav-top-core-bundle li a:hover {
  background-color: #dedede;
}
.nav-top-core-bundle li a.active {
  background-color: #0374bc;
  color: #fff;
}
.nav-top-core-bundle li a.menu-item-top-empty {
  position: relative;
  font-weight: bold;
}
.nav-top-core-bundle li a.menu-item-top-empty .iicon {
  position: absolute;
  right: 20px;
  top: 13px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform: translate3d(0, 0, O);
  -ms-transform: translate3d(0, 0, O);
  transform: translate3d(0, 0, O);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nav-top-core-bundle li a.menu-item-top-empty:hover {
  color: #fff;
  background-color: #0374bc;
}
.nav-top-core-bundle li a.menu-item-top-empty:hover .iicon {
  top: 15px;
}
.nav-top-core-bundle li:not(.is-mobile) {
  opacity: 0;
  pointer-events: none;
}
.nav-top-core-bundle:hover li a.menu-item-top-empty {
  background-color: #0374bc;
  color: #fff;
}
.nav-top-opened .nav-top-core-bundle {
  z-index: 10;
}
.nav-top-opened .nav-top-core-bundle li a.menu-item-top-empty {
  position: relative;
  font-weight: bold;
}
.nav-top-opened .nav-top-core-bundle li a.menu-item-top-empty .iicon {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform: translate3d(0, 0, O);
  -ms-transform: translate3d(0, 0, O);
  transform: translate3d(0, 0, O);
}
.nav-top-opened .nav-top-core-bundle li:not(.is-mobile) {
  opacity: 1;
  pointer-events: inherit;
  background-color: #fff;
}
@media (min-width: 768px) {
  .nav-top-core-bundle li {
    display: inline-block;
  }
  .nav-top-core-bundle li a {
    width: 100%;
  }
  .nav-top-core-bundle li:not(.is-mobile) {
    opacity: 1;
    pointer-events: inherit;
  }
}
.search-form-in-menu-opened .nav-top-core-bundle {
  z-index: 3;
}

.nav-icons-core-bundle {
  position: absolute;
  right: 0;
  top: 20px;
  margin-right: 20px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nav-icons-core-bundle ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.nav-icons-core-bundle li {
  padding: 0 10px;
  margin: 0;
  list-style-type: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nav-icons-core-bundle li a,
.nav-icons-core-bundle li button {
  color: #0374bc;
  padding: 0;
  display: block;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.header-inverted:not(.scrolling) .nav-icons-core-bundle li a,
.header-inverted:not(.scrolling) .nav-icons-core-bundle li button {
  color: #fff;
}
.nav-icons-core-bundle li a .iicon,
.nav-icons-core-bundle li button .iicon {
  font-size: 26;
  font-size: 1.625rem;
}
.nav-icons-core-bundle li a span.text,
.nav-icons-core-bundle li button span.text {
  padding-top: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14;
  font-size: 0.875rem;
}
.nav-icons-core-bundle li button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  margin: 0;
}
.nav-icons-core-bundle li button span.text {
  padding-top: 8px;
}
.nav-icons-core-bundle li:last-child {
  padding-right: 0;
}
@media (min-width: 768px) {
  .nav-icons-core-bundle {
    top: 55px;
  }
  body:not(.homepage) .nav-icons-core-bundle, .search-form-in-menu-opened .nav-icons-core-bundle, .scrolling .nav-icons-core-bundle {
    top: 22px;
  }
}
.homepage .nav-icons-core-bundle li.nav-icons-item-search {
  display: none;
}
.nav-icons-core-bundle li.nav-icons-item-user .iicon {
  stroke: none;
}
.is-connected.scrolling .nav-icons-core-bundle li.nav-icons-item-user a, .is-connected.cms .nav-icons-core-bundle li.nav-icons-item-user a,
.is-connected.scrolling .nav-icons-core-bundle li.nav-icons-item-user button,
.is-connected.cms .nav-icons-core-bundle li.nav-icons-item-user button {
  color: #f0941d;
}

.header-inverted:not(.scrolling) .logo-core-bundle em {
  display: none;
}
.header-inverted:not(.scrolling) .logo-core-bundle strong {
  display: none;
}

.big-nav {
  position: relative;
  z-index: 35;
}

.bm-item-list {
  z-index: 35;
  padding: 60px 20px;
  min-height: 100%;
}
.bm-item-list > ul.bm-item {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0 0 30px 0;
  min-height: 100%;
  overflow-y: auto;
}
.bm-item-list > ul.bm-item li {
  display: block;
  width: 100%;
}
.bm-item-list > ul.bm-item li.button a {
  margin: 10px auto;
  padding: 20px 0px;
  width: 100%;
  white-space: normal;
}
.bm-item-list > ul.bm-item a {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.bm-item-list > ul.bm-item a:hover {
  text-decoration: none;
}
.bm-item-list > ul.bm-item > li:not(.menu-item-logout):not(.menu-item-welcome):not(.button) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.bm-item-list > ul.bm-item > li > a {
  padding: 15px 0;
  font-weight: bold;
  text-transform: uppercase;
}
.bm-item-list > ul.bm-item > li > ul {
  padding: 0;
  margin: 0;
}
.bm-item-list > ul.bm-item > li > ul > li a {
  padding: 10px;
  text-transform: uppercase;
  font-size: 14;
  font-size: 0.875rem;
}
.bm-item-list > ul.bm-item > li > ul > li a:hover {
  text-decoration: underline;
}
.bm-item-list > ul.bm-item > li > ul > li:last-child {
  margin-bottom: 20px;
}
.bm-item-list > ul.bm-item > li.has-children {
  position: relative;
}
.bm-item-list > ul.bm-item > li.has-children > a:hover {
  padding-left: 10px;
}
.bm-item-list > ul.bm-item > li.has-children > a .iicon {
  position: absolute;
  right: 10px;
  top: 18px;
  display: inline-block;
}
.bm-item-list > ul.bm-item > li.has-children > ul {
  max-height: 0;
  transition: max-height 0.6s ease-out;
  overflow: hidden;
}
.bm-item-list > ul.bm-item > li.has-children.opened > a:hover {
  padding-left: 0;
}
.bm-item-list > ul.bm-item > li.has-children.opened > a::after {
  position: absolute;
  content: "_";
  right: 11px;
  top: -14px;
  font-size: 35px;
}
.bm-item-list > ul.bm-item > li.has-children.opened > a .iicon {
  display: none !important;
}
.bm-item-list > ul.bm-item > li.has-children.opened > ul {
  max-height: 500px;
  transition: max-height 0.6s ease-in;
}
@media (min-width: 768px) {
  .bm-item-list > ul.bm-item {
    padding-bottom: 35px;
    margin-bottom: 50px;
  }
  .bm-item-list > ul.bm-item > li > a {
    padding: 30px 0;
  }
  .bm-item-list > ul.bm-item > li > ul > li a {
    padding: 20px;
  }
  .bm-item-list > ul.bm-item > li.has-children > a .iicon {
    top: 32px;
  }
  .bm-item-list > ul.bm-item > li.has-children.opened > a::after {
    top: 1px;
  }
}
.bm-item-list .button {
  padding-top: 30px;
}
.bm-item-list .button .btn-skinny {
  background-color: #0374bc;
  border: 2px solid white;
}
.bm-item-list .button .btn-skinny:hover {
  background-color: white;
  color: #0374bc;
}
.bm-item-list .bm-item > .button ~ .button {
  padding-top: 0;
}
@media (min-width: 768px) {
  .bm-item-list {
    padding: 100px 100px 100px 60px;
  }
}

.widget-core-bundle {
  position: fixed;
  display: inline-block;
  left: 0;
  bottom: 0;
  width: 33.33%;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  letter-spacing: normal;
  font-weight: bold;
  line-height: 1.3;
  z-index: 6;
  background-color: #6bc024;
  color: #fff !important;
  font-size: 0.75rem;
  transform: translate3d(0, 100%, 0);
  transition: all 0.2s 0.1s;
}
.widget-core-bundle:focus, .widget-core-bundle:hover {
  text-decoration: none;
  color: #fff !important;
}
.widget-core-bundle span.text {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  padding-top: 30px;
}
.widget-core-bundle span.text .iicon {
  position: absolute;
  left: 50%;
  margin-left: -13px;
  top: -3px;
  stroke: none;
  width: 1.8rem;
  height: 1.8rem;
}
.widget-core-bundle span.text .mobile-hide {
  display: none;
}
.widget-core-bundle.widget-core-bundle-assistance {
  left: 33.33%;
  background-color: #0365a3;
}
.widget-core-bundle.widget-core-bundle-help {
  left: 66.66%;
  background-color: #f0941d;
}
.scrolling .widget-core-bundle {
  transform: translate3d(0, 0, 0);
}
@media (min-width: 768px) {
  .widget-core-bundle {
    right: 0;
    top: 240px;
    bottom: inherit;
    left: inherit;
    width: 300px;
    border-radius: 26px 0 0 26px;
    padding: 18px;
    font-size: 0.9375rem;
    transition: all 0.2s;
    transform: translate3d(240px, 0, 0);
  }
  .widget-core-bundle span.text {
    padding-top: 0;
    padding-left: 45px;
    width: 100%;
    text-align: left;
  }
  .widget-core-bundle span.text .iicon {
    left: 3px;
    top: -5px;
    margin-left: 0;
    font-size: 2rem;
  }
  .widget-core-bundle span.text .mobile-hide {
    display: inline-block;
  }
  .site-global .widget-core-bundle:focus, .site-global .widget-core-bundle:hover {
    transform: translate3d(15px, 0, 0);
  }
  .widget-core-bundle.widget-core-bundle-location-agencie-mine span.text .iicon, .widget-core-bundle.widget-core-bundle-location-agencie span.text .iicon {
    left: -1px;
    top: -4px;
  }
  .widget-core-bundle.widget-core-bundle-location-agencie-mine span.text-behavior span {
    display: inline-block;
    padding-right: 6px;
  }
  .widget-core-bundle.widget-core-bundle-assistance {
    left: inherit;
    top: 305px;
  }
  .widget-core-bundle.widget-core-bundle-assistance span.text .iicon {
    left: -1px;
    top: -4px;
  }
  .widget-core-bundle.widget-core-bundle-help {
    left: inherit;
    top: 370px;
  }
  .widget-core-bundle.widget-core-bundle-help span.text .iicon {
    left: -1px;
    top: -5px;
  }
  .widget-core-bundle:hover {
    transform: translate3d(15px, 0, 0);
  }
  .scrolling .widget-core-bundle {
    transform: translate3d(240px, 0, 0);
  }
  .scrolling .widget-core-bundle:hover {
    transform: translate3d(15px, 0, 0);
  }
}

.formula {
  text-align: center;
}
.formula .content {
  padding-top: 0;
  padding-bottom: 0;
}
.formula .content h2 {
  margin-bottom: 20px;
}
.formula table {
  position: relative;
}
.formula table caption {
  position: relative;
  font-size: 16;
  font-size: 1rem;
}
.formula table caption:before {
  position: absolute;
  content: "";
  width: 74%;
  height: 60px;
  bottom: -311px;
  right: 0;
  z-index: 5;
  box-shadow: 0 -90px 120px 0 rgba(0, 0, 0, 0.2);
}
.formula table caption:after {
  position: absolute;
  content: "";
  width: 300px;
  height: 60px;
  bottom: -311px;
  right: -300px;
  z-index: 5;
  background-color: #fff;
}
.formula table i {
  font-style: normal;
}
.formula table i.fa {
  font-size: 28;
  font-size: 1.75rem;
}
.formula table i.fa-underscore {
  position: relative;
  display: block;
  width: 100%;
  height: 20px;
}
.formula table i.fa-underscore:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 3px;
  background-color: #fec05a;
  font-weight: bold;
  left: 50%;
  margin-left: -10px;
  top: 10px;
}
.formula table td.col-space,
.formula table th.col-space {
  width: 1%;
  padding: 0 !important;
  background-color: transparent;
}
.formula table td.col-space:before,
.formula table th.col-space:before {
  display: none;
}
.formula table td.col-space {
  background-color: #fff;
}
.formula table thead th {
  position: relative;
  z-index: 2;
  vertical-align: top;
  padding-top: 30px;
  background-color: #fff;
}
.formula table thead th:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  background-color: #ffe7bf;
  border-radius: 10px 10px 0 0;
}
.formula table thead th:first-child {
  padding: 0;
  text-align: right;
  background-color: transparent;
}
.formula table thead th:first-child:before {
  display: none;
}
.formula table thead th span {
  display: block;
  clear: both;
}
.formula table thead th span.formula-type {
  font-weight: normal;
  text-decoration: underline;
  line-height: 1.1em;
  margin-bottom: 5px;
  font-size: 15;
  font-size: 0.9375rem;
}
.formula table thead th span.formula-label {
  font-weight: bold;
  line-height: 1.1em;
  margin-bottom: 5px;
  font-size: 27;
  font-size: 1.6875rem;
}
.formula table thead th span.formula-description {
  font-weight: normal;
  font-style: italic;
  line-height: 1.2em;
  font-size: 14;
  font-size: 0.875rem;
}
.formula table tbody {
  position: relative;
  z-index: 5;
  background-color: #fff;
}
.formula table tbody tr {
  border-bottom: 1px solid #fff;
}
.formula table tbody td {
  width: 18%;
}
.formula table tbody td[scope] {
  position: relative;
  z-index: 2;
  width: 24%;
  padding-right: 60px;
  line-height: 1.2em;
  vertical-align: middle;
  text-align: left;
  background-color: #c4c4c4;
  color: black;
  border-radius: 10px 0 0 10px;
}
.formula table tbody td[scope] span.help {
  position: absolute;
  right: 12px;
  top: 50%;
  background: black;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color: #c4c4c4;
  font-weight: bold;
  cursor: pointer;
  font-size: 17;
  font-size: 1.0625rem;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.formula table tbody td[scope] span.help span.help-text {
  position: absolute;
  left: 47px;
  top: -8px;
  width: 240px;
  height: auto;
  border-radius: 10px;
  z-index: 5;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  font-size: 12;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  line-height: 1.1em;
  color: black;
  text-align: left;
}
.formula table tbody td[scope] span.help:hover {
  color: #fec05a;
}
.formula table tbody td[scope] span.help:hover span.help-text {
  color: black;
}
.formula table tbody tr:nth-of-type(odd) td[scope] {
  background-color: #eee;
}
.formula table tbody tr:nth-of-type(odd) td[scope] span.help {
  color: #eee;
}
.formula table tbody tr:nth-of-type(odd) td[scope] span.help span.help-text {
  color: black;
}
.formula table tbody tr:nth-of-type(odd) td[scope] span.help:hover {
  color: #ff9600;
}
.formula table tbody tr:nth-of-type(odd) td[scope] span.help:hover span.help-text {
  color: black;
}
.formula .legend {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.formula .legend li {
  position: relative;
  display: inline-block;
  color: #ff9600;
  margin-left: 20px;
  padding-left: 25px;
}
.formula .legend li:first-child {
  font-style: italic;
  color: black;
}
.formula .legend li i {
  font-style: normal;
}
.formula .legend li i.fa {
  position: absolute;
  left: 0;
  bottom: 10px;
  font-size: 35;
  font-size: 2.1875rem;
}
.formula .legend li i.fa-underscore:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 3px;
  background-color: #ff9600;
  font-weight: bold;
  left: 0;
  top: 0;
}
.formula .legend li i.fa-check {
  bottom: 0;
  font-size: 20;
  font-size: 1.25rem;
}
.formula .note-pdf {
  margin-bottom: 30px;
}
.formula .note-pdf a {
  text-decoration: underline;
}
.formula .note-pdf a:focus, .formula .note-pdf a:hover {
  text-decoration: none;
}
.formula .action {
  text-align: center;
  padding-bottom: 60px;
}

.formula {
  margin: 40px 0px 0px 0px;
}

.col-md-2ths,
.col-md-3ths,
.col-md-4ths,
.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.detail-formula:before {
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: 10px;
  height: 10px;
  background-color: #fec05a;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}

.detail-push-formula:before {
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: 0;
  height: 10px;
  background-color: #6BC024;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}

.space-top {
  height: 10px;
  display: block;
}

.detail-formula {
  margin-top: 10px;
  box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
  width: 100%;
}
.detail-formula .texte-formula {
  color: #fec05a;
}
.detail-formula .separator-formula {
  height: 4px;
  width: 23px;
  margin: 20px auto;
  background-color: #fec05a;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.detail-formula .wysiwyg-formula, .detail-formula .wysiwyg-formula-horizontal {
  text-align: left;
}
.detail-formula .wysiwyg-formula ul li:before, .detail-formula .wysiwyg-formula-horizontal ul li:before {
  display: inline-block;
  transform: rotate(45deg);
  width: 8px;
  height: 15px;
  content: "";
  margin: 0 10px 0 10px;
}
.detail-formula .wysiwyg-formula ul, .detail-formula .wysiwyg-formula-horizontal ul {
  display: table;
}
.detail-formula .wysiwyg-formula ul li, .detail-formula .wysiwyg-formula-horizontal ul li {
  display: table-row;
  margin: 10px 0;
  font-size: 15px;
}
.detail-formula .wysiwyg-formula ul li span, .detail-formula .wysiwyg-formula-horizontal ul li span {
  vertical-align: middle;
  display: table-cell;
  padding-bottom: 13px;
}

.wysiwyg-formula-horizontal {
  padding-left: 17px !important;
}
.wysiwyg-formula-horizontal p {
  margin: 1em 0;
}

.detail-push-formula {
  margin-top: 10px;
  border-right: 5px solid #6BC024;
  border-left: 5px solid #6BC024;
  border-bottom: 5px solid #6BC024;
  box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-box-shadow: 0 8px 14px 0 rgba(70, 74, 83, 0.5);
  -webkit-border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
  width: 100%;
}
.detail-push-formula .texte-formula {
  color: #6BC024;
}
.detail-push-formula .separator-formula {
  height: 4px;
  width: 23px;
  margin: 20px auto;
  background-color: #6BC024;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
}
.detail-push-formula .wysiwyg-formula {
  text-align: left;
}
.detail-push-formula .wysiwyg-formula ul li:before {
  display: inline-block;
  transform: rotate(45deg);
  width: 8px;
  height: 15px;
  content: "";
  margin: 0 10px 0 10px;
}
.detail-push-formula .wysiwyg-formula ul {
  display: table;
}
.detail-push-formula .wysiwyg-formula ul li {
  display: table-row;
  margin: 10px 0;
  font-size: 15px;
}
.detail-push-formula .wysiwyg-formula ul li span {
  vertical-align: middle;
  display: table-cell;
  padding-bottom: 13px;
}

h2.title-2-custom span {
  font-size: 1.7em;
  margin-bottom: 15px;
}

.icon-formula {
  padding-top: 20px;
}

.icons-why-formula {
  height: 50px;
  margin-bottom: 15px;
}

.titre-formula {
  font-weight: bold;
  font-size: 25px;
  padding: 0 35px 10px 35px;
}

.titre-formula-horizontal {
  font-weight: bold;
  font-size: 25px;
  padding: 0 35px 0 15px;
}

.push-formula {
  padding: 10px;
}

.header-core-bundle {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  display: block;
  z-index: 50;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
body:not(.homepage) .header-core-bundle, .homepage.scrolling .header-core-bundle, .search-form-in-menu-opened .header-core-bundle {
  height: auto;
}
.search-open .header-core-bundle .nav-top-core-bundle,
.search-open .header-core-bundle .header-inner-core-bundle {
  pointer-events: none;
}

.header-inner-core-bundle {
  position: absolute;
  left: 0;
  z-index: 7;
  top: 40px;
  width: 100%;
  display: flex;
  justify-content: left;
}
.search-form-in-menu-opened .header-inner-core-bundle, .scrolling .header-inner-core-bundle, .cms .header-inner-core-bundle {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.search-form-in-menu-opened .header-inner-core-bundle {
  z-index: 3;
}

a span.text-icon-espace-perso {
  font-family: sans-serif;
}

.footer-core-bundle {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #ebebeb;
}
.footer-core-bundle .agencie-sarpgn-link .text::before {
  background-image: url(../../../public/images/mascottes/mascotte-sarpgn3.png) !important;
}
.footer-core-bundle .agencie-sarpsp-link .text::before {
  background-image: url(../../../public/images/mascottes/mascotte-sarpgn3.png);
}
.footer-core-bundle .agencie-link, .footer-core-bundle .agencie-sarpgn-link, .footer-core-bundle .agencie-sarpsp-link {
  position: relative;
  overflow: hidden;
  border-top: 5px solid #6bc024;
  background-image: url(../../../public/images/footer/bg-map.jpg);
  background-size: cover;
  text-align: center;
  background-position: center center;
}
.footer-core-bundle .agencie-link img.map-france, .footer-core-bundle .agencie-sarpgn-link img.map-france, .footer-core-bundle .agencie-sarpsp-link img.map-france {
  position: absolute;
  height: 190px;
  left: 120px;
  width: auto;
}
.footer-core-bundle .agencie-link .inner, .footer-core-bundle .agencie-sarpgn-link .inner, .footer-core-bundle .agencie-sarpsp-link .inner {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px 0;
  min-height: 310px;
}
.footer-core-bundle .agencie-link .text, .footer-core-bundle .agencie-sarpgn-link .text, .footer-core-bundle .agencie-sarpsp-link .text {
  padding-left: 400px;
  display: block;
  width: 100%;
  flex: 1;
}
.footer-core-bundle .agencie-link .text::before, .footer-core-bundle .agencie-sarpgn-link .text::before, .footer-core-bundle .agencie-sarpsp-link .text::before {
  position: absolute;
  left: 760px;
  bottom: -32px;
  z-index: 1;
  display: block;
  width: 143px;
  height: 162px;
  content: "";
  background-image: url(../../../public/images/mascottes/mascotte3.png);
  background-position: center center;
  background-repeat: no-repeat;
}
.footer-core-bundle .agencie-link .text .title-your-agencie, .footer-core-bundle .agencie-sarpgn-link .text .title-your-agencie, .footer-core-bundle .agencie-sarpsp-link .text .title-your-agencie {
  font-weight: bold;
  padding-right: 60px;
  padding-top: 30px;
  color: #424242;
  line-height: 1.3;
  margin: 0 0 35px 0;
  font-size: 28;
  font-size: 1.75rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie::before, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie::before, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie::before {
  display: none;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p {
  margin: 0;
  line-height: 1.3;
  color: #585858;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.agencie-name, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.agencie-name, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.agencie-name {
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 0;
  font-size: 27;
  font-size: 1.6875rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.agent-name, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.agent-name, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.agent-name {
  font-weight: bold;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number, .footer-core-bundle .agencie-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link {
  position: relative;
  padding-left: 34px;
  padding-top: 12px;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number img,
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number .iicon, .footer-core-bundle .agencie-link .text.text-your-agencie p.open-status img,
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status .iicon, .footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number img,
.footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number .iicon, .footer-core-bundle .agencie-link .text.text-your-agencie p.email-link img,
.footer-core-bundle .agencie-link .text.text-your-agencie p.email-link .iicon, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number img,
.footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number .iicon, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status img,
.footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status .iicon, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number img,
.footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number .iicon, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link img,
.footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link .iicon, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number img,
.footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number .iicon, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status img,
.footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status .iicon, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number img,
.footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number .iicon, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link img,
.footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link .iicon {
  position: absolute;
  left: 0;
  top: 9px;
  font-size: 25;
  font-size: 1.5625rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number {
  display: flex;
  padding-left: 0;
  font-size: 13;
  font-size: 0.8125rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number > .tooltip, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number > .tooltip, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number > .tooltip {
  width: 27px;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.orias-number .iicon, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.orias-number .iicon, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.orias-number .iicon {
  top: 0;
  font-size: 20;
  font-size: 1.25rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link {
  font-weight: bold;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status img, .footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number img, .footer-core-bundle .agencie-link .text.text-your-agencie p.email-link img, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status img, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number img, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link img, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status img, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number img, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link img {
  margin-top: 4px;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status {
  text-transform: uppercase;
  font-size: 18;
  font-size: 1.125rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status.is-opened, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status.is-opened, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status.is-opened {
  color: #6bc024;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.open-status.is-closed, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.open-status.is-closed, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.open-status.is-closed {
  color: #f0941d;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.phone-number, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.phone-number {
  font-size: 23;
  font-size: 1.4375rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link {
  padding-bottom: 18px;
  font-size: 18;
  font-size: 1.125rem;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.email-link a, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link a, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link a {
  color: #585858;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.email-link a:hover, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link a:hover, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link a:hover {
  text-decoration: underline;
}
.footer-core-bundle .agencie-link .text.text-your-agencie p.email-link img, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie p.email-link img, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie p.email-link img {
  margin-top: 6px;
}
.footer-core-bundle .agencie-link .text.text-your-agencie .text-left, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie .text-left, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie .text-left {
  float: left;
  padding-right: 35px;
  padding-top: 40px;
}
.footer-core-bundle .agencie-link .text.text-your-agencie .text-left img, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie .text-left img, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie .text-left img {
  width: 20px;
  height: auto;
}
.footer-core-bundle .agencie-link .text.text-your-agencie .text-left.text-left-contact, .footer-core-bundle .agencie-sarpgn-link .text.text-your-agencie .text-left.text-left-contact, .footer-core-bundle .agencie-sarpsp-link .text.text-your-agencie .text-left.text-left-contact {
  padding-right: 0;
  padding-top: 20px;
}
.footer-core-bundle .btn-bandeau-agence-core-bundle {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: normal;
  padding: 6px 15px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border: 2px solid #6bc024;
  background-color: #6bc024;
  font-size: 1rem;
  transition: all 0.2s;
}
.footer-core-bundle .btn-bandeau-agence-core-bundle:focus, .footer-core-bundle .btn-bandeau-agence-core-bundle:hover {
  text-decoration: none;
  opacity: 0.8;
}
@media (min-width: 768px) {
  .footer-core-bundle .btn-bandeau-agence-core-bundle {
    text-transform: uppercase;
    padding: 15px 30px;
    font-size: 1.25rem;
  }
}
.footer-core-bundle .btn-to-top-core-bundle {
  float: right;
  position: relative;
  margin-top: -225px;
  display: block;
  width: 42px;
  height: 42px;
  z-index: 3;
  border: 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  color: #0374bc;
}
.footer-core-bundle .btn-to-top-core-bundle .iicon {
  position: absolute;
  left: 14px;
  top: 5px;
  height: 2rem;
  width: 1rem;
  transition: all 0.2s;
  transform: rotate(-90deg);
}
.footer-core-bundle .btn-to-top-core-bundle:hover {
  background-color: #fff;
  color: #0374bc;
}
.footer-core-bundle .btn-to-top-core-bundle:hover .iicon {
  top: 2px;
}
@media (min-width: 768px) {
  .footer-core-bundle .btn-to-top-core-bundle {
    position: fixed;
    right: 20px;
    bottom: 22px;
    opacity: 0;
    transition: all 0.2s;
    transform: translate3d(0, 120px, 0);
  }
  .scrolling .footer-core-bundle .btn-to-top-core-bundle {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.footer-core-bundle .footer-links-bottom {
  background-color: #ebebeb;
  font-weight: 600;
  margin-bottom: 70px;
  padding-bottom: 15px;
}
.footer-core-bundle .footer-links-bottom p {
  color: #424242;
  margin: 1.6em 0;
  font-size: 13;
  font-size: 0.8125rem;
}
.footer-core-bundle .footer-links-bottom p a {
  text-decoration: none;
  color: #424242;
  font-size: 13;
  font-size: 0.8125rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.footer-core-bundle .footer-links-bottom p a:hover {
  color: #000;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .footer-core-bundle .footer-links-bottom {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  .footer-core-bundle .footer-links-bottom p {
    float: none;
    display: inline-block;
    width: auto;
    padding: 0 45px;
  }
}
.footer-core-bundle .footer-container {
  background-color: #0374bc;
  padding-bottom: 20px;
  color: #fff;
}
.footer-core-bundle .footer-container .col {
  display: block;
  padding-bottom: 20px;
  text-align: left;
}
.footer-core-bundle .footer-container .footer-inner-title-col {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 16;
  font-size: 1rem;
}
.footer-core-bundle .footer-container ul {
  padding: 0;
  margin: 0;
}
.footer-core-bundle .footer-container ul li {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  line-height: 1;
}
.footer-core-bundle .footer-container ul li a {
  padding: 0;
  line-height: 1.2;
  text-decoration: none;
  color: #fff;
  font-size: 16;
  font-size: 1rem;
}
.footer-core-bundle .footer-container ul li a:hover {
  text-decoration: underline;
}
.footer-core-bundle .footer-social {
  padding: 18px 0 22px;
  margin-bottom: 15px;
  font-weight: bold;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}
.footer-core-bundle .footer-social a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
  padding: 1px;
  border-radius: 100%;
  color: #fff;
  text-decoration: none;
  font-size: 30;
  font-size: 1.875rem;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.footer-core-bundle .footer-social a:hover {
  background: #fff;
  color: #0374bc;
}
.footer-core-bundle .footer-social a .iicon {
  display: block;
}
.footer-core-bundle .footer-social a .iicon-facebook {
  stroke: none;
}
@media (min-width: 768px) {
  .footer-core-bundle .footer-inner {
    display: flex;
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-core-bundle .footer-inner .col {
    min-width: 16.66%;
    padding-right: 15px;
  }
  .footer-core-bundle .footer-inner .footer-inner-title-col {
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 13;
    font-size: 0.8125rem;
  }
  .footer-core-bundle .footer-inner ul {
    padding-bottom: 15px;
  }
  .footer-core-bundle .footer-inner ul li {
    margin-bottom: 5px;
  }
  .footer-core-bundle .footer-inner ul li a {
    line-height: 1;
    font-size: 13;
    font-size: 0.8125rem;
  }
}
.search-form-in-menu-opened .footer-core-bundle {
  z-index: 3;
}
.body-menu-navigation-opened .footer-core-bundle .btn-to-top-core-bundle {
  display: none;
}
.footer-core-bundle .footer-without-social {
  padding-top: 60px;
}

body.error-page .main {
  background-color: #ebebeb;
  padding-top: 100px;
  margin-bottom: 3em;
}
body.error-page .main .section {
  margin-top: 3rem;
}
body.error-page .main .push-navigation {
  background-color: white;
  text-align: left;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  body.error-page .main {
    padding-top: 196px;
  }
}
body.error-page .main .push-inner {
  padding: 11px;
}
body.error-page .main .push-inner p {
  margin-top: 0;
  line-height: 1.3;
  font-size: 1rem;
}
body.error-page .main .push-inner p.cta {
  margin-top: auto;
}
body.error-page .main .push-inner span.icon-widget {
  width: 50px;
  border-radius: 26px 0 0 26px;
  font-size: 0.9375rem;
  background-color: #f0941d;
  color: white;
  margin: 10px auto;
  margin-bottom: 1rem;
  -webkit-box-shadow: 4px 8px 14px 0 rgba(216, 221, 230, 0.5);
  -moz-box-shadow: 4px 8px 14px 0 rgba(216, 221, 230, 0.5);
  box-shadow: 4px 8px 14px 0 rgba(216, 221, 230, 0.5);
}
body.error-page .main .push-inner span.icon-help {
  background-color: #f0941d;
}
body.error-page .main .push-inner span.icon-assistance {
  background-color: #0365a3;
}
body.error-page .main .push-inner span.icon-location {
  background-color: #6bc024;
}
body.error-page .main .push-inner span.icons-blue {
  color: #0374bc;
  display: block;
  font-weight: 700;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  padding-bottom: 1rem;
}
body.error-page .main .push-inner span.icons-blue .iicon {
  font-size: 2rem;
}
body.error-page .main .push-inner span.icons-blue .iicon-logo, body.error-page .main .push-inner span.icons-blue .iicon-user {
  width: 3.75rem;
  height: 3rem;
}
body.error-page .main .push-inner span.icons-blue .iicon-logo.iicon-sarpsp-logo {
  width: 12rem;
  height: 3rem;
}
body.error-page .main .push-inner span.icons-blue .iicon-habitation, body.error-page .main .push-inner span.icons-blue .iicon-car {
  width: 4.75rem;
  height: 3rem;
}
body.error-page .main .push-inner span.icons-blue .text {
  font-size: 0.875rem;
  text-transform: uppercase;
}
body.error-page .main .btn {
  font-size: 1.05em !important;
}
body.error-page .main ul li,
body.error-page .main ol li,
body.error-page .main p {
  color: #444444;
  line-height: 1.3;
  font-size: 1.125rem;
}
body.error-page .main p {
  margin: 1.6em 0;
}
body.error-page .main .inner {
  width: 800px;
  max-width: 100%;
  background-color: #fff;
  padding: 60px;
  margin: 30px auto;
  border-radius: 10px 0 10px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  color: #0374bc;
}
@media (max-width: 767px) {
  body.error-page .main .inner {
    padding: 20px;
  }
}
body.error-page .main .inner .title, body.error-page .main .inner body.institutionnelle .editable h3, body.institutionnelle .editable body.error-page .main .inner h3,
body.error-page .main .inner body.institutionnelle .preview h3,
body.institutionnelle .preview body.error-page .main .inner h3,
body.error-page .main .inner body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element body.error-page .main .inner h3,
body.error-page .main .inner body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg body.error-page .main .inner h3,
body.error-page .main .inner body.sonata-bc .editable h3,
body.sonata-bc .editable body.error-page .main .inner h3,
body.error-page .main .inner body.sonata-bc .preview h3,
body.sonata-bc .preview body.error-page .main .inner h3,
body.error-page .main .inner body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element body.error-page .main .inner h3,
body.error-page .main .inner body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg body.error-page .main .inner h3, body.error-page .main .inner body.institutionnelle .editable h2, body.institutionnelle .editable body.error-page .main .inner h2,
body.error-page .main .inner body.institutionnelle .preview h2,
body.institutionnelle .preview body.error-page .main .inner h2,
body.error-page .main .inner body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element body.error-page .main .inner h2,
body.error-page .main .inner body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg body.error-page .main .inner h2,
body.error-page .main .inner body.sonata-bc .editable h2,
body.sonata-bc .editable body.error-page .main .inner h2,
body.error-page .main .inner body.sonata-bc .preview h2,
body.sonata-bc .preview body.error-page .main .inner h2,
body.error-page .main .inner body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element body.error-page .main .inner h2,
body.error-page .main .inner body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg body.error-page .main .inner h2, body.error-page .main .inner body.cms main h3, body.cms main body.error-page .main .inner h3, body.error-page .main .inner body.cms main h2, body.cms main body.error-page .main .inner h2 {
  margin-bottom: 30px;
}
body.error-page .main .inner .btn {
  margin-top: 30px;
}
body.error-page .main h2 {
  font-size: 1.75rem !important;
}

@media (min-width: 768px) {
  body.error-page.sarpgn .main {
    padding-top: 100px;
  }
}

body.error-page .main .inner.error-500 {
  margin: 0 auto 100px;
}

.js-expandmore {
  width: 810px;
  max-width: 100%;
  margin: 10px auto 0 auto;
}

.js-expandmore-button {
  border: 0.125rem solid #6bc024;
  border-radius: 10px 0;
  padding: 22px 32px;
  background-color: #fff;
  text-align: left;
  width: 100%;
  font-weight: bold;
  color: #6bc024;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
}
.js-expandmore-button:focus {
  outline: none;
}
.js-expandmore-button .iicon {
  margin-left: auto;
  min-width: 1.5rem;
  height: 1.5rem;
  transform: rotate(180deg);
  stroke-width: 10%;
  fill: #6bc024;
  color: #6bc024;
  stroke: #6bc024;
}
.js-expandmore-button[aria-expanded=true] {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}
.js-expandmore-button[aria-expanded=true] .iicon {
  transform: rotate(0deg);
}

.js-to_expand {
  width: 810px;
  max-width: 100%;
  margin: -1px auto 10px auto;
  text-align: left;
  padding: 0 32px;
}
.js-to_expand[aria-hidden=true], .js-to_expand[data-hidden=true] {
  display: none;
}
.js-to_expand:not([aria-hidden=true]), .js-to_expand:not([data-hidden=true]) {
  border: 0.125rem solid #6bc024;
  border-top: 0;
  border-radius: 0 0 10px 0;
}

.js-to_expand_inner {
  padding-bottom: 22px;
}

.animated-expandmore__to_expand {
  display: block;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
}
.animated-expandmore__to_expand:not([data-hidden=true]) {
  transition: visibility 0s ease, max-height 2s ease;
}
.animated-expandmore__to_expand[data-hidden=true] {
  display: block;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.expandmore__button[aria-expanded=false] > .expandmore__symbol::before,
.expandmore__button[data-expanded=false] > .expandmore__symbol::before {
  content: "+ ";
}

.expandmore__button[aria-expanded=true] > .expandmore__symbol::before,
.expandmore__button[data-expanded=true] > .expandmore__symbol::before {
  content: "− ";
}

.expandmore__button {
  background: none;
  font-size: inherit;
  color: inherit;
}

.full-block-expand {
  width: 810px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0705882353);
  border-radius: 13px 0;
  overflow: hidden;
}
.full-block-expand div.js-to_expand {
  border: none;
  margin: 0;
}
.full-block-expand .block-accordeon-mobilite svg.iicon {
  color: #a2a2a2;
  stroke: #a2a2a2;
}
.full-block-expand .block-accordeon-mobilite.js-expandmore {
  margin: 0;
}
.full-block-expand .block-accordeon-mobilite.js-expandmore button.js-expandmore-button[aria-expanded=false] {
  border: none;
}
.full-block-expand .block-accordeon-mobilite.js-expandmore button.js-expandmore-button[aria-expanded=true] {
  border: none;
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

div.section-accordion div.section-inner {
  margin: 10px auto;
}

html.js-enabled .section.to-animate {
  opacity: 0;
  transition: all 0.8s;
  transform: translate3d(0, 2%, 0);
}
html.js-enabled .section.to-animate.is-animated {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
html.js-enabled .push-simple.to-animate,
html.js-enabled .push-number.to-animate {
  opacity: 0;
  transition: all 0.8s;
  transform: translate3d(0, 2%, 0);
  transition-delay: 0.2s;
}
html.js-enabled .push-simple.to-animate.is-animated,
html.js-enabled .push-number.to-animate.is-animated {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(1),
html.js-enabled .push-number.to-animate + .push:nth-of-type(1) {
  transition-delay: 0.2s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(2),
html.js-enabled .push-number.to-animate + .push:nth-of-type(2) {
  transition-delay: 0.4s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(3),
html.js-enabled .push-number.to-animate + .push:nth-of-type(3) {
  transition-delay: 0.6s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(4),
html.js-enabled .push-number.to-animate + .push:nth-of-type(4) {
  transition-delay: 0.8s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(5),
html.js-enabled .push-number.to-animate + .push:nth-of-type(5) {
  transition-delay: 1s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(6),
html.js-enabled .push-number.to-animate + .push:nth-of-type(6) {
  transition-delay: 1.2s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(7),
html.js-enabled .push-number.to-animate + .push:nth-of-type(7) {
  transition-delay: 1.4s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(8),
html.js-enabled .push-number.to-animate + .push:nth-of-type(8) {
  transition-delay: 1.6s;
}
html.js-enabled .push-simple.to-animate + .push:nth-of-type(9),
html.js-enabled .push-number.to-animate + .push:nth-of-type(9) {
  transition-delay: 1.8s;
}
html.js-enabled .push-star.to-animate > img, html.js-enabled .push-star.to-animate > .push-inner {
  transition: all 0.8s;
}
html.js-enabled .push-star.to-animate > img {
  opacity: 0;
  transform: translate3d(0, -2%, 0);
}
html.js-enabled .push-star.to-animate > .push-inner {
  transform: translate3d(0, 2%, 0);
}
html.js-enabled .push-star.to-animate.is-animated > img, html.js-enabled .push-star.to-animate.is-animated > .push-inner {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
html.js-enabled .institutionnelle .first-line.to-animate,
html.js-enabled .institutionnelle .banner-subtitle.to-animate {
  opacity: 0;
  transition: all 0.8s;
  transform: translate3d(0, 2%, 0);
}
html.js-enabled .institutionnelle .first-line.to-animate.is-animated,
html.js-enabled .institutionnelle .banner-subtitle.to-animate.is-animated {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
html.js-enabled .institutionnelle .banner-subtitle.to-animate {
  transition-delay: 0.4s;
}

.icone {
  margin: 0 auto;
}

main .banner {
  margin: 110px 0 0 0;
}

main .banner-sarpgn {
  margin: 85px 0 0 0 !important;
}

main .banner-sarpsp {
  margin: 85px 0 0 0 !important;
}

.banner, .banner-sarpgn, .banner-sarpsp {
  height: 110px;
  padding: 0;
  overflow: hidden;
}
@media (min-width: 568px) {
  .banner:not(.is-empty), .banner-sarpgn:not(.is-empty), .banner-sarpsp:not(.is-empty) {
    height: 170px;
  }
}
@media (min-width: 768px) {
  .banner:not(.is-empty), .banner-sarpgn:not(.is-empty), .banner-sarpsp:not(.is-empty) {
    height: 220px;
  }
}
@media (min-width: 992px) {
  .banner:not(.is-empty), .banner-sarpgn:not(.is-empty), .banner-sarpsp:not(.is-empty) {
    height: 280px;
  }
}
@media (min-width: 1440px) {
  .banner:not(.is-empty), .banner-sarpgn:not(.is-empty), .banner-sarpsp:not(.is-empty) {
    height: 330px;
  }
}
.banner.is-empty, .banner-sarpgn.is-empty, .banner-sarpsp.is-empty {
  background: linear-gradient(to right, #0374bc 0%, #0788da 100%);
}
@media (min-width: 1440px) {
  .banner.is-empty, .banner-sarpgn.is-empty, .banner-sarpsp.is-empty {
    height: 200px;
  }
}
.banner img, .banner-sarpgn img, .banner-sarpsp img {
  display: block;
  max-width: 100%;
  height: auto;
}
.banner.banner-cms .parallax, .banner-sarpgn.banner-cms .parallax, .banner-sarpsp.banner-cms .parallax {
  overflow: hidden;
  height: 420px;
  position: relative;
}
.banner.banner-cms .parallax img, .banner-sarpgn.banner-cms .parallax img, .banner-sarpsp.banner-cms .parallax img {
  position: absolute;
  top: 0;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
@media (min-width: 1440px) {
  .banner.banner-cms .parallax img, .banner-sarpgn.banner-cms .parallax img, .banner-sarpsp.banner-cms .parallax img {
    margin-top: -100px;
  }
}
.banner.banner-sass, .banner-sarpgn.banner-sass, .banner-sarpsp.banner-sass {
  height: 400px !important;
  position: relative;
}
.banner.banner-sass .parallax, .banner-sarpgn.banner-sass .parallax, .banner-sarpsp.banner-sass .parallax {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.banner.banner-sass .parallax img, .banner-sarpgn.banner-sass .parallax img, .banner-sarpsp.banner-sass .parallax img {
  position: absolute;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.banner.banner-sass .parallax.compat-object-fit, .banner-sarpgn.banner-sass .parallax.compat-object-fit, .banner-sarpsp.banner-sass .parallax.compat-object-fit {
  background-size: cover;
  background-position: center center;
}
.banner.banner-sass .parallax.compat-object-fit img, .banner-sarpgn.banner-sass .parallax.compat-object-fit img, .banner-sarpsp.banner-sass .parallax.compat-object-fit img {
  display: none;
}
.banner.banner-sass::before, .banner-sarpgn.banner-sass::before, .banner-sarpsp.banner-sass::before {
  position: absolute;
  z-index: 2;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(94, 94, 94, 0) 100%);
  opacity: 0.6;
}
.banner.banner-sass .banner-inner, .banner-sarpgn.banner-sass .banner-inner, .banner-sarpsp.banner-sass .banner-inner {
  position: absolute;
  z-index: 3;
  width: 90%;
  bottom: 30px;
  left: 5%;
}
.banner.banner-full, .banner-sarpgn.banner-full, .banner-sarpsp.banner-full {
  position: relative;
  font-family: "Open Sans", "Helvetica Neue", Arial, Roboto, sans-serif;
  height: 100vh;
  margin-top: 0;
}
.banner.banner-full .parallax, .banner-sarpgn.banner-full .parallax, .banner-sarpsp.banner-full .parallax {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.banner.banner-full .parallax img, .banner-sarpgn.banner-full .parallax img, .banner-sarpsp.banner-full .parallax img {
  position: absolute;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.banner.banner-full .title, .banner.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner.banner-full h3,
.banner.banner-full body.institutionnelle .preview h3,
body.institutionnelle .preview .banner.banner-full h3,
.banner.banner-full body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .banner.banner-full h3,
.banner.banner-full body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .banner.banner-full h3,
.banner.banner-full body.sonata-bc .editable h3,
body.sonata-bc .editable .banner.banner-full h3,
.banner.banner-full body.sonata-bc .preview h3,
body.sonata-bc .preview .banner.banner-full h3,
.banner.banner-full body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .banner.banner-full h3,
.banner.banner-full body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .banner.banner-full h3, .banner.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner.banner-full h2,
.banner.banner-full body.institutionnelle .preview h2,
body.institutionnelle .preview .banner.banner-full h2,
.banner.banner-full body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .banner.banner-full h2,
.banner.banner-full body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .banner.banner-full h2,
.banner.banner-full body.sonata-bc .editable h2,
body.sonata-bc .editable .banner.banner-full h2,
.banner.banner-full body.sonata-bc .preview h2,
body.sonata-bc .preview .banner.banner-full h2,
.banner.banner-full body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .banner.banner-full h2,
.banner.banner-full body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .banner.banner-full h2, .banner.banner-full body.cms main h3, body.cms main .banner.banner-full h3, .banner.banner-full body.cms main h2, body.cms main .banner.banner-full h2, .banner-sarpgn.banner-full .title, .banner-sarpgn.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.institutionnelle .preview h3,
body.institutionnelle .preview .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.sonata-bc .editable h3,
body.sonata-bc .editable .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.sonata-bc .preview h3,
body.sonata-bc .preview .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.institutionnelle .preview h2,
body.institutionnelle .preview .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.sonata-bc .editable h2,
body.sonata-bc .editable .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.sonata-bc .preview h2,
body.sonata-bc .preview .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2, .banner-sarpgn.banner-full body.cms main h3, body.cms main .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.cms main h2, body.cms main .banner-sarpgn.banner-full h2, .banner-sarpsp.banner-full .title, .banner-sarpsp.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.institutionnelle .preview h3,
body.institutionnelle .preview .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.sonata-bc .editable h3,
body.sonata-bc .editable .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.sonata-bc .preview h3,
body.sonata-bc .preview .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.institutionnelle .preview h2,
body.institutionnelle .preview .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.sonata-bc .editable h2,
body.sonata-bc .editable .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.sonata-bc .preview h2,
body.sonata-bc .preview .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2, .banner-sarpsp.banner-full body.cms main h3, body.cms main .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.cms main h2, body.cms main .banner-sarpsp.banner-full h2 {
  font-size: 1.5rem;
  padding-bottom: 0;
  display: inline-block;
}
.banner.banner-full .title .first-line, .banner.banner-full body.institutionnelle .editable h3 .first-line, body.institutionnelle .editable .banner.banner-full h3 .first-line,
.banner.banner-full body.institutionnelle .preview h3 .first-line,
body.institutionnelle .preview .banner.banner-full h3 .first-line,
.banner.banner-full body.institutionnelle .medium-editor-element h3 .first-line,
body.institutionnelle .medium-editor-element .banner.banner-full h3 .first-line,
.banner.banner-full body.institutionnelle .section-wysiwyg h3 .first-line,
body.institutionnelle .section-wysiwyg .banner.banner-full h3 .first-line,
.banner.banner-full body.sonata-bc .editable h3 .first-line,
body.sonata-bc .editable .banner.banner-full h3 .first-line,
.banner.banner-full body.sonata-bc .preview h3 .first-line,
body.sonata-bc .preview .banner.banner-full h3 .first-line,
.banner.banner-full body.sonata-bc .medium-editor-element h3 .first-line,
body.sonata-bc .medium-editor-element .banner.banner-full h3 .first-line,
.banner.banner-full body.sonata-bc .section-wysiwyg h3 .first-line,
body.sonata-bc .section-wysiwyg .banner.banner-full h3 .first-line, .banner.banner-full body.institutionnelle .editable h2 .first-line, body.institutionnelle .editable .banner.banner-full h2 .first-line,
.banner.banner-full body.institutionnelle .preview h2 .first-line,
body.institutionnelle .preview .banner.banner-full h2 .first-line,
.banner.banner-full body.institutionnelle .medium-editor-element h2 .first-line,
body.institutionnelle .medium-editor-element .banner.banner-full h2 .first-line,
.banner.banner-full body.institutionnelle .section-wysiwyg h2 .first-line,
body.institutionnelle .section-wysiwyg .banner.banner-full h2 .first-line,
.banner.banner-full body.sonata-bc .editable h2 .first-line,
body.sonata-bc .editable .banner.banner-full h2 .first-line,
.banner.banner-full body.sonata-bc .preview h2 .first-line,
body.sonata-bc .preview .banner.banner-full h2 .first-line,
.banner.banner-full body.sonata-bc .medium-editor-element h2 .first-line,
body.sonata-bc .medium-editor-element .banner.banner-full h2 .first-line,
.banner.banner-full body.sonata-bc .section-wysiwyg h2 .first-line,
body.sonata-bc .section-wysiwyg .banner.banner-full h2 .first-line, .banner.banner-full body.cms main h3 .first-line, body.cms main .banner.banner-full h3 .first-line, .banner.banner-full body.cms main h2 .first-line, body.cms main .banner.banner-full h2 .first-line, .banner-sarpgn.banner-full .title .first-line, .banner-sarpgn.banner-full body.institutionnelle .editable h3 .first-line, body.institutionnelle .editable .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .preview h3 .first-line,
body.institutionnelle .preview .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3 .first-line,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3 .first-line,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .editable h3 .first-line,
body.sonata-bc .editable .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .preview h3 .first-line,
body.sonata-bc .preview .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3 .first-line,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3 .first-line,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3 .first-line, .banner-sarpgn.banner-full body.institutionnelle .editable h2 .first-line, body.institutionnelle .editable .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .preview h2 .first-line,
body.institutionnelle .preview .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2 .first-line,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2 .first-line,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .editable h2 .first-line,
body.sonata-bc .editable .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .preview h2 .first-line,
body.sonata-bc .preview .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2 .first-line,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2 .first-line,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2 .first-line,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2 .first-line, .banner-sarpgn.banner-full body.cms main h3 .first-line, body.cms main .banner-sarpgn.banner-full h3 .first-line, .banner-sarpgn.banner-full body.cms main h2 .first-line, body.cms main .banner-sarpgn.banner-full h2 .first-line, .banner-sarpsp.banner-full .title .first-line, .banner-sarpsp.banner-full body.institutionnelle .editable h3 .first-line, body.institutionnelle .editable .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .preview h3 .first-line,
body.institutionnelle .preview .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3 .first-line,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3 .first-line,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .editable h3 .first-line,
body.sonata-bc .editable .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .preview h3 .first-line,
body.sonata-bc .preview .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3 .first-line,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3 .first-line,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3 .first-line, .banner-sarpsp.banner-full body.institutionnelle .editable h2 .first-line, body.institutionnelle .editable .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .preview h2 .first-line,
body.institutionnelle .preview .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2 .first-line,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2 .first-line,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .editable h2 .first-line,
body.sonata-bc .editable .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .preview h2 .first-line,
body.sonata-bc .preview .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2 .first-line,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2 .first-line,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2 .first-line,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2 .first-line, .banner-sarpsp.banner-full body.cms main h3 .first-line, body.cms main .banner-sarpsp.banner-full h3 .first-line, .banner-sarpsp.banner-full body.cms main h2 .first-line, body.cms main .banner-sarpsp.banner-full h2 .first-line {
  margin-bottom: 10px;
  display: block;
}
.banner.banner-full .title .banner-subtitle, .banner.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.institutionnelle .preview h3 .banner-subtitle,
body.institutionnelle .preview .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.sonata-bc .editable h3 .banner-subtitle,
body.sonata-bc .editable .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.sonata-bc .preview h3 .banner-subtitle,
body.sonata-bc .preview .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner.banner-full h3 .banner-subtitle,
.banner.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.institutionnelle .preview h2 .banner-subtitle,
body.institutionnelle .preview .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.sonata-bc .editable h2 .banner-subtitle,
body.sonata-bc .editable .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.sonata-bc .preview h2 .banner-subtitle,
body.sonata-bc .preview .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner.banner-full h2 .banner-subtitle,
.banner.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner.banner-full h2 .banner-subtitle, .banner.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full .title .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .preview h3 .banner-subtitle,
body.institutionnelle .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .editable h3 .banner-subtitle,
body.sonata-bc .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .preview h3 .banner-subtitle,
body.sonata-bc .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .preview h2 .banner-subtitle,
body.institutionnelle .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .editable h2 .banner-subtitle,
body.sonata-bc .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .preview h2 .banner-subtitle,
body.sonata-bc .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
.banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full .title .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .preview h3 .banner-subtitle,
body.institutionnelle .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .editable h3 .banner-subtitle,
body.sonata-bc .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .preview h3 .banner-subtitle,
body.sonata-bc .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .preview h2 .banner-subtitle,
body.institutionnelle .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .editable h2 .banner-subtitle,
body.sonata-bc .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .preview h2 .banner-subtitle,
body.sonata-bc .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
.banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h2 .banner-subtitle {
  font-size: 1.875rem;
  position: relative;
}
@media (min-width: 568px) {
  .banner.banner-full .title, .banner.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner.banner-full h3, .banner.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner.banner-full h2, .banner.banner-full body.cms main h3, body.cms main .banner.banner-full h3, .banner.banner-full body.cms main h2, body.cms main .banner.banner-full h2, .banner-sarpgn.banner-full .title, .banner-sarpgn.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2, .banner-sarpgn.banner-full body.cms main h3, body.cms main .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.cms main h2, body.cms main .banner-sarpgn.banner-full h2, .banner-sarpsp.banner-full .title, .banner-sarpsp.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2, .banner-sarpsp.banner-full body.cms main h3, body.cms main .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.cms main h2, body.cms main .banner-sarpsp.banner-full h2 {
    padding-bottom: 40px;
    font-size: 2.1875rem;
    margin: 0 auto;
  }
  .banner.banner-full .title .banner-subtitle, .banner.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner.banner-full h2 .banner-subtitle, .banner.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full .title .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full .title .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h2 .banner-subtitle {
    font-size: 3.125rem;
  }
}
@media (min-width: 992px) {
  .banner.banner-full .title, .banner.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner.banner-full h3,
  .banner.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner.banner-full h3,
  .banner.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner.banner-full h3, .banner.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner.banner-full h2,
  .banner.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner.banner-full h2,
  .banner.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner.banner-full h2, .banner.banner-full body.cms main h3, body.cms main .banner.banner-full h3, .banner.banner-full body.cms main h2, body.cms main .banner.banner-full h2, .banner-sarpgn.banner-full .title, .banner-sarpgn.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2, .banner-sarpgn.banner-full body.cms main h3, body.cms main .banner-sarpgn.banner-full h3, .banner-sarpgn.banner-full body.cms main h2, body.cms main .banner-sarpgn.banner-full h2, .banner-sarpsp.banner-full .title, .banner-sarpsp.banner-full body.institutionnelle .editable h3, body.institutionnelle .editable .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .preview h3,
  body.institutionnelle .preview .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .editable h3,
  body.sonata-bc .editable .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .preview h3,
  body.sonata-bc .preview .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.institutionnelle .editable h2, body.institutionnelle .editable .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .preview h2,
  body.institutionnelle .preview .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .editable h2,
  body.sonata-bc .editable .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .preview h2,
  body.sonata-bc .preview .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2, .banner-sarpsp.banner-full body.cms main h3, body.cms main .banner-sarpsp.banner-full h3, .banner-sarpsp.banner-full body.cms main h2, body.cms main .banner-sarpsp.banner-full h2 {
    padding-bottom: 80px;
    font-size: 3.125rem;
    text-align: left;
  }
  .banner.banner-full .title .banner-subtitle, .banner.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner.banner-full h3 .banner-subtitle,
  .banner.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner.banner-full h2 .banner-subtitle,
  .banner.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner.banner-full h2 .banner-subtitle, .banner.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner.banner-full h3 .banner-subtitle, .banner.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full .title .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h3 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpgn.banner-full h2 .banner-subtitle,
  .banner-sarpgn.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h3 .banner-subtitle, .banner-sarpgn.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpgn.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full .title .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h3 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .preview h3 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h3 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h3 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .editable h3 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .preview h3 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h3 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h3 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h3 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.institutionnelle .editable h2 .banner-subtitle, body.institutionnelle .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .preview h2 .banner-subtitle,
  body.institutionnelle .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .medium-editor-element h2 .banner-subtitle,
  body.institutionnelle .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.institutionnelle .section-wysiwyg h2 .banner-subtitle,
  body.institutionnelle .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .editable h2 .banner-subtitle,
  body.sonata-bc .editable .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .preview h2 .banner-subtitle,
  body.sonata-bc .preview .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .medium-editor-element h2 .banner-subtitle,
  body.sonata-bc .medium-editor-element .banner-sarpsp.banner-full h2 .banner-subtitle,
  .banner-sarpsp.banner-full body.sonata-bc .section-wysiwyg h2 .banner-subtitle,
  body.sonata-bc .section-wysiwyg .banner-sarpsp.banner-full h2 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h3 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h3 .banner-subtitle, .banner-sarpsp.banner-full body.cms main h2 .banner-subtitle, body.cms main .banner-sarpsp.banner-full h2 .banner-subtitle {
    font-size: 4.5rem;
  }
}
.banner.banner-full .banner-inner, .banner-sarpgn.banner-full .banner-inner, .banner-sarpsp.banner-full .banner-inner {
  position: absolute;
  z-index: 3;
  width: 90%;
  bottom: 30px;
  left: 5%;
}

.banner-full .parallax {
  overflow: hidden;
  height: 100vh;
  position: relative;
}
.banner-full .parallax img {
  position: absolute;
  height: 120vh;
  min-width: 100%;
}

.banner-sass .parallax {
  overflow: hidden;
  height: 400px;
  position: relative;
}
.banner-sass .parallax img {
  position: absolute;
  height: 500px;
  min-width: 100%;
}

blockquote {
  display: block;
  margin: 10px auto;
  font-style: italic;
  font-weight: 600;
  text-align: center;
  color: #424242;
  font-size: 1.5rem;
  line-height: 1.3;
  letter-spacing: 0.01875rem;
}
blockquote::before {
  content: "« ";
}
blockquote::after {
  content: " »";
}

.breadcrumb:not(.breadcrumb-sonata) ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.breadcrumb:not(.breadcrumb-sonata) li {
  display: inline-block;
  vertical-align: top;
  padding-right: 3px;
  font-weight: normal;
  color: #757575;
  font-size: 0.625rem;
}
.breadcrumb:not(.breadcrumb-sonata) li:not(:last-child)::after {
  content: ">";
  padding-left: 3px;
  font-weight: bold;
}
.breadcrumb:not(.breadcrumb-sonata) a {
  font-weight: bold;
  color: #757575;
  text-decoration: none;
}
.breadcrumb:not(.breadcrumb-sonata) a:hover, .breadcrumb:not(.breadcrumb-sonata) a:focus {
  text-decoration: underline;
}

a {
  color: #0374bc;
}
a:hover, a:focus {
  text-decoration: none;
}

.site-global .btn {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  letter-spacing: normal;
  padding: 6px 15px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border: 2px solid #0374bc;
  background-color: #0374bc;
  font-size: 1rem;
  transition: all 0.2s;
}
.site-global .btn:focus, .site-global .btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.btn .cta-icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn .cta-icon_container .cta-icon-image {
  width: 25px;
  height: auto;
}
.btn .cta-icon_container .cta-icon-image.icon-position-right {
  margin-left: 15px;
}
.btn .cta-icon_container .cta-icon-image.icon-position-left {
  margin-right: 15px;
}
.btn.btn-full-width {
  width: 100%;
}
.btn.btn-link {
  color: #0374bc;
  border: 0;
  background-color: transparent;
  position: relative;
  text-transform: inherit;
  padding: 10px 30px 10px 10px;
}
.btn.btn-link .link-big {
  font-size: 1.5em;
  margin-right: 10px;
}
.btn.btn-link .iicon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate3d(0, -50%, 0) rotate(90deg);
  transition: all 0.2s ease-out;
  stroke-width: 10%;
  width: 1.625rem;
}
.btn.btn-link:focus, .btn.btn-link:hover {
  opacity: 1;
}
.btn.btn-link:focus .iicon, .btn.btn-link:hover .iicon {
  stroke-width: 15%;
  right: 0;
}
.btn.btn-green {
  color: #fff;
  border-color: #6bc024;
  background-color: #6bc024;
}
.btn.btn-green.btn-link {
  color: #6bc024;
  border-color: transparent;
  background-color: transparent;
}
.btn.btn-orange {
  color: #fff;
  border-color: #f0941d;
  background-color: #f0941d;
}
.btn.btn-orange.btn-link {
  color: #f0941d;
  border-color: transparent;
  background-color: transparent;
}
.btn.btn-error {
  color: #fff;
  border-color: #f0941d;
  background-color: #f0941d;
}
.btn.btn-error.btn-link {
  color: #f0941d;
  border-color: transparent;
  background-color: transparent;
}
.btn.btn-error-inverse {
  color: #f0941d;
  border-color: #f0941d;
  background-color: #fff;
}
.btn.btn-error-inverse:focus, .btn.btn-error-inverse:hover {
  opacity: 1;
  color: #fff;
  border-color: #f0941d;
  background-color: #f0941d;
}
.btn.btn-blue-inverse {
  color: #0374bc;
  border-color: #0374bc;
  background-color: #fff;
}
.btn.btn-blue-inverse:focus, .btn.btn-blue-inverse:hover {
  opacity: 1;
  color: #fff;
  border-color: #0374bc;
  background-color: #0374bc;
}
.btn.btn-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}
.btn.btn-white:focus, .btn.btn-white:hover {
  opacity: 1;
  color: #0374bc;
  border-color: #fff;
  background-color: #fff;
}
.btn.btn-icon-right {
  position: relative;
  padding-right: 45px;
}
.btn.btn-icon-right .iicon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  transition: all 0.2s;
}
.btn.btn-icon-left {
  position: relative;
  padding-left: 45px;
}
.btn.btn-icon-left .iicon {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -8px;
  transition: all 0.2s;
}
@media (min-width: 768px) {
  .btn.btn-big {
    text-transform: uppercase;
    padding: 15px 30px;
    font-size: 1.25rem;
  }
  .btn.btn-big.btn-icon-right {
    padding-right: 50px;
  }
  .btn.btn-big.btn-icon-right .iicon {
    right: 20px;
  }
  .btn.btn-big.btn-icon-left {
    padding-left: 50px;
  }
  .btn.btn-big.btn-icon-left .iicon {
    left: 20px;
  }
}
.btn.btn-full {
  width: 100%;
}

.btn-back {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  line-height: 38px;
  width: 38px;
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 50px;
  padding: 0;
  transition: background-color 0.5s;
  text-align: center;
}
.btn-back svg {
  color: #fff;
  width: 25px;
  height: 20px;
  padding-top: 2px;
  transition: color 0.5s;
}
.btn-back:hover {
  background-color: #fff;
}
.btn-back:hover svg {
  color: #0374bc;
}

a.dual-cta {
  margin: 5px;
}

.citation-mobilite .citation-block {
  border-radius: 15px 0 15px 0;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0705882353);
}
.citation-mobilite .citation-block .citation-banner {
  height: 120px;
}
.citation-mobilite .citation-block .citation-bottom {
  display: flex;
  justify-content: center;
}
.citation-mobilite .citation-block .citation-bottom .citation-block-text {
  position: relative;
  max-width: 400px;
  margin: 70px 50px;
}
.citation-mobilite .citation-block .citation-bottom .citation-block-text .citation-text {
  text-align: justify;
  font-size: 1.2em;
  font-weight: 600;
  color: #757575;
}
.citation-mobilite .citation-block .citation-bottom .citation-block-text .citation-quotation-mark {
  width: 30px;
  height: auto;
}
.citation-mobilite .citation-block .citation-bottom .citation-block-text .citation-quotation-mark.citation-quotation-mark-left {
  position: absolute;
  top: -20px;
  left: -40px;
}
.citation-mobilite .citation-block .citation-bottom .citation-block-text .citation-quotation-mark.citation-quotation-mark-right {
  position: absolute;
  bottom: -20px;
  right: -40px;
}

.bloc-focus {
  position: relative;
  margin: 10px auto;
  padding: 10px;
  max-width: 100%;
}
.bloc-focus::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 10px;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  display: block;
  width: 148px;
  height: 170px;
  background: url(../../../public/images/mascottes/mascotte.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
@media (min-width: 568px) {
  .bloc-focus {
    width: 900px;
    padding-left: 200px;
  }
  .bloc-focus::after {
    left: 20px;
    transform: translate3d(0, 0, 0);
  }
}

.bloc-focus-inner:not(.focus-mobilite) {
  border: 0.125rem solid #6bc024;
  padding: 20px 30px 200px 20px;
  border-radius: 10px 0;
  background-color: #fff;
}
@media (min-width: 568px) {
  .bloc-focus-inner:not(.focus-mobilite) {
    padding-bottom: 30px;
  }
}

.bloc-focus-inner.focus-mobilite {
  border-top: 0.5rem solid #6bc024;
  padding: 20px 30px 200px 20px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0705882353);
  border-radius: 13px 0;
}
@media (min-width: 568px) {
  .bloc-focus-inner.focus-mobilite {
    padding-bottom: 30px;
  }
}

.bloc-focus-title {
  display: block;
  text-align: center;
  font-weight: bold;
  margin: 10px auto 20px auto;
  color: #6bc024;
  font-size: 1.125rem;
}

.bloc-focus-content {
  text-align: left;
  font-size: 0.875rem;
}

.grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .body-browser-ie .grid-2-cols > div {
    width: 400px;
    margin: 10px;
  }
  .body-browser-ie .grid-3-cols > div {
    width: 270px;
    margin: 10px;
  }
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-template-rows: auto;
    align-items: stretch;
    justify-items: stretch;
    grid-template-columns: 1fr;
    grid-gap: 1.4rem;
  }
  @media (min-width: 768px) {
    .grid-2-cols {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 768px) {
    .grid-3-cols {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
div.section-image-mobilite div.section-inner {
  padding: 0;
}
div.section-image-mobilite .bloc-image-mobilite {
  position: relative;
  display: block;
  height: 200px;
  overflow: hidden;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  cursor: default;
}
div.section-image-mobilite .bloc-image-mobilite a {
  cursor: pointer;
}
div.section-image-mobilite .bloc-image-mobilite.bloc-image-mobilite-full-width {
  width: 100%;
}
div.section-image-mobilite .bloc-image-mobilite .image-mobilite-picture {
  filter: brightness(80%);
  background-size: cover;
  background-position: center;
  display: block;
  height: 100%;
  transform: scale(1, 1);
  transition: transform 0.6s ease;
}
div.section-image-mobilite .bloc-image-mobilite .image-mobilite-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  color: #fff;
  font-size: 1.9em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.1;
  word-break: break-word;
}
div.section-image-mobilite .bloc-image-mobilite:hover .image-mobilite-picture {
  transform: scale(1.2, 1.2);
  transition: transform 5s ease;
}

@media only screen and (max-width: 768px) {
  div.section-image-mobilite .bloc-image-mobilite .image-mobilite-text-overlay {
    font-size: 1.7em;
  }
}
div.section-dual-image-mobilite div.section-inner {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
div.section-dual-image-mobilite .bloc-image-mobilite {
  position: relative;
  display: block;
  height: 200px;
  overflow: hidden;
  width: 50%;
  max-width: 100%;
  margin: 0;
  cursor: default;
}
div.section-dual-image-mobilite .bloc-image-mobilite a {
  cursor: pointer;
}
div.section-dual-image-mobilite .bloc-image-mobilite .image-mobilite-picture {
  filter: brightness(80%);
  background-size: cover;
  background-position: center;
  display: block;
  height: 100%;
  transform: scale(1, 1);
  transition: transform 0.6s ease;
}
div.section-dual-image-mobilite .bloc-image-mobilite .image-mobilite-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  color: #fff;
  font-size: 1.9em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.1;
  word-break: break-word;
}
div.section-dual-image-mobilite .bloc-image-mobilite:hover .image-mobilite-picture {
  transform: scale(1.2, 1.2);
  transition: transform 5s ease;
}

@media only screen and (max-width: 768px) {
  div.section-dual-image-mobilite .bloc-image-mobilite {
    width: 100%;
  }
  div.section-dual-image-mobilite .bloc-image-mobilite .image-mobilite-text-overlay {
    font-size: 1.7em;
  }
}
ul {
  margin-left: 1.6em;
  margin-right: 1.6em;
}
ul.list-offres {
  list-style: none;
  padding: 0;
}
ul.list-offres hr {
  border: none;
  border-bottom: 1px solid #6bc024;
}
ul.list-offres p {
  margin: 15px 0;
}
ul.list-offres .btn.btn-link {
  position: absolute;
  right: 0;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
}

.main {
  display: block;
  z-index: 3;
  min-height: 150px;
  width: 100%;
  transition: all 0.2s;
}
@media (min-width: 768px) {
  .main {
    min-height: 400px;
  }
}
.main > .section:last-child:not(.section-push-bas-de-page),
.main .article > .section:last-child:not(.section-push-bas-de-page) {
  padding-bottom: 5rem;
}

.push-inner {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px 0 10px 0;
  padding: 20px;
  height: 100%;
  box-shadow: 0 8px 14px 0 rgba(216, 221, 230, 0.5);
  z-index: 2;
}
.push-inner .title, .push-inner body.institutionnelle .editable h3, body.institutionnelle .editable .push-inner h3,
.push-inner body.institutionnelle .preview h3,
body.institutionnelle .preview .push-inner h3,
.push-inner body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-inner h3,
.push-inner body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-inner h3,
.push-inner body.sonata-bc .editable h3,
body.sonata-bc .editable .push-inner h3,
.push-inner body.sonata-bc .preview h3,
body.sonata-bc .preview .push-inner h3,
.push-inner body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-inner h3,
.push-inner body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-inner h3, .push-inner body.institutionnelle .editable h2, body.institutionnelle .editable .push-inner h2,
.push-inner body.institutionnelle .preview h2,
body.institutionnelle .preview .push-inner h2,
.push-inner body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-inner h2,
.push-inner body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-inner h2,
.push-inner body.sonata-bc .editable h2,
body.sonata-bc .editable .push-inner h2,
.push-inner body.sonata-bc .preview h2,
body.sonata-bc .preview .push-inner h2,
.push-inner body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-inner h2,
.push-inner body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-inner h2, .push-inner body.cms main h3, body.cms main .push-inner h3, .push-inner body.cms main h2, body.cms main .push-inner h2 {
  margin-bottom: 10px;
}
.push-inner p {
  margin-top: 0;
  line-height: 1.3;
}
.push-inner .push-btn {
  margin-top: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.push-inner .push-btn .btn {
  align-self: center;
}

.push-star {
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.push-star > img {
  position: relative;
  z-index: 1;
  max-width: 100%;
}
.push-star .push-inner {
  margin: -20px 10px 40px;
}
@media (min-width: 768px) {
  .push-star .push-inner {
    margin: -50px 40px 40px;
  }
}
.push-star .push-inner.push-without-image {
  margin: 40px 10px;
}
.push-star .btn {
  margin-bottom: 20px;
}

.push-simple {
  list-style: none;
}
.push-simple .title, .push-simple body.institutionnelle .editable h3, body.institutionnelle .editable .push-simple h3,
.push-simple body.institutionnelle .preview h3,
body.institutionnelle .preview .push-simple h3,
.push-simple body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-simple h3,
.push-simple body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-simple h3,
.push-simple body.sonata-bc .editable h3,
body.sonata-bc .editable .push-simple h3,
.push-simple body.sonata-bc .preview h3,
body.sonata-bc .preview .push-simple h3,
.push-simple body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-simple h3,
.push-simple body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-simple h3, .push-simple body.institutionnelle .editable h2, body.institutionnelle .editable .push-simple h2,
.push-simple body.institutionnelle .preview h2,
body.institutionnelle .preview .push-simple h2,
.push-simple body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-simple h2,
.push-simple body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-simple h2,
.push-simple body.sonata-bc .editable h2,
body.sonata-bc .editable .push-simple h2,
.push-simple body.sonata-bc .preview h2,
body.sonata-bc .preview .push-simple h2,
.push-simple body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-simple h2,
.push-simple body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-simple h2, .push-simple body.cms main h3, body.cms main .push-simple h3, .push-simple body.cms main h2, body.cms main .push-simple h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0374bc;
}
.push-simple .title .iicon, .push-simple body.institutionnelle .editable h3 .iicon, body.institutionnelle .editable .push-simple h3 .iicon,
.push-simple body.institutionnelle .preview h3 .iicon,
body.institutionnelle .preview .push-simple h3 .iicon,
.push-simple body.institutionnelle .medium-editor-element h3 .iicon,
body.institutionnelle .medium-editor-element .push-simple h3 .iicon,
.push-simple body.institutionnelle .section-wysiwyg h3 .iicon,
body.institutionnelle .section-wysiwyg .push-simple h3 .iicon,
.push-simple body.sonata-bc .editable h3 .iicon,
body.sonata-bc .editable .push-simple h3 .iicon,
.push-simple body.sonata-bc .preview h3 .iicon,
body.sonata-bc .preview .push-simple h3 .iicon,
.push-simple body.sonata-bc .medium-editor-element h3 .iicon,
body.sonata-bc .medium-editor-element .push-simple h3 .iicon,
.push-simple body.sonata-bc .section-wysiwyg h3 .iicon,
body.sonata-bc .section-wysiwyg .push-simple h3 .iicon, .push-simple body.institutionnelle .editable h2 .iicon, body.institutionnelle .editable .push-simple h2 .iicon,
.push-simple body.institutionnelle .preview h2 .iicon,
body.institutionnelle .preview .push-simple h2 .iicon,
.push-simple body.institutionnelle .medium-editor-element h2 .iicon,
body.institutionnelle .medium-editor-element .push-simple h2 .iicon,
.push-simple body.institutionnelle .section-wysiwyg h2 .iicon,
body.institutionnelle .section-wysiwyg .push-simple h2 .iicon,
.push-simple body.sonata-bc .editable h2 .iicon,
body.sonata-bc .editable .push-simple h2 .iicon,
.push-simple body.sonata-bc .preview h2 .iicon,
body.sonata-bc .preview .push-simple h2 .iicon,
.push-simple body.sonata-bc .medium-editor-element h2 .iicon,
body.sonata-bc .medium-editor-element .push-simple h2 .iicon,
.push-simple body.sonata-bc .section-wysiwyg h2 .iicon,
body.sonata-bc .section-wysiwyg .push-simple h2 .iicon, .push-simple body.cms main h3 .iicon, body.cms main .push-simple h3 .iicon, .push-simple body.cms main h2 .iicon, body.cms main .push-simple h2 .iicon {
  display: block;
  margin: 10px auto;
  width: 1.875rem;
  height: 1.875rem;
}
.push-simple .push-inner .img-wrapper img.img-presentation {
  width: 40px;
}

.push-simple-green .title, .push-simple-green body.institutionnelle .editable h3, body.institutionnelle .editable .push-simple-green h3,
.push-simple-green body.institutionnelle .preview h3,
body.institutionnelle .preview .push-simple-green h3,
.push-simple-green body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-simple-green h3,
.push-simple-green body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-simple-green h3,
.push-simple-green body.sonata-bc .editable h3,
body.sonata-bc .editable .push-simple-green h3,
.push-simple-green body.sonata-bc .preview h3,
body.sonata-bc .preview .push-simple-green h3,
.push-simple-green body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-simple-green h3,
.push-simple-green body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-simple-green h3, .push-simple-green body.institutionnelle .editable h2, body.institutionnelle .editable .push-simple-green h2,
.push-simple-green body.institutionnelle .preview h2,
body.institutionnelle .preview .push-simple-green h2,
.push-simple-green body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-simple-green h2,
.push-simple-green body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-simple-green h2,
.push-simple-green body.sonata-bc .editable h2,
body.sonata-bc .editable .push-simple-green h2,
.push-simple-green body.sonata-bc .preview h2,
body.sonata-bc .preview .push-simple-green h2,
.push-simple-green body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-simple-green h2,
.push-simple-green body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-simple-green h2, .push-simple-green body.cms main h3, body.cms main .push-simple-green h3, .push-simple-green body.cms main h2, body.cms main .push-simple-green h2 {
  color: #6bc024;
}

.push-simple-img .img-wrapper {
  height: 140px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 10px 0 0 0;
}
.push-simple-img .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.push-simple-img .push-inner {
  padding: 0;
}
.push-simple-img .push-inner .title, .push-simple-img .push-inner body.institutionnelle .editable h3, body.institutionnelle .editable .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.institutionnelle .preview h3,
body.institutionnelle .preview .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.sonata-bc .editable h3,
body.sonata-bc .editable .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.sonata-bc .preview h3,
body.sonata-bc .preview .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-simple-img .push-inner h3,
.push-simple-img .push-inner body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-simple-img .push-inner h3, .push-simple-img .push-inner body.institutionnelle .editable h2, body.institutionnelle .editable .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.institutionnelle .preview h2,
body.institutionnelle .preview .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.sonata-bc .editable h2,
body.sonata-bc .editable .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.sonata-bc .preview h2,
body.sonata-bc .preview .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-simple-img .push-inner h2,
.push-simple-img .push-inner body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-simple-img .push-inner h2, .push-simple-img .push-inner body.cms main h3, body.cms main .push-simple-img .push-inner h3, .push-simple-img .push-inner body.cms main h2, body.cms main .push-simple-img .push-inner h2,
.push-simple-img .push-inner p {
  padding: 0 20px;
}

.push-number {
  color: #0374bc;
  text-align: center;
}
.push-number .title, .push-number body.institutionnelle .editable h3, body.institutionnelle .editable .push-number h3,
.push-number body.institutionnelle .preview h3,
body.institutionnelle .preview .push-number h3,
.push-number body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-number h3,
.push-number body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-number h3,
.push-number body.sonata-bc .editable h3,
body.sonata-bc .editable .push-number h3,
.push-number body.sonata-bc .preview h3,
body.sonata-bc .preview .push-number h3,
.push-number body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-number h3,
.push-number body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-number h3, .push-number body.institutionnelle .editable h2, body.institutionnelle .editable .push-number h2,
.push-number body.institutionnelle .preview h2,
body.institutionnelle .preview .push-number h2,
.push-number body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-number h2,
.push-number body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-number h2,
.push-number body.sonata-bc .editable h2,
body.sonata-bc .editable .push-number h2,
.push-number body.sonata-bc .preview h2,
body.sonata-bc .preview .push-number h2,
.push-number body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-number h2,
.push-number body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-number h2, .push-number body.cms main h3, body.cms main .push-number h3, .push-number body.cms main h2, body.cms main .push-number h2 {
  font-size: 2.3rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 10px;
}
.push-number .title .number, .push-number body.institutionnelle .editable h3 .number, body.institutionnelle .editable .push-number h3 .number,
.push-number body.institutionnelle .preview h3 .number,
body.institutionnelle .preview .push-number h3 .number,
.push-number body.institutionnelle .medium-editor-element h3 .number,
body.institutionnelle .medium-editor-element .push-number h3 .number,
.push-number body.institutionnelle .section-wysiwyg h3 .number,
body.institutionnelle .section-wysiwyg .push-number h3 .number,
.push-number body.sonata-bc .editable h3 .number,
body.sonata-bc .editable .push-number h3 .number,
.push-number body.sonata-bc .preview h3 .number,
body.sonata-bc .preview .push-number h3 .number,
.push-number body.sonata-bc .medium-editor-element h3 .number,
body.sonata-bc .medium-editor-element .push-number h3 .number,
.push-number body.sonata-bc .section-wysiwyg h3 .number,
body.sonata-bc .section-wysiwyg .push-number h3 .number, .push-number body.institutionnelle .editable h2 .number, body.institutionnelle .editable .push-number h2 .number,
.push-number body.institutionnelle .preview h2 .number,
body.institutionnelle .preview .push-number h2 .number,
.push-number body.institutionnelle .medium-editor-element h2 .number,
body.institutionnelle .medium-editor-element .push-number h2 .number,
.push-number body.institutionnelle .section-wysiwyg h2 .number,
body.institutionnelle .section-wysiwyg .push-number h2 .number,
.push-number body.sonata-bc .editable h2 .number,
body.sonata-bc .editable .push-number h2 .number,
.push-number body.sonata-bc .preview h2 .number,
body.sonata-bc .preview .push-number h2 .number,
.push-number body.sonata-bc .medium-editor-element h2 .number,
body.sonata-bc .medium-editor-element .push-number h2 .number,
.push-number body.sonata-bc .section-wysiwyg h2 .number,
body.sonata-bc .section-wysiwyg .push-number h2 .number, .push-number body.cms main h3 .number, body.cms main .push-number h3 .number, .push-number body.cms main h2 .number, body.cms main .push-number h2 .number {
  font-size: 3rem;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
}
.push-number p {
  margin-top: 0;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .push-number .title .number, .push-number body.institutionnelle .editable h3 .number, body.institutionnelle .editable .push-number h3 .number,
  .push-number body.institutionnelle .preview h3 .number,
  body.institutionnelle .preview .push-number h3 .number,
  .push-number body.institutionnelle .medium-editor-element h3 .number,
  body.institutionnelle .medium-editor-element .push-number h3 .number,
  .push-number body.institutionnelle .section-wysiwyg h3 .number,
  body.institutionnelle .section-wysiwyg .push-number h3 .number,
  .push-number body.sonata-bc .editable h3 .number,
  body.sonata-bc .editable .push-number h3 .number,
  .push-number body.sonata-bc .preview h3 .number,
  body.sonata-bc .preview .push-number h3 .number,
  .push-number body.sonata-bc .medium-editor-element h3 .number,
  body.sonata-bc .medium-editor-element .push-number h3 .number,
  .push-number body.sonata-bc .section-wysiwyg h3 .number,
  body.sonata-bc .section-wysiwyg .push-number h3 .number, .push-number body.institutionnelle .editable h2 .number, body.institutionnelle .editable .push-number h2 .number,
  .push-number body.institutionnelle .preview h2 .number,
  body.institutionnelle .preview .push-number h2 .number,
  .push-number body.institutionnelle .medium-editor-element h2 .number,
  body.institutionnelle .medium-editor-element .push-number h2 .number,
  .push-number body.institutionnelle .section-wysiwyg h2 .number,
  body.institutionnelle .section-wysiwyg .push-number h2 .number,
  .push-number body.sonata-bc .editable h2 .number,
  body.sonata-bc .editable .push-number h2 .number,
  .push-number body.sonata-bc .preview h2 .number,
  body.sonata-bc .preview .push-number h2 .number,
  .push-number body.sonata-bc .medium-editor-element h2 .number,
  body.sonata-bc .medium-editor-element .push-number h2 .number,
  .push-number body.sonata-bc .section-wysiwyg h2 .number,
  body.sonata-bc .section-wysiwyg .push-number h2 .number, .push-number body.cms main h3 .number, body.cms main .push-number h3 .number, .push-number body.cms main h2 .number, body.cms main .push-number h2 .number {
    font-size: 3.6rem;
  }
}
@media (min-width: 992px) {
  .push-number .title .number, .push-number body.institutionnelle .editable h3 .number, body.institutionnelle .editable .push-number h3 .number,
  .push-number body.institutionnelle .preview h3 .number,
  body.institutionnelle .preview .push-number h3 .number,
  .push-number body.institutionnelle .medium-editor-element h3 .number,
  body.institutionnelle .medium-editor-element .push-number h3 .number,
  .push-number body.institutionnelle .section-wysiwyg h3 .number,
  body.institutionnelle .section-wysiwyg .push-number h3 .number,
  .push-number body.sonata-bc .editable h3 .number,
  body.sonata-bc .editable .push-number h3 .number,
  .push-number body.sonata-bc .preview h3 .number,
  body.sonata-bc .preview .push-number h3 .number,
  .push-number body.sonata-bc .medium-editor-element h3 .number,
  body.sonata-bc .medium-editor-element .push-number h3 .number,
  .push-number body.sonata-bc .section-wysiwyg h3 .number,
  body.sonata-bc .section-wysiwyg .push-number h3 .number, .push-number body.institutionnelle .editable h2 .number, body.institutionnelle .editable .push-number h2 .number,
  .push-number body.institutionnelle .preview h2 .number,
  body.institutionnelle .preview .push-number h2 .number,
  .push-number body.institutionnelle .medium-editor-element h2 .number,
  body.institutionnelle .medium-editor-element .push-number h2 .number,
  .push-number body.institutionnelle .section-wysiwyg h2 .number,
  body.institutionnelle .section-wysiwyg .push-number h2 .number,
  .push-number body.sonata-bc .editable h2 .number,
  body.sonata-bc .editable .push-number h2 .number,
  .push-number body.sonata-bc .preview h2 .number,
  body.sonata-bc .preview .push-number h2 .number,
  .push-number body.sonata-bc .medium-editor-element h2 .number,
  body.sonata-bc .medium-editor-element .push-number h2 .number,
  .push-number body.sonata-bc .section-wysiwyg h2 .number,
  body.sonata-bc .section-wysiwyg .push-number h2 .number, .push-number body.cms main h3 .number, body.cms main .push-number h3 .number, .push-number body.cms main h2 .number, body.cms main .push-number h2 .number {
    font-size: 4.4rem;
  }
}

.push-internal {
  width: 100%;
}
.push-internal .push-inner {
  border-radius: 0;
  box-shadow: none;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
  background: linear-gradient(to right, #0788da 0%, #0374bc 100%);
}
.push-internal .title, .push-internal body.institutionnelle .editable h3, body.institutionnelle .editable .push-internal h3,
.push-internal body.institutionnelle .preview h3,
body.institutionnelle .preview .push-internal h3,
.push-internal body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-internal h3,
.push-internal body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-internal h3,
.push-internal body.sonata-bc .editable h3,
body.sonata-bc .editable .push-internal h3,
.push-internal body.sonata-bc .preview h3,
body.sonata-bc .preview .push-internal h3,
.push-internal body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-internal h3,
.push-internal body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-internal h3, .push-internal body.institutionnelle .editable h2, body.institutionnelle .editable .push-internal h2,
.push-internal body.institutionnelle .preview h2,
body.institutionnelle .preview .push-internal h2,
.push-internal body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-internal h2,
.push-internal body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-internal h2,
.push-internal body.sonata-bc .editable h2,
body.sonata-bc .editable .push-internal h2,
.push-internal body.sonata-bc .preview h2,
body.sonata-bc .preview .push-internal h2,
.push-internal body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-internal h2,
.push-internal body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-internal h2, .push-internal body.cms main h3, body.cms main .push-internal h3, .push-internal body.cms main h2, body.cms main .push-internal h2 {
  color: #fff;
}
.push-internal .push-btn {
  margin-top: 30px;
}
.push-internal .push-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.push-internal .push-image {
  display: none;
}
@media (min-width: 568px) {
  .push-internal .push-content {
    width: 70%;
    max-width: 800px;
  }
  .push-internal .push-image {
    width: 22%;
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 768px) {
  .push-internal .push-image {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 10%;
    width: 96px;
    transform: translate3d(-50%, 0, 0);
  }
  .push-internal .push-image img {
    width: 100%;
    max-width: 196px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.push-advice {
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.push-advice .push-inner {
  overflow: hidden;
  box-shadow: none;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
  background: radial-gradient(circle at bottom left, #0788da 10%, #0374bc 40%);
}
.push-advice .title, .push-advice body.institutionnelle .editable h3, body.institutionnelle .editable .push-advice h3,
.push-advice body.institutionnelle .preview h3,
body.institutionnelle .preview .push-advice h3,
.push-advice body.institutionnelle .medium-editor-element h3,
body.institutionnelle .medium-editor-element .push-advice h3,
.push-advice body.institutionnelle .section-wysiwyg h3,
body.institutionnelle .section-wysiwyg .push-advice h3,
.push-advice body.sonata-bc .editable h3,
body.sonata-bc .editable .push-advice h3,
.push-advice body.sonata-bc .preview h3,
body.sonata-bc .preview .push-advice h3,
.push-advice body.sonata-bc .medium-editor-element h3,
body.sonata-bc .medium-editor-element .push-advice h3,
.push-advice body.sonata-bc .section-wysiwyg h3,
body.sonata-bc .section-wysiwyg .push-advice h3, .push-advice body.institutionnelle .editable h2, body.institutionnelle .editable .push-advice h2,
.push-advice body.institutionnelle .preview h2,
body.institutionnelle .preview .push-advice h2,
.push-advice body.institutionnelle .medium-editor-element h2,
body.institutionnelle .medium-editor-element .push-advice h2,
.push-advice body.institutionnelle .section-wysiwyg h2,
body.institutionnelle .section-wysiwyg .push-advice h2,
.push-advice body.sonata-bc .editable h2,
body.sonata-bc .editable .push-advice h2,
.push-advice body.sonata-bc .preview h2,
body.sonata-bc .preview .push-advice h2,
.push-advice body.sonata-bc .medium-editor-element h2,
body.sonata-bc .medium-editor-element .push-advice h2,
.push-advice body.sonata-bc .section-wysiwyg h2,
body.sonata-bc .section-wysiwyg .push-advice h2, .push-advice body.cms main h3, body.cms main .push-advice h3, .push-advice body.cms main h2, body.cms main .push-advice h2 {
  color: #fff;
}
.push-advice .push-btn {
  margin-top: 30px;
}
.push-advice .push-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.push-advice .push-image {
  position: absolute;
  bottom: -15px;
  left: -20px;
  width: 96px;
}
.push-advice .push-image img {
  width: 100%;
  max-width: 196px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(15deg);
}
@media (min-width: 568px) {
  .push-advice .push-content {
    width: 70%;
    max-width: 800px;
  }
  .push-advice .push-image {
    width: 22%;
    bottom: -30px;
    left: -40px;
  }
}

.push-infos {
  text-align: left !important;
}
.push-infos .push-infos__summary {
  border-bottom: 1px solid #6bc024;
  padding-bottom: 20px;
}
.push-infos .btn {
  float: right;
}

.section a.btn {
  max-width: 90%;
  white-space: normal;
}

.section {
  text-align: left;
}
.section img {
  max-width: 100%;
  height: auto;
}
.section iframe {
  border: 0;
  max-width: 100%;
}
.homepage .section {
  margin-top: 3rem;
}

.section-inner {
  position: relative;
  width: 910px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.section-intro {
  margin: -30px auto 0 auto;
}
@media (min-width: 992px) {
  .section-intro {
    margin-top: -60px;
  }
}
.section-intro .section-inner {
  background-color: #fff;
  border-top-left-radius: 10px;
  padding: 17px 27px;
  max-width: calc(100% - 34px);
  text-align: center;
}
.section-intro .text-intro {
  font-size: 1.125rem;
}
.section-intro .text-intro p {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .section-intro .text-intro {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.section-grey {
  background-color: #f5f6f7;
}
.section-grey .title.title-2-custom span, .section-grey body.institutionnelle .editable h3.title-2-custom span, body.institutionnelle .editable .section-grey h3.title-2-custom span,
.section-grey body.institutionnelle .preview h3.title-2-custom span,
body.institutionnelle .preview .section-grey h3.title-2-custom span,
.section-grey body.institutionnelle .medium-editor-element h3.title-2-custom span,
body.institutionnelle .medium-editor-element .section-grey h3.title-2-custom span,
.section-grey body.institutionnelle .section-wysiwyg h3.title-2-custom span,
body.institutionnelle .section-wysiwyg .section-grey h3.title-2-custom span,
.section-grey body.sonata-bc .editable h3.title-2-custom span,
body.sonata-bc .editable .section-grey h3.title-2-custom span,
.section-grey body.sonata-bc .preview h3.title-2-custom span,
body.sonata-bc .preview .section-grey h3.title-2-custom span,
.section-grey body.sonata-bc .medium-editor-element h3.title-2-custom span,
body.sonata-bc .medium-editor-element .section-grey h3.title-2-custom span,
.section-grey body.sonata-bc .section-wysiwyg h3.title-2-custom span,
body.sonata-bc .section-wysiwyg .section-grey h3.title-2-custom span, .section-grey body.institutionnelle .editable h2.title-2-custom span, body.institutionnelle .editable .section-grey h2.title-2-custom span,
.section-grey body.institutionnelle .preview h2.title-2-custom span,
body.institutionnelle .preview .section-grey h2.title-2-custom span,
.section-grey body.institutionnelle .medium-editor-element h2.title-2-custom span,
body.institutionnelle .medium-editor-element .section-grey h2.title-2-custom span,
.section-grey body.institutionnelle .section-wysiwyg h2.title-2-custom span,
body.institutionnelle .section-wysiwyg .section-grey h2.title-2-custom span,
.section-grey body.sonata-bc .editable h2.title-2-custom span,
body.sonata-bc .editable .section-grey h2.title-2-custom span,
.section-grey body.sonata-bc .preview h2.title-2-custom span,
body.sonata-bc .preview .section-grey h2.title-2-custom span,
.section-grey body.sonata-bc .medium-editor-element h2.title-2-custom span,
body.sonata-bc .medium-editor-element .section-grey h2.title-2-custom span,
.section-grey body.sonata-bc .section-wysiwyg h2.title-2-custom span,
body.sonata-bc .section-wysiwyg .section-grey h2.title-2-custom span, .section-grey body.cms main h3.title-2-custom span, body.cms main .section-grey h3.title-2-custom span, .section-grey body.cms main h2.title-2-custom span, body.cms main .section-grey h2.title-2-custom span {
  background-color: #f5f6f7;
}

.section-accordion .section-inner {
  padding-bottom: 0;
}
.section-accordion + .section-accordion .section-inner {
  padding-top: 0;
}
.section-accordion + .section:not(.section-accordion) {
  margin-top: 1rem;
}

#etudiants {
  margin-top: 10rem;
}
#etudiants .push-star {
  margin-top: -5rem;
}

.section.section-cta {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
}

.title, body.institutionnelle .editable h3,
body.institutionnelle .preview h3,
body.institutionnelle .medium-editor-element h3,
body.institutionnelle .section-wysiwyg h3,
body.sonata-bc .editable h3,
body.sonata-bc .preview h3,
body.sonata-bc .medium-editor-element h3,
body.sonata-bc .section-wysiwyg h3, body.institutionnelle .editable h2,
body.institutionnelle .preview h2,
body.institutionnelle .medium-editor-element h2,
body.institutionnelle .section-wysiwyg h2,
body.sonata-bc .editable h2,
body.sonata-bc .preview h2,
body.sonata-bc .medium-editor-element h2,
body.sonata-bc .section-wysiwyg h2, body.cms main h3, body.cms main h2 {
  display: block;
  position: relative;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #0374bc;
}

.title-1 {
  font-size: 1.875rem;
}

.title-2, body.institutionnelle .editable h2,
body.institutionnelle .preview h2,
body.institutionnelle .medium-editor-element h2,
body.institutionnelle .section-wysiwyg h2,
body.sonata-bc .editable h2,
body.sonata-bc .preview h2,
body.sonata-bc .medium-editor-element h2,
body.sonata-bc .section-wysiwyg h2, body.cms main h2 {
  font-size: 1.75rem;
}

.title-3, body.institutionnelle .editable h3,
body.institutionnelle .preview h3,
body.institutionnelle .medium-editor-element h3,
body.institutionnelle .section-wysiwyg h3,
body.sonata-bc .editable h3,
body.sonata-bc .preview h3,
body.sonata-bc .medium-editor-element h3,
body.sonata-bc .section-wysiwyg h3, body.cms main h3 {
  font-size: 1.5rem;
}

.title-4 {
  color: #757575;
  font-size: 1.25rem;
}

.title-5 {
  margin-bottom: 0;
  color: #757575;
  font-size: 1.125rem;
}

.title-2-custom {
  text-align: center;
  font-weight: bold;
  font-size: 1.4375rem;
  display: flex !important;
}
.title-2-custom::before, .title-2-custom::after {
  content: "";
  flex: 1;
  border-bottom: 3px solid #0374bc;
  opacity: 0.7;
  margin: auto 0.5em; /* 0.5em : espace entre texte et bords */
  /* + IE */
  display: inline;
  height: 0;
}
.title-2-custom strong {
  font-weight: inherit;
}
@media (min-width: 768px) {
  .title-2-custom {
    font-size: 1.75rem;
  }
}

.paging {
  width: 150px;
  margin: auto;
}
.paging ul {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.paging ul li {
  padding: 0 7px;
}
.paging ul li a {
  text-decoration: none;
  color: #757575;
}
.paging ul li a svg {
  color: #6bc024;
  stroke-width: 3px;
}
.paging ul li:first-child svg {
  transform: rotate(-90deg);
}
.paging ul li:last-child svg {
  transform: rotate(90deg);
}
.paging ul .current {
  font-weight: bold;
  color: #6bc024;
}

.form p {
  margin: 0;
}

.input-group {
  display: block;
  width: 100%;
}
.input-group label {
  font-weight: bold;
}
.input-group .input_infos,
.input-group .input_error {
  float: right;
  font-style: italic;
  font-size: 0.875rem;
}
.input-group .input_error {
  display: none;
}
.input-group input,
.input-group textarea {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #757575;
  padding: 10px;
}
.input-group input:focus,
.input-group textarea:focus {
  border-color: #0374bc;
  outline: none;
}
.input-group textarea {
  height: 190px;
}
.input-group.is-error {
  color: #f0941d;
}
.input-group.is-error input,
.input-group.is-error textarea {
  color: #f0941d;
  border-color: #f0941d;
}
.input-group.is-error .input_error {
  display: block;
}

.btn-checkbox,
.btn-radio {
  /* Masquer le bouton radio d'origine */
  /* Affichage par défaut du bouton radio personnalisé */
  /* Affichage du bouton radio personnalisé quand il est sélectionné */
}
.btn-checkbox p,
.btn-radio p {
  font-weight: bold;
}
.btn-checkbox .input_error,
.btn-radio .input_error {
  float: none !important;
}
.btn-checkbox .input_infos,
.btn-radio .input_infos {
  float: none !important;
  display: block;
}
.btn-checkbox label,
.btn-radio label {
  padding-right: 30px;
  padding-left: 5px;
  font-weight: normal;
  cursor: pointer;
}
.btn-checkbox input[type=checkbox],
.btn-checkbox input[type=radio],
.btn-radio input[type=checkbox],
.btn-radio input[type=radio] {
  display: none;
}
.btn-checkbox input[type=checkbox] + label::before,
.btn-checkbox input[type=radio] + label::before,
.btn-radio input[type=checkbox] + label::before,
.btn-radio input[type=radio] + label::before {
  background: #fff;
  border: 2px solid #757575;
  margin-top: -3px;
  margin-right: 6px;
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 17px;
  height: 17px;
}
.btn-checkbox input[type=radio] + label::before,
.btn-radio input[type=radio] + label::before {
  border-radius: 50%;
}
.btn-checkbox input[type=checkbox]:checked + label::before,
.btn-checkbox input[type=radio]:checked + label::before,
.btn-radio input[type=checkbox]:checked + label::before,
.btn-radio input[type=radio]:checked + label::before {
  background: #0374bc;
  border-color: #0374bc;
}

.attachment {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .attachment {
    width: auto;
  }
}
.attachment .input_infos {
  float: none;
  display: block;
  text-align: right;
}
.attachment .input_error {
  float: none;
  text-align: left;
}
.attachment .btn {
  border-width: 3px;
  padding: 10px 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .attachment .btn {
    width: auto;
  }
}
.attachment input[type=file] {
  /* modification du conteneur */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.attachment .input_filename {
  display: block;
  color: #0374bc;
  font-size: 0.75rem;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.attachment.is-error .input_filename {
  color: #f0941d;
}

.scroll-down {
  display: none;
}
@media (min-width: 992px) {
  .scroll-down {
    position: absolute;
    opacity: 1;
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    background-color: transparent;
    box-shadow: none;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 10;
    animation: bounce 2s infinite 2s;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    outline: 0;
  }
  .scroll-down::before {
    position: absolute;
    content: "";
    top: calc(50% - 11px);
    left: calc(50% - 8px);
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
  }
  .loading .scroll-down, .no-overflow .scroll-down, .body-menu-navigation-opened .scroll-down, .body-menu-profile-opened .scroll-down, .search-form-in-menu-opened .scroll-down {
    z-index: 0;
  }
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.bloc-tarif {
  margin: 50px 0;
  justify-content: center;
  display: grid;
  grid-template-columns: 200px 320px 200px;
  grid-template-rows: 50px 30px auto 30px auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.bloc-tarif.bloc-tarif-position-left {
  grid-template-columns: 200px 320px 0;
}
.bloc-tarif.bloc-tarif-position-right {
  grid-template-columns: 0 320px 200px;
}

.tarif-title {
  border-top-left-radius: 15px;
  background: #0374BC;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  grid-area: 1/2/2/3;
}

.tarif-central-bloc {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0705882353);
  color: #0374BC;
  padding: 5px 45px;
  grid-area: 2/2/5/3;
}

.tarif-central-bloc p {
  margin: 10px 0;
}

.tarif-info-bloc {
  background: #f0941d;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 145px;
}
.tarif-info-bloc.tarif-info-bloc-position-left {
  border-radius: 15px 0 0 15px;
  grid-area: 3/1/4/2;
}
.tarif-info-bloc.tarif-info-bloc-position-right {
  border-radius: 0 15px 15px 0;
  grid-area: 3/3/4/4;
}

.tarif-info-bloc p {
  margin: -2px 0;
}

@media (max-width: 600px) {
  .bloc-tarif, .bloc-tarif.bloc-tarif-position-left, .bloc-tarif.bloc-tarif-position-right {
    grid-template-columns: 0 320px 0;
  }
  .tarif-info-bloc, .tarif-info-bloc.tarif-info-bloc-position-left, .tarif-info-bloc.tarif-info-bloc-position-right {
    grid-area: 5/2/6/3;
    width: 200px;
    margin: 0 0 0 auto;
    border-radius: 0 0 15px 0;
  }
}
.formula-horizontal-row {
  margin-bottom: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.0705882353);
  border-radius: 13px 0;
}