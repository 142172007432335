$path-core-bundle: '../../../public/';

// Breakpoints du core
$mobile: 568px;
$phablet: 768px;
$tablet: 992px;
$laptop: 1200px;
$desktop: 1440px;

$core-breakpoints: (
  xs: 0,
  sm: $mobile,
  md: $phablet,
  lg: $tablet,
  xl: $laptop,
  xxl: $desktop
);

// Var's typography — font-family
$font-primary-core-bundle: "Open Sans", "Helvetica Neue", Arial, Roboto, sans-serif;

// Var's color
// $greendark: darken($green, 5%);
// $orangedark: darken($orange, 5%);

$grey-light-core-bundle: #ebebeb;
$grey-core-bundle: #d3d3d3;
$grey-dark-core-bundle: #757575;
$grey-verydark-core-bundle: darken($grey-dark-core-bundle, 20%);

$color-primary-core-bundle: #0374bc;
$color-secondary-core-bundle: #6bc024;
$color-third-core-bundle: #f0941d;
$color-font-core-bundle: $grey-dark-core-bundle;
$color-background-core-bundle: $grey-light-core-bundle;
$color-agency-headband-core-bundle: #585858;
$section-background-core-bundle: #f5f6f7;
$color-error-core-bundle: #f00;

// Other
$shadow-core-bundle: 0 2px 4px 0 rgba(0, 0, 0, .15);
$navTopHeight: 40;
