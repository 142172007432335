body.quoterequest {

  .content {

    .steps {

      margin-bottom: 30px;

      .step {
        position: relative;
        display: inline-block;
        padding: 5px;
        color: $colorBlue;
        text-align: center;

        @media (min-width: $screen-sm) {
          padding: 5px 15px;
        }
        @media (min-width: $screen-md) {
          padding: 5px 25px;
        }

        &:not(:last-child):before {
          position: absolute;
          top:85px;
          right:-10px;
          content: '|';
          display: block;
          @include em-size(20);
          color: #dadada;

          @media (min-width: $screen-sm) {
            top:95px;
          }
        }

        .step-label {
          display: block;
          @include em-size(12);

          @media (min-width: $screen-sm) {
            @include em-size(14);
          }

          &:before {
            content: '';
            display: block;
            width: 65px;
            height: 65px;
            margin: 3px auto;
            @extend .sprite;
            background-position: 2px -201px;
          }

        }


        &.is-disabled {
          .step-label {
            &:before {
              background-position: 2px -259px;
            }
          }
        }

        &.is-checked {
          .step-label {
            &:before {
              background-position: 2px -142px;
            }
          }
        }

        /* bonhomme */
        &:nth-of-type(2) {
          .step-label {
            &:before {
              background-position: 12px -372px;
            }
          }
        }

        &.is-disabled:nth-of-type(2) {
          .step-label {
            &:before {
              background-position: 12px -431px;
            }
          }
        }

        &.is-checked:nth-of-type(2) {
          .step-label {
            &:before {
              background-position: 12px -313px;
            }
          }
        }


        /* bloc-notes */
        &:nth-of-type(3) {
          .step-label {
            &:before {
              background-position: 12px -548px;
            }
          }
        }
        &.is-disabled:nth-of-type(3) {
          .step-label {
            &:before {
              background-position: 12px -607px;
            }
          }
        }

        &.is-checked:nth-of-type(3) {
          .step-label {
            &:before {
              background-position: 12px -489px;
            }
          }
        }




        .step-status {
          position: relative;
          display: inline-block;
          width: 15px;
          height: 15px;
          margin: 1px;
          background-color: $colorBlue;
          border-radius: 100%;

          @media (min-width: $screen-sm) {
            width: 20px;
            height: 20px;
            margin: 3px 20px;
          }

          &.is-disabled {
            border:4px solid #dadada;
            background-color: #fff;
          }
          &.is-checked {
            border:0;
            background-color: $colorSuccess;
          }
        }


      &.is-disabled {
        color: #dadada;
      }

      &.is-checked {
        color: $colorSuccess;
      }

      }

    }

    .form-quoterequest {

      padding: 20px 0;

      h2 {
        @include em-size(18);
        font-weight: normal;
        text-align: center;

        @media (min-width: $screen-sm) {
          @include em-size(28);
        }
      }




      .msg-error {
        display:none;
        color:red;
        margin: 10px 0;

        &.show {
          display: block;
        }

        br {
          display:none;
        }
      }




      .form-group {

        label {

          line-height: 100%;

          @media (max-width: $screen-sm) {
            text-align: left;
          }
        }

        .radio {
          float:left;
          padding-right: 10px;

          input {
            margin-top: 0;
          }
        }






        /* special styles for date de naissance */
        #quote_step3_dateNaissance {

          .btn-group {
            margin-bottom: 4px;

            @media (min-width: $screen-sm) {
              float: left;
              width:32.5%;
              margin-right: 4px;
              margin-bottom: 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }


        /* special styles for date de 1ere immatriculation */
        #quote_step2_miseEnCirculation {

          .btn-group {
            margin-bottom: 4px;

            @media (min-width: $screen-sm) {
              float: left;
              width:49.4%;
              margin-right: 4px;
              margin-bottom: 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }




      }



      .button-row {
        margin-top:40px;


        div button {
          margin:10px auto;
        }


        @media (min-width: $screen-sm) {

          div button {
            float: left;
          }

          div:last-child button {
            float: right;
          }

        }

      }

      .etape-loading-form {
        width:64px;
        height:64px;
        display:block;
        margin:130px auto;
        background:url('../../images/mdp/loader.gif') no-repeat center center;
      }

      .etape-form {
        transition:opacity .5s ease;
        text-align:center;
        position:absolute;
        opacity:0;
        top:0;

        @media (min-width: $screen-sm) {
          &#etape-4 .notes {
            width:660px;
            padding-left: 10px;
          }
        }

        @media (min-width: $screen-lg) {
          &#etape-1 {
            .form-group-radio .custom-radio,
            .form-group-checkbox .custom-radio,
            .form-group-radio .custom-checkbox,
            .form-group-checkbox .custom-checkbox {
              margin-left: 0;
            }
          }

          &#etape-4 .notes {
            width:620px;
          }
        }


      }
      .etape-active {
        position:relative;
        opacity:1;
        z-index:1;
        top:0;
      }


    }



    .results {
      background: #ebebeb;

      .no-result,
      .result-confirmation
      {
        background: #fff;

        h2 {
          color: $colorText;
          text-transform: uppercase;
          font-weight: normal;
          @include em-size(24);
          margin-top:20px;
          margin-bottom:40px;
          text-align: center;
        }

      }

    }





  }

}

.personal-prices {
	background-color: #ebebeb;
	padding: 20px 0;

	h2 {
		text-transform: uppercase;
		color: $colorAnthracite;
		font-weight: normal;
		text-align: center;
		margin-bottom: 30px;
		@include em-size(24);
	}
}

.agences {

  padding-top:15px;
  padding-bottom:30px;

  h2 {
    line-height: 130%;
    margin-bottom: 30px;
    font-weight: normal;
  }

}

.expand-franchises {

  a {
    display: block;
    padding: 10px;
    color: #fff;
    background: $colorGrey;
    line-height:110%;
    text-align: center;
    @include em-size(16);
    font-weight: bold;
    @include transition(all .5s);
    border:1px dotted transparent;

    &:hover,
    &:focus
    {
      text-decoration: none;
      background: darken($colorGrey, 10%);
    }
    &:focus {
      outline:0;
      text-decoration: underline;
      border:1px dotted #fff;
    }
  }

  a > span {
    color: #fff;
  }

  @media (max-width: $screen-xs) {
    a > i {
      display: block;
    }
  }

}

#collapseDetailFranchises {

  .row {
    padding-bottom: 30px;
  }

}



body.quoterequest.sarpgn {
  .content {
    .form-quoterequest {
      .etape-loading-form {
       // background-image:url('../../images/sarpgn/loader.gif');
      }
    }
  }
}


