@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/select2/dist/css/select2";

$icomoon-font-path: "/fonts/";
@import "assets/fonts/icomoon/style.scss";

@import "init/vars";
@import "init/mixins";
@import "init/states";
@import "init/layout";

/* modules */
@import "modules/btn";
@import "modules/cookie";
@import "modules/form";
@import "modules/flash-messages";
@import "modules/loader";
@import "modules/modal";
@import "modules/print";
@import "modules/push-agency";
@import "modules/push-home";
@import "modules/push-offer";
@import "modules/typography";
@import "modules/table";
@import "modules/list";

/* regions */
@import "regions/breadcrumb";
@import "regions/content";

/* templates */
@import "templates/quoterequest";
@import "templates/formula";
@import "templates/agency";
@import "templates/error";
@import "templates/legal";
