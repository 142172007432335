// Conversion de la palette de couleurs

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

// Conversion des unités en rem

@function rem($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }
  $rem-size: $size / $base-font-size;
  @return #{$rem-size}rem;
}

// Conversion des unités en em

@function em($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }
  $em-size: $size / $base-font-size;
  @return #{$em-size}em;
}

@mixin is-hidden {
  display: none !important;
  pointer-events: none;
}

/*
Calcul de la font PX to EM
*/

@function calculaterem($size: $baseFontSize) {
  $remSize: $size / 16;
  @return $remSize * 1rem;
}

@mixin em-size($size: 16) {
  font-size: $size;
  font-size: calculaterem($size);
}

/*
Créer une transition entre deux états d'un élément
$arguments		- Paramètres de la transition
*/

@mixin transition($arguments...) {
  -webkit-transition: $arguments;
  transition: $arguments;
}

/*
Gérer la transparence/opacité d'un élément
$opacity: 		- Opacité d'un élément de 0 (transparent) à 1 (opaque)
*/

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityPercentage: $opacity * 100;

  filter: alpha(opacity=$opacityPercentage);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
$y 				- Axe des ordonnées Y
$z 				- Axe de la profondeur Z
*/

@mixin translate3d($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

/*
Gérer la rotation d'un élément
$deg			- Rotation en degrés d'un élément (unité = deg)
*/

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
  @include translate3d(0, 0, O);
}

/*
Gérer la rotation d'un élément
$ratio 			- Ratio scale (0.5 = 50%, 1 = 100%, 1.5 = 150%)
*/

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio);
  transform: scale($ratio);
  @include translate3d(0, 0, O);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
*/

@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$y   			- Axe des ordonnées Y
*/

@mixin translateY($y) {
  -webkit-transform: translateY($y);
  -ms-transform: translateY($y);
  transform: translateY($y);
}

/*
Dégradé vertical
$start-color 	- Couleur de départ (default = #555)
$end-color		- Couleur de fin (default = #333) : couleur également utilisée en fallback
$start-percent 	- Niveau de départ de la couleur top en pourcentage (default = 0%)
$end-percent 	- Niveau de fin de la couleur bottom en pourcentage (default = 100%)
*/

@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background: $end-color;
  background: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
}

/*
Dégradé horizontal
$start-color 	- Couleur de départ (default = #555)
$end-color 		- Couleur de fin (default = #333) : couleur également utilisée en fallback
$start-percent 	- Niveau de départ de la couleur top en pourcentage (default = 0%)
$end-percent  	- Niveau de fin de la couleur bottom en pourcentage (default = 100%)
*/

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background: $end-color;
  background: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
  background: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
}

/*
Dégradé radial
$start-color 	- Couleur de départ
$end-color 		- Couleur de fin : couleur également utilisée en fallback
*/

@mixin gradient-radial($start-color, $end-color) {
  background: $end-color;
  background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
  background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
}

/*
Box-sizing
$type 			- Valeur de la propriétée box-sizing (border-box par défaut)
*/

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

/*
Gérer l'inclinaison d'un élément
$deg 			- Inclinaison en degrés d'un élément (unité = deg)
*/

@mixin skew($deg) {
  -webkit-transform: skew($deg);
  -ms-transform: skew($deg);
  transform: skew($deg);
  @include translate3d(0, 0, O);
}

/*
Gérer l'inclinaison d'un élément sur l'axe des ordonnées
$deg  			- Inclinaison en degrés d'un élément (unité = deg) sur l'axe des ordonnées
*/

@mixin skewY($deg) {
  -webkit-transform: skewY($deg);
  -ms-transform: skewY($deg);
  transform: skewY($deg);
  @include translate3d(0, 0, O);
}

/*
Gérer le flou d'un élément
$dist 			- Valeur en px du flou sur un élément
*/

@mixin blur($dist) {
  -webkit-filter: blur($dist);
  -ms-filter: blur($dist);
  filter: blur($dist);
  @include translate3d(0, 0, O);
}

/*
Gérer le retour à la ligne après des éléménrts flottants
*/

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
