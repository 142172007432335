.grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.body-browser-ie {
  @include media(">=md") {
    .grid-2-cols > div {
      width: 400px;
      margin: 10px;
    }

    .grid-3-cols > div {
      width: 270px;
      margin: 10px;
    }
  }
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-template-rows: auto;
    align-items: stretch;
    justify-items: stretch;
    grid-template-columns: 1fr;
    grid-gap: 1.4rem;
  }

  .grid-2-cols {
    @include media(">=md") {
      grid-template-columns: 1fr 1fr;
    }
  }

  .grid-3-cols {
    @include media(">=md") {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
