.bloc-focus {
  position: relative;
  margin: 10px auto;
  padding: 10px;
  max-width: 100%;

  &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 10px;
      -webkit-transform: translate3d(-50%,0,0);
      -moz-transform: translate3d(-50%,0,0);
      transform: translate3d(-50%,0,0);
      display: block;
      width: 148px;
      height: 170px;
      background: url(#{$path-core-bundle}images/mascottes/mascotte.png);
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;

  }

  @include media(">=sm") {
    width: 900px;
    padding-left: 200px;

    &::after {
      left: 20px;
      transform: translate3d(0, 0, 0);
    }
  }
}

.bloc-focus-inner:not(.focus-mobilite) {
  border: rem(2) solid $color-secondary-core-bundle;
  padding: 20px 30px 200px 20px;
  border-radius: 10px 0;
  background-color: #fff;

  @include media(">=sm") {
    padding-bottom: 30px;
  }
}

.bloc-focus-inner.focus-mobilite {
  border-top: rem(8) solid $color-secondary-core-bundle;
  padding: 20px 30px 200px 20px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0 10px 20px #00000012;
  border-radius: 13px 0;

  @include media(">=sm") {
    padding-bottom: 30px;
  }
}

.bloc-focus-title {
  display: block;
  text-align: center;
  font-weight: bold;
  margin: 10px auto 20px auto;
  color: $color-secondary-core-bundle;
  font-size: rem(18);
}

.bloc-focus-content {
  text-align: left;
  font-size: rem(14);
}
