.scroll-down {
  display: none;

  @include media(">=lg") {
    position: absolute;
    opacity: 1;
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    background-color: transparent;
    box-shadow: none;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 10;
    animation: bounce 2s infinite 2s;
    transition: all .2s ease-in-out;
    transform: scale(1);
    outline: 0;

    &::before {
      position: absolute;
      content: "";
      top: calc(50% - 11px);
      left: calc(50% - 8px);
      display: block;
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      border-width: 0 0 2px 2px;
      transform: rotate(-45deg);
    }

    .loading &,
    .no-overflow &,
    .body-menu-navigation-opened &,
    .body-menu-profile-opened &,
    .search-form-in-menu-opened & {
      z-index: 0;
    }
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}
