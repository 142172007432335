.btn-ag, .btn {
  display: inline-block;
  width: 270px;
  max-width: 100%;
  line-height: 110%;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  white-space: normal;
  background-color: #fff;
  color: $colorText;
  border: 1px dotted transparent;
  border-radius: 10px;
  @include em-size(12);
  @include transition(all .2s linear);

  &:hover,
  &:focus {
      background-color: $colorText;
      color: #fff;
  }
  &:focus,
  &:active {
      outline: none !important;
      border: 1px dotted #fff;
      text-decoration: underline;
  }

  &.btn-ag-bg-green {
      background-color: $colorGreen;
      color: #fff;

      &:hover,
      &:focus {
        background-color: $colorGreenHover;
        color: #fff;
      }
  }

  &.btn-ag-bg-orange {
      background-color: $colorOrange;
      color: #fff;

      &:hover,
      &:focus {
        background-color: darken($colorOrange, 10%);
        color: #fff;
      }
  }

  &.btn-ag-bg-blue {
      background-color: $colorBlue;
      color: #fff;

      &:hover,
      &:focus {
        background-color: darken($colorBlue, 10%);
        color: #fff;
      }
  }

  &.btn-ag-bg-grey {
      background-color: $colorGrey;
      color: #fff;

      &:hover,
      &:focus {
        background-color: darken($colorGrey, 10%);
        color: #fff;
      }
  }

  &.btn-ag-2x {
      width: auto;
      min-width: inherit;
      padding: 15px 30px;
      @include em-size(17);

      &.push-agency-button {
        padding: 15px 25px;
        @include em-size(14);
      }
  }

	&.btn-ag-full {
		width: 100%;
		text-align: center;
		padding: 13px 15px;
		@include em-size(18);
	}

  &.btn-ag-3x {
      padding: 15px 70px;
      text-shadow: 2px 2px 2px rgba(0, 0 ,0, .2);
      @include em-size(24);

      @media only screen and (max-width: 991px) {
        padding: 12px 30px;
        @include em-size(18);
      }
  }

  &.btn-ag-width-auto {
      display: inline-block;
      width:auto;
  }

  &.btn-ag-offer {
    position: relative;
    width: auto;
    color: #fff;
    background-color: $colorGreen;
    padding: 13px 20px 13px 55px !important;
    text-decoration: none;
    @include em-size(22);

    &:hover {
      text-decoration: none;
      background-color: $colorBlue;
    }

    .iicon {
      position: absolute;
      left: 10px;
      top: 12px;
      width: 40px;
      height: 30px;
      display: inline-block;
      font-family: "icomoon";
      content: '';
      color: #fff;
      @include em-size(19);

      &::before {
        color: #fff;
      }
    }

    &.btn-ag-house {
      .iicon {
        top: 14px;
        @include em-size(15);
      }
    }

    &.btn-ag-health {
      .iicon {
        left: 7px;
        top: 8px;
        @include em-size(26);
      }
    }
  }
}

/* bouton assistance */
.btn-ag-assistance {
  position: relative;
  margin: 30px auto;
  padding: 0 20px;
  overflow: visible;
  border-radius: 0;
  border: 0 !important;
  display:block;
  width: 100%;
  max-width: 500px;
  color: $colorBlue;
  text-decoration: none;

  span {
    display: block;
    text-transform: initial;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none !important;
  }

  .iicon-assistance-popin {
    position: absolute;
    display: inline-block;
    left: 30px;
    top: 15px;
    overflow: visible;
    @include em-size(30);
  }

  .iicon-chevron-thin-right {
    position: absolute;
    display: inline-block;
    right: 42px;
    top: 15px;
    overflow: visible;
    @include em-size(26);
  }

  .btn-ag-assistance-intro {
    width: 100%;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    padding: 15px 45px;
    @include em-size(16);
  }

  .btn-ag-assistance-info {
    margin: -4px 0 15px;
    font-weight: normal;
    text-align: center;
    color: $colorAnthracite;
    @include em-size(13);
  }

  &:focus,
  &:hover {
    background-color: transparent;
    color: inherit;
    text-decoration: none !important;
    color: $colorBlue;

    .btn-ag-assistance-intro {
      color: $colorBlue;
      text-decoration: underline !important;
    }

    .btn-ag-assistance-info {
      border: 0 !important;
    }
  }
}
