.form {
  p {
    margin: 0;
  }
}

//CHAMPS TEXTE + ZONE DE TEXTE
.input-group {
  display: block;
  width: 100%;

  label {
    font-weight: bold;
  }

  .input_infos,
  .input_error {
    float: right;
    font-style: italic;
    font-size: rem(14);
  }

  .input_error {
    display: none;
  }

  input,
  textarea {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: 2px solid $grey-dark-core-bundle;
    // margin-bottom: 30px;
    padding: 10px;

    &:focus {
      border-color: $color-primary-core-bundle;
      outline: none;
    }
  }

  textarea {
    height: 190px;
  }

  &.is-error {
    color: $color-third-core-bundle;

    input,
    textarea {
      color: $color-third-core-bundle;
      border-color: $color-third-core-bundle;
    }

    .input_error {
      display: block;
    }
  }
}

//BOUTON RADIO & CHECKBOX
.btn-checkbox,
.btn-radio {
  p {
    font-weight: bold;
  }

  .input_error {
    float: none !important;
  }

  .input_infos {
    float: none !important;
    display: block;
  }

  label {
    padding-right: 30px;
    padding-left: 5px;
    font-weight: normal;
    cursor: pointer;
  }

  /* Masquer le bouton radio d'origine */
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  /* Affichage par défaut du bouton radio personnalisé */
  input[type="checkbox"] + label::before,
  input[type="radio"] + label::before {
    background: #fff;
    border: 2px solid $grey-dark-core-bundle;
    margin-top: -3px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 17px;
    height: 17px;
  }

  input[type="radio"] + label::before {
    border-radius: 50%;
  }

  /* Affichage du bouton radio personnalisé quand il est sélectionné */
  input[type="checkbox"]:checked + label::before,
  input[type="radio"]:checked + label::before {
    background: $color-primary-core-bundle;
    border-color: $color-primary-core-bundle;
  }
}

//PIECE JOINTE
.attachment {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: 10px;

  @include media(">=md") {
    width: auto;
  }

  .input_infos {
    float: none;
    display: block;
    text-align: right;
  }

  .input_error {
    float: none;
    text-align: left;
  }

  .btn {
    border-width: 3px;
    padding: 10px 20px;
    width: 100%;

    @include media(">=md") {
      width: auto;
    }
  }

  input[type="file"] {
    /* modification du conteneur */
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .input_filename {
    display: block;
    color: $color-primary-core-bundle;
    font-size: rem(12);
    overflow: hidden;
    word-wrap: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.is-error {
    .input_filename {
      color: $color-third-core-bundle;
    }
  }
}
