.bloc-tarif {
  margin: 50px 0;
  justify-content: center;
  display: grid;
  grid-template-columns: 200px 320px 200px;
  grid-template-rows: 50px 30px auto 30px auto;
  grid-column-gap: 0;
  grid-row-gap: 0;

  &.bloc-tarif-position-left {
    grid-template-columns: 200px 320px 0;
  }

  &.bloc-tarif-position-right {
    grid-template-columns: 0 320px 200px;
  }
}

.tarif-title {
  border-top-left-radius: 15px;
  background: #0374BC;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 2 / 2 / 3;
}
.tarif-central-bloc {
  box-shadow: 0 10px 20px #00000012;
  color: #0374BC;
  padding: 5px 45px;
  grid-area: 2 / 2 / 5 / 3;
}
.tarif-central-bloc p {
  margin: 10px 0;
}

.tarif-info-bloc {
  background: #f0941d;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 145px;

  &.tarif-info-bloc-position-left {
    border-radius: 15px 0 0 15px;
    grid-area: 3 / 1 / 4 / 2;
  }

  &.tarif-info-bloc-position-right {
    border-radius: 0 15px 15px 0;
    grid-area: 3 / 3 / 4 / 4;
  }
}

.tarif-info-bloc p {
  margin: -2px 0;
}

@media (max-width: 600px) {
  .bloc-tarif, .bloc-tarif.bloc-tarif-position-left, .bloc-tarif.bloc-tarif-position-right {
    grid-template-columns: 0 320px 0;
  }
  .tarif-info-bloc, .tarif-info-bloc.tarif-info-bloc-position-left, .tarif-info-bloc.tarif-info-bloc-position-right {
    grid-area: 5 / 2 / 6 / 3;
    width: 200px;
    margin: 0 0 0 auto;
    border-radius: 0 0 15px 0;
  }
}
