/* fonts */
$baseFontSize:  16;
$base-font-size: 16px;
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic");
$fontPrimary: 'Open Sans', 'Arial', 'Verdana', sans-serif;
$font-ico: "icomoon";

/* paths */
$imagePath: "/images/";
$vendorPath: "vendor/";
$fontPath: "/fonts/";

/* colors */
$colorPrimary: #0076be; /* Bleu foncé */
$colorSecondary: #6bc024; /* Vert */

$colorBlue: $colorPrimary;
$colorBlueLight: #74aae7;
$colorGreen: #6bc024;
$colorGreenHover: #74d425;
$colorOrange: #ff9600;
$colorRed: #b80451;
$colorWhite: #ffffff;
$colorAnthracite: #3b3b3b;
$colorGrey: #bfbfbf;
$colorLightGrey: #ebebeb;

$colorText: #444444;
$colorTextBis: #757575;

$colorInputBorder: #dbdada;
$colorInputBg: #f2f3f3;
$colorInputText: #000000;

$colorError: #ff0000;
$colorSuccess: #6bc024;
$outlineWhite: 1px dotted #ffffff;

// Breakpoints
$mobile: 568px;
$phablet: 768px;
$tablet: 992px;
$laptop: 1200px;
$desktop: 1600px;
$maxidesktop: 2000px;

$grid-breakpoints: (
  xs: 0,
  sm: $mobile,
  md: $phablet,
  lg: $tablet,
  xl: $laptop,
  xxl: $desktop,
  bigxxl: $maxidesktop
);
