/*
Cacher le texte de son conteneur
*/
@mixin ir() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/*
Calcul de la font PX to EM
*/
@function calculateRem($size: $baseFontSize) {
  $remSize: $size / 16;
  @return $remSize * 1rem;
}

@mixin em-size($size: 16) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*
Créer une transition entre deux états d'un élément
$arguments		- Paramètres de la transition
*/
@mixin transition($arguments) {
  -webkit-transition: $arguments;
  transition: $arguments;
}

/*
Gérer la transparence/opacité d'un élément
$opacity: 		- Opacité d'un élément de 0 (transparent) à 1 (opaque)
*/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityPercentage: $opacity * 100;
  filter: alpha(opacity=$opacityPercentage);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
$y 				- Axe des ordonnées Y
$z 				- Axe de la profondeur Z
*/
@mixin translate3d($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

/*
Gérer la rotation d'un élément
$deg			- Rotation en degrés d'un élément (unité = deg)
*/
@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
  @include translate3d(0, 0, O);
}

/*
Gérer la rotation d'un élément
$ratio 			- Ratio scale (0.5 = 50%, 1 = 100%, 1.5 = 150%)
*/
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio);
  transform: scale($ratio);
  @include translate3d(0, 0, O);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$x 				- Axe des abscisses X
*/
@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
}

/*
Gérer la position et le déplacement sur 3 axes d'un élément
$y   			- Axe des ordonnées Y
*/
@mixin translateY($y) {
  -webkit-transform: translateY($y);
  -ms-transform: translateY($y);
  transform: translateY($y);
}

/*
Gérer le retour à la ligne après des éléménrts flottants
*/
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/*
Sprite SVG
*/
.sprite {
  background-image: url('../../images/mdp/pictos.svg?v=2');
  background-repeat: no-repeat;
  background-size: 60px 843px;
}
