.footer-core-bundle {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: $color-background-core-bundle;

  .agencie-sarpgn-link {
    .text {
      &::before {
        background-image: url(#{$path-core-bundle}images/mascottes/mascotte-sarpgn3.png) !important;
      }
    }
  }

  .agencie-sarpsp-link {
    .text {
      &::before {
        background-image: url(#{$path-core-bundle}images/mascottes/mascotte-sarpgn3.png);
      }
    }
  }

  .agencie-link, .agencie-sarpgn-link, .agencie-sarpsp-link {
    position: relative;
    overflow: hidden;
    border-top: 5px solid $color-secondary-core-bundle;
    background-image: url(#{$path-core-bundle}images/footer/bg-map.jpg);
    background-size: cover;
    text-align: center;
    background-position: center center;

    img.map-france {
      position: absolute;
      height: 190px;
      left: 120px;
      width: auto;
    }

    .inner {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 40px 0;
      min-height: 310px;
    }

    .text {
      padding-left: 400px;
      display: block;
      width: 100%;
      flex: 1;

      &::before {
        position: absolute;
        left: 760px;
        bottom: -32px;
        z-index: 1;
        display: block;
        width: 143px;
        height: 162px;
        content: "";
        background-image: url(#{$path-core-bundle}images/mascottes/mascotte3.png);
        background-position: center center;
        background-repeat: no-repeat;
      }

      .title-your-agencie {
        font-weight: bold;
        padding-right: 60px;
        padding-top: 30px;
        color: $grey-verydark-core-bundle;
        line-height: 1.3;
        margin: 0 0 35px 0;
        @include em-size(28);
      }

      &.text-your-agencie {
        &::before {
          display: none;
        }

        p {
          margin: 0;
          line-height: 1.3;
          color: $color-agency-headband-core-bundle;

          &.agencie-name {
            font-weight: bold;
            text-transform: uppercase;
            padding: 8px 0;
            @include em-size(27);
          }

          &.agent-name {
            font-weight: bold;
          }

          &.orias-number,
          &.open-status,
          &.phone-number,
          &.email-link {
            position: relative;
            padding-left: 34px;
            padding-top: 12px;

            img,
            .iicon {
              position: absolute;
              left: 0;
              top: 9px;
              @include em-size(25);
            }
          }

          &.orias-number {
            display: flex;
            padding-left: 0;
            @include em-size(13);

            > .tooltip {
              width: 27px;
            }

            .iicon {
              top: 0;
              @include em-size(20);
            }
          }

          &.open-status,
          &.phone-number,
          &.email-link {
            font-weight: bold;

            img {
              margin-top: 4px;
            }
          }

          &.open-status {
            text-transform: uppercase;
            @include em-size(18);

            &.is-opened {
              color: $color-secondary-core-bundle;
            }

            &.is-closed {
              color: $color-third-core-bundle;
            }
          }

          &.phone-number {
            @include em-size(23);
          }

          &.email-link {
            padding-bottom: 18px;
            @include em-size(18);

            a {
              color: $color-agency-headband-core-bundle;
              text-decoration: none;
              @include transition(all .2s);

              &:hover {
                text-decoration: underline;
              }
            }

            img {
              margin-top: 6px;
            }
          }
        }

        .text-left {
          float: left;
          padding-right: 35px;
          padding-top: 40px;

          img {
            width: 20px;
            height: auto;
          }

          &.text-left-contact {
            padding-right: 0;
            padding-top: 20px;
          }
        }
      }
    }
  }

  // Bouton dans bandeau agence
  .btn-bandeau-agence-core-bundle {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 8px;
    letter-spacing: normal;
    padding: 6px 15px;
    font-weight: bold;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border: 2px solid $color-secondary-core-bundle;
    background-color: $color-secondary-core-bundle;
    font-size: rem(16);
    transition: all .2s;

    &:focus,
    &:hover {
      text-decoration: none;
      opacity: .8;
    }

    @include media(">=md") {
      text-transform: uppercase;
      padding: 15px 30px;
      font-size: rem(20);
    }
  }

  // Btn back to top
  .btn-to-top-core-bundle {
    float: right;
    position: relative;
    margin-top: -225px;
    display: block;
    width: 42px;
    height: 42px;
    z-index: 3;
    border: 0;
    padding: 0;
    border-radius: 0;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    color: $color-primary-core-bundle;

    .iicon {
      position: absolute;
      left: 14px;
      top: 5px;
      height: 2rem;
      width: 1rem;
      transition: all .2s;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: #fff;
      color: $color-primary-core-bundle;

      .iicon {
        top: 2px;
      }
    }

    @include media(">=md") {
      position: fixed;
      right: 20px;
      bottom: 22px;
      opacity: 0;
      transition: all .2s;
      transform: translate3d(0, 120px, 0);

      .scrolling & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .footer-links-bottom {
    background-color: $color-background-core-bundle;
    font-weight: 600;
    margin-bottom: 70px;
    padding-bottom: 15px;

    p {
      color: $grey-verydark-core-bundle;
      margin: 1.6em 0;
      @include em-size(13);

      a {
        text-decoration: none;
        color: $grey-verydark-core-bundle;
        @include em-size(13);
        @include transition(all .2s);

        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }

  @include media(">=md") {
    .footer-links-bottom {
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 0;

      p {
        float: none;
        display: inline-block;
        width: auto;
        padding: 0 45px;
      }
    }
  }

  .footer-container {
    background-color: $color-primary-core-bundle;
    padding-bottom: 20px;
    color: #fff;

    .col {
      display: block;
      padding-bottom: 20px;
      text-align: left;
    }

    .footer-inner-title-col {
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 10px;
      @include em-size(16);
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0 0 10px;
        line-height: 1;

        a {
          padding: 0;
          line-height: 1.2;
          text-decoration: none;
          color: #fff;
          @include em-size(16);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer-social {
    padding: 18px 0 22px;
    margin-bottom: 15px;
    font-weight: bold;
    border-bottom: 2px solid rgba(255, 255, 255, .5);

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin: 0 6px;
      padding: 1px;
      border-radius: 100%;
      color: #fff;
      text-decoration: none;
      @include em-size(30);
      @include transition(all .1s);

      &:hover {
        background: #fff;
        color: $color-primary-core-bundle;
      }

      .iicon {
        display: block;
      }

      .iicon-facebook {
        stroke: none;
      }
    }
  }

  @include media(">=md") {
    .footer-inner {
      display: flex;
      text-align: left;
      flex-direction: row;
      justify-content: space-between;

      .col {
        min-width: 16.66%;
        padding-right: 15px;
      }

      .footer-inner-title-col {
        margin-top: 0;
        margin-bottom: 7px;
        @include em-size(13);
      }

      ul {
        padding-bottom: 15px;

        li {
          margin-bottom: 5px;

          a {
            line-height: 1;
            @include em-size(13);
          }
        }
      }
    }
  }

  .search-form-in-menu-opened & {
    z-index: 3;
  }

  .body-menu-navigation-opened & {
    .btn-to-top-core-bundle {
      display: none;
    }
  }

  .footer-without-social {
    padding-top: 60px;
  }
}
