.paging {
  width: 150px;
  margin: auto;

  ul {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      padding: 0 7px;

      a {
        text-decoration: none;
        color: $grey-dark-core-bundle;

        svg {
          color: $color-secondary-core-bundle;
          stroke-width: 3px;
        }
      }

      &:first-child {
        svg {
          transform: rotate(-90deg);
        }
      }

      &:last-child {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .current {
      font-weight: bold;
      color: $color-secondary-core-bundle;
    }
  }
}
