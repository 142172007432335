
.header-title {
    padding: 10px 0;
    margin-bottom: 30px;
    background-color: $colorOrange;
    box-shadow: inset 0px 3px 5px 0px rgba(5,5,5,.3);

    @media (min-width: $screen-sm) {
      padding: 20px 0;
    }


    h1 {
        text-align: center;
        font-weight: normal;
        text-transform: uppercase;
        color: #fff;
        @include em-size(24);

        @media (min-width: $screen-sm) {
          @include em-size(34);
        }
    }
}

.title-1,
.title-2 {
  color: $colorPrimary;
}

p {
	&.mention {
		@include em-size(13);
		margin-bottom: 20px;

        .list-puce {
          @include em-size(13);

          li {
            @include em-size(13);
          }

        }
	}
}

.title-page-agency {
  text-align: center;
  text-transform: none;
  color: $colorBlue;
  font-weight: normal;
  margin-bottom: 20px;
  @include em-size(22);
}

