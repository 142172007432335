@media print {
  * {
    -webkit-print-color-adjust: exact; /* Force l'affichage des backgrounds sur les navigateurs */
    color: $colorText !important;
  }

  a[href]:after {
    content: " (" attr(href) ")";
    @include em-size(11);
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
    @include em-size(11);
  }


  .navbar-brand a[href]:after,
  .navbar-brand abbr[title]:after,
  .breadcrumb a[href]:after,
  .breadcrumb abbr[title]:after,
  .quoterequest .list-formulas a[href]:after,
  .quoterequest .list-formulas abbr[title]:after,
  .expand-franchises > a[href]:after,
  .expand-franchises > abbr[title]:after {
    content: "";
  }

  #pre-footer,
  .quoterequest .steps{
    display: none !important;
  }

  .navbar {
    display: block;

    .navbar-brand {
      max-width: 100% !important;
      width:100% !important;
    }
  }

  .btn-ag {
    background:$colorBlue !important;
    color:#fff !important;
  }

  /* homepage */
  .form-calcul {
    .form-group {
      display: inline-block;
      margin: 5px;
      width: 450px;
      vertical-align: middle;
    }

    button {
      display: inline-block;
      margin: 5px;
      width: auto;
      background:$colorOrange !important;
      color:#fff !important;
    }
  }

  .push-agency-neutral {
    padding: 0;

    .btn-ag {
      background:$colorGreen !important;
      color:#fff !important;
    }

    .france {
      display: none;
    }
  }

  .push-agency-details {
    padding: 0;

    .btn-ag {
      background:$colorGreen !important;
      color:#fff !important;
    }

    .personnage {
      display: none;
    }
  }

  /* page formules */
  .formula_tab {
    td {
      padding: 2px;
      border-right:1px solid #fff;
    }

    tr:nth-child(odd) td {
      background-color: #fff !important;
    }
    tr:nth-child(even) td {
      background-color: lighten($colorAnthracite, 70%) !important;
    }

    tbody td[scope] {
      padding-left: 10px !important;
      font-weight: bold;
    }

    .help {
      display: none;
    }
  }

  /* page tarifs */
  .quoterequest {
    .header-title {
      margin-bottom: 0;
    }

    .list-item {
      display: inline-block;
      vertical-align: top;
      width:45%;
    }

    #collapseDetailFranchises {
      display: block;
      height: auto;
    }
  }

  /* sarpgn push advisor */
  .push-advisor .container {
    padding-bottom: 0 !important;
  }
}

