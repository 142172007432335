.push-home {

  position: relative;
  background-image: url('../../images/common/push-home-bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: $colorText;

  .push-home-title {
    display: block;
    width: 512px;
    max-width: 100%;
    height: 135px;
    margin:40px auto 10px auto;
    background-image: url('../../images/common/push-home-title.svg') !important; /* !important for printing */
    background-repeat: no-repeat !important; /* !important for printing */
    background-position: top center !important; /* !important for printing */
    background-size: contain !important; /* !important for printing */

    @media (min-width: $screen-sm) {
      margin:50px auto 20px auto;
    }
    @media (min-width: $screen-md) {
      margin:50px auto;
    }
  }

  .push-home-illustration {
    display: block;
    width: 1066px;
    height: 145px;
    max-width: 100%;
    margin:-10px auto 0 auto;
    background-image: url('../../images/common/push-home-voiture.png') !important; /* !important for printing */
    background-repeat: no-repeat !important; /* !important for printing */
    background-size: 90% auto !important; /* !important for printing */
    background-position: 50% 0 !important; /* !important for printing */

    @media (min-width: $screen-sm) {
      height: 219px;
      margin-top: -20px;
    }
    @media (min-width: $screen-md) {
      height: 319px;
      background-size: 85% auto !important; /* !important for printing */
    }
    @media (min-width: $screen-lg) {
      width: 1066px;
      height: 419px;
      background-size: 100% auto !important; /* !important for printing */
      background-position: 50% 40px !important; /* !important for printing */
      margin-top: -50px;
    }

    /* RETINA */
    @media ( -webkit-min-device-pixel-ratio: 1.25), (min--moz-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), ( min-resolution: 120dpi) {
      background-image: url('../../images/common/push-home-voiture2X.png');
    }

  }

  /* A/B testing  */
  &.version-2 {
    background-image: url('../../images/common/push-home-bg2.jpg');
    background-position: 25% 100%;

    @media (min-width: $screen-sm) {
      background-position: top left;
    }

    .push-home-title {
      height: 100px;
      background-image: url('../../images/common/push-home-title-blanc.svg') !important;
      margin:30px auto 30px auto;

      @media (min-width: $screen-sm) {
        height: 100px;
        margin:50px auto 50px auto;
      }
      @media (min-width: $screen-md) {
        height: 349px;
        margin:100px auto 0 auto;
      }
      @media (min-width: $screen-lg) {
        height: 449px;
        margin:150px auto 0 auto;
      }
    }

    .push-home-illustration {
      margin:0 0 0 -20px;
      display: none;
      background-image:none !important;

      @media (min-width: $screen-sm) {
        margin:-70px 0 0 -20px;
      }
      @media (min-width: $screen-md) {
        margin:-70px 0 0 -20px;
      }
      @media (min-width: $screen-lg) {
        margin:-90px 0 0 -20px;
      }


    }


  }

  .form-calcul {

    background: #fff;
    padding: 10px;

    @media (min-width: $screen-md) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      -ms-transform: translateX(-50%); /* IE 9 */
      -webkit-transform: translateX(-50%); /* Safari */
      transform: translateX(-50%);
      z-index: 1;
      border-radius: 10px;
      box-shadow: 0 0 0 16px rgba(255,255,255,0.6);
      width:640px;

      &.form-version-1 {
        /* A/B Testing bouton ok */
        width:580px;
      }
      &.form-version-2 {
        /* A/B Testing bouton je valide */
        width:650px;
      }
      &.form-version-3 {
        /* c'est parti ! */
        width:700px;
      }
    }



    form.form-horizontal {
      padding: 0;
      margin:0;
      text-align: center;

      .form-calcul-title {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: $screen-md) {
          margin-bottom: 5px;
          text-align: left;
          padding-left: 5px;
        }
      }

      .form-group {
        margin:0;
        text-align: left;

        @media (min-width: $screen-sm) {
          display: inline-block;
          vertical-align: top;
          margin:5px;
          width:450px;
        }

      }

      button {
        margin:20px auto 10px auto;
        white-space: nowrap;
        width:auto;
        max-width: 300px;

        @media (min-width: $screen-sm) {
          display: inline-block;
          vertical-align: top;
          margin:5px 0;
        }

      }

    }

  }


  /* push home in agency page */
  &.push-home-inside {

    overflow: hidden;

    .push-home-title {
      width: 410px;
      height: 110px;
      margin: 10px auto;

    }

    .push-home-illustration {
      width: 400px;
      height: 200px;
      margin:0 auto 0 auto;
      background-size: 100% auto;
      background-position: 50% 60px;
    }

  }


}
