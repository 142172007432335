.form-calcul {
  input.form-control {
    box-shadow: inset 3px 3px 3px 0px rgba(0, 0, 0, .2);
  }
}

button.btn:disabled {
  background-color: $colorGrey;
  color: #fff;
}

.select2-container {
  width: 100% !important;

  .selection {
    width: 100% !important;
  }

  .select2-search {
    display: none !important;
  }

  .select2-results {
    text-align: left;
  }

  .select2-selection {
    .select2-selection__arrow {
      height: 38px;
      width: 38px;
      background-color: $colorPrimary;
      border-radius: 0 3px 3px 0;

      b {
        border-color: #fff transparent transparent transparent;
        border-width: 8px 8px 0 8px;
        margin-left: -8px;
        margin-top: -4px;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          border-style: solid;
          border-color: $colorPrimary transparent transparent transparent;
          border-width: 8px 8px 0 8px;
          margin-left: -8px;
          margin-top: -12px;
          height: 0;
          width: 0;
          content: '';
        }
      }
    }
  }

  &.select2-container--open {
    .select2-selection {
      .select2-selection__arrow {
        b {
          border-color: transparent transparent #fff transparent;
          border-width: 0 8px 8px 8px;

          &::after {
            border-color: transparent transparent $colorPrimary transparent;
            border-width: 0 8px 8px 8px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

input.form-control,
.form-group textarea,
.select2-container .select2-selection,
.btn.dropdown-toggle
{
  height: auto;
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: $colorInputBg;
  border: 1px solid $colorInputBorder;
  color: $colorInputText;
  appearance: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  @include transition(all .4s);

  &:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &:focus {
    outline: 0;
    border: 1px dotted $colorInputText;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.select2-container .select2-selection {
  padding: 5px 15px;
}

.form-group textarea {
  height:200px;
  @include em-size(16);
}

.btn.dropdown-toggle {
  position: relative;
  padding: 10px 15px;
  text-transform: none;
  font-weight: normal;
  border-radius: 4px;
  @include em-size(16);

  &:before {
    @extend .sprite;

    position: absolute;
    right: 0;
    top:0;
    display: block;
    content: '';
    border-left:1px solid transparent;
    background-position: 0 -684px;
    height: 100%;
    width:39px;
    border-radius: 0 3px 3px 0;
  }

  &.disabled {
    &:before {
      background-position: 0 -800px;
    }

    &:hover {
      background-color: #f2f3f3;
    }
  }

  .bs-caret {
    display: none;
  }
}

/* Radio & Checkbox */
.radio,
.checkbox,
.radio-inline,
.checkbox-inline {
  position:relative;

  label {
    cursor:pointer;
    margin-right: 7px;
    padding-left: 35px;
    margin-bottom: 0;
    line-height: 1.2;
    font-weight: normal !important;

    input + .custom-icon {
      background-color: $colorInputBg;
      border: 1px solid $colorInputBorder;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      content: '';
      top: 1px;
      left: 2px;
      width:20px;
      height:20px;
      position:absolute;
    }

    input:focus + .custom-icon {
      border-color: $colorPrimary;
    }

    input + .custom-icon + .iicon-check {
      position: absolute;
      top: 1px;
      left: 2px;
      width:20px;
      height:20px;
      padding-left: 3px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      opacity:0;
      filter: alpha(opacity=0);
      @include transition(all .5s);
    }

    input:checked + .custom-icon + .iicon-check {
      opacity:1;
      filter: alpha(opacity=100);
      background-color: $colorPrimary;
    }
  }
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  margin-left: -23px;
  margin-top: 2px;
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
  margin-left: 0;
  margin-right: 20px;
}

.radio-inline,
.checkbox-inline {
  label {
    padding-left: 10px;
  }
}

@media (max-width: $screen-sm) {
  .radio-inline,
  .checkbox-inline {
    display: block;
  }
  .radio-inline + .radio-inline,
  .checkbox-inline + .checkbox-inline {
    margin-left:0;
  }
  .radio + .radio,
  .checkbox + .checkbox {
    margin-top:-10px;
  }
}

.form-group-radio,
.form-group-checkbox {
  position: relative;
  text-align: left;

  label {
    line-height: 120% !important;
  }

  .custom-radio,
  .custom-checkbox {
    margin-left: 10px;
  }

  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    padding-top: 0;
  }
}

.form-group-title {
  font-weight:bold;
  line-height: 100%;

  @media (max-width: $screen-sm) {
    float: none;
    margin-bottom: 10px;
  }

  @media (min-width: $screen-sm) {
    position: initial;
    text-align: right;
  }
}

/* Optin Je souhaite recevoir les offres */
.col-optin {
  padding-left: 30px;
  @include em-size(15);

  .custom-icon,
  .iicon-check {
    left:-10px !important;
  }
}

.btn-group {
  height: auto;

  .btn.dropdown-toggle {
    padding: 0 50px 0 15px;
    height: 40px;

    .filter-option {
      padding-bottom: 4px;
      margin-top:10px;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      outline: none !important;
    }
  }

  .dropdown-menu {
    & > li {
      & > a:hover,
      & > a:focus {
        background-color: $colorPrimary;
        color: #fff;
      }
    }

    & > li.selected {
      & > a,
      & > a:hover,
      & > a:focus {
        background-color: $colorGrey;
        color: $colorText;
      }
    }

    li:first-child {
      font-style: italic;

      & > a,
      & > a:hover,
      & > a:focus {
        background: transparent;
        color: $colorText;
      }
    }

    a:focus {
      outline: none;
    }
  }
}

label.disabled {
  color: $colorGrey;
}

.required-information {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  font-style: italic;
  text-align: center;
  @include em-size(14);
}

.help-block {
  text-align: left;
  display: none;
  clear: both;
  @include em-size(12);
}

/* errors */
.error {
  display: none;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label,
.has-error .error,
.autocomplete-no-find {
  color: red;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.has-error .form-control {
  border-color: red;
}

.has-error {
  .help-block {
    display: block;
  }

  .btn.dropdown-toggle {
    &:before {
      background-position: 0 -743px;
    }
  }
}

.autocomplete-no-find {
  display: block;
  padding: 15px 20px 0;
  text-align: left;
  @include em-size(12);
}

/* Modal Help */
.btn-help {
  position: absolute;
  right:-20px;
  top: 5px;
  width:30px;
  height:30px;
  padding: 0;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  border: 1px solid $colorGrey;
  color:$colorGrey;
}

.modal-help {
  .modal-help-title {
    color: $colorBlue;
    font-weight: bold;
  }
}

/* Auto completion */
#recherche_box {
  position: absolute;
  display: block;
  clear: both;
  text-align: left;
  z-index: 9999;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.4);

  @media (max-width:$screen-xs) {
    max-width:90%;
  }

  @media (min-width:$screen-md) {
    min-width:100%;
  }

  ul {
    display: block;
    width: 100%;
    margin:10px 0;
    max-height: 180px;
    overflow-y: scroll;

    li {
      cursor: pointer;
      border:1px dotted transparent;
      padding: 2px 10px;

      &:focus {
        border:1px dotted #000;
        outline: none;
        background: $colorInputBg;
      }
      &:hover {
        background: $colorBlue;
        color: #fff;
      }

      &:first-child {
        cursor: default;

        &:focus {
          outline: none;
          border:1px dotted transparent;
          background: transparent;
        }

        &:hover {
          background: none;
          color: inherit;
        }
      }
    }
  }

  #recherche_box_close {
    position: absolute;
    right:5px;
    top:5px;
    cursor: pointer;
  }

  a {
    display: block;
    margin:10px auto;
    color:$colorOrange;
    text-decoration: underline;
    text-align: center;
    font-weight: bold;
    @include em-size(16);

    &:hover {
      text-decoration: none;
    }
  }
}

//----Moteur de recherche HP----
#formSearchAgency {
  .form-help {
    > div {
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  input#search_agency_recherche {
    position: relative;
    width: 100% !important;
    display: block !important;
  }
}

input.loader-img {
  background-image: url('../../images/common/loader-ajax.gif');
  background-size: 20px 20px;
  background-position: 98% 10px;
  background-repeat: no-repeat;
  @include transition(none .4s);
}

/* Custom Nb de KM (step 1) */
#formQuote {
  #etape-1 {
    & > .form-group:nth-of-type(1) {
      .radio-inline {
        @media (max-width: 510px) {
          margin-bottom: 10px;
        }
        @media (min-width: 510px) and (max-width: 768px) {
          width:42%;
          display: inline-block;
        }
      }
    }
  }
}


#formContact {
  padding-top: 30px;

  .form-group {
    display: block;
    clear: both;

    label {
      font-weight: bold;
      padding-bottom: 5px;
    }

    div {
      padding-bottom: 20px !important;

      div {
        padding: 0 !important;
      }

      &.radio {
        input {
          margin-right: 12px;
        }
      }
    }

    [type="text"],
    [type="email"],
    textarea {
      border-radius: 4px;
      color: #333333;
    }
  }

  .button-row {
    div {
      padding-top: 10px;
      padding-right: 30px;

      [type="submit"] {
        cursor: pointer;
        float: right;
      }
    }
  }

  @media (min-width: $screen-md) {
    .form-group {
      margin-left: -33.3333%;

      label {
        text-align: right;
      }

      div {
        &.radio {
          padding-bottom: 0;
        }
      }
    }
  }
}
