a {
  color: $color-primary-core-bundle;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Styles des boutons
// On cible uniquement les boutons qui sont dans le site-global (ça exclut ceux du BO Sonata)
.site-global {
  .btn {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 8px;
    letter-spacing: normal;
    padding: 6px 15px;
    font-weight: bold;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border: 2px solid $color-primary-core-bundle;
    background-color: $color-primary-core-bundle;
    font-size: rem(16);
    transition: all .2s;

    &:focus,
    &:hover {
      text-decoration: none;
      opacity: .8;
    }
  }
}

.btn {

  .cta-icon_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .cta-icon-image {
      width: 25px;
      height: auto;

      &.icon-position-right {
        margin-left: 15px;
      }
      &.icon-position-left {
        margin-right: 15px;
      }
    }
  }

  &.btn-full-width {
    width: 100%;
  }

  // Type link
  &.btn-link {
    color: $color-primary-core-bundle;
    border: 0;
    background-color: transparent;
    position: relative;
    text-transform: inherit;
    padding: 10px 30px 10px 10px;

    .link-big {
      font-size: 1.5em;
      margin-right: 10px;
    }

    .iicon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translate3d(0, -50%, 0) rotate(90deg);
      transition: all .2s ease-out;
      stroke-width: 10%;
      width: rem(26);
    }

    &:focus,
    &:hover {
      opacity: 1;

      .iicon {
        stroke-width: 15%;
        right: 0;
      }
    }
  }

  // Colors
  &.btn-green {
    color: #fff;
    border-color: $color-secondary-core-bundle;
    background-color: $color-secondary-core-bundle;

    &.btn-link {
      color: $color-secondary-core-bundle;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.btn-orange {
    color: #fff;
    border-color: $color-third-core-bundle;
    background-color: $color-third-core-bundle;

    &.btn-link {
      color: $color-third-core-bundle;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.btn-error {
    color: #fff;
    border-color: $color-third-core-bundle;
    background-color: $color-third-core-bundle;

    &.btn-link {
      color: $color-third-core-bundle;
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.btn-error-inverse {
    color: $color-third-core-bundle;
    border-color: $color-third-core-bundle;
    background-color: #fff;

    &:focus,
    &:hover {
      opacity: 1;
      color: #fff;
      border-color: $color-third-core-bundle;
      background-color: $color-third-core-bundle;
    }
  }

  &.btn-blue-inverse {
    color: $color-primary-core-bundle;
    border-color: $color-primary-core-bundle;
    background-color: #fff;

    &:focus,
    &:hover {
      opacity: 1;
      color: #fff;
      border-color: $color-primary-core-bundle;
      background-color: $color-primary-core-bundle;
    }
  }

  &.btn-white {
    color: #fff;
    border-color: #fff;
    background-color: transparent;

    &:focus,
    &:hover {
      opacity: 1;
      color: $color-primary-core-bundle;
      border-color: #fff;
      background-color: #fff;
    }
  }

  // Icônes
  &.btn-icon-right {
    position: relative;
    padding-right: 45px;

    .iicon {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -8px;
      transition: all .2s;
    }
  }

  &.btn-icon-left {
    position: relative;
    padding-left: 45px;

    .iicon {
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -8px;
      transition: all .2s;
    }
  }

  // Sizes
  @include media(">=md") {
    &.btn-big {
      text-transform: uppercase;
      padding: 15px 30px;
      font-size: rem(20);

      &.btn-icon-right {
        padding-right: 50px;

        .iicon {
          right: 20px;
        }
      }

      &.btn-icon-left {
        padding-left: 50px;

        .iicon {
          left: 20px;
        }
      }
    }
  }

  &.btn-full {
    width: 100%;
  }
}

// FLECHE RETOUR
.btn-back {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  line-height: 38px;
  width: 38px;
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 50px;
  padding: 0;
  transition: background-color .5s;
  text-align: center;

  svg {
    color: #fff;
    width: 25px;
    height: 20px;
    padding-top: 2px;
    transition: color .5s;
  }

  &:hover {
    background-color: #fff;

    svg {
      color: $color-primary-core-bundle;
    }
  }
}

a.dual-cta {
  margin: 5px;
}
