//Header
.header-core-bundle {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  display: block;
  z-index: 50;
  background-color: transparent;
  @include transition(all .2s);

  body:not(.homepage) &,
  .homepage.scrolling &,
  .search-form-in-menu-opened & {
    height: auto;
  }

  .search-open & {
    .nav-top-core-bundle,
    .header-inner-core-bundle {
      pointer-events: none;
    }
  }
}

.header-inner-core-bundle {
  position: absolute;
  left: 0;
  z-index: 7;
  top: 40px;
  width: 100%;
  display: flex;
  justify-content: left;

  .search-form-in-menu-opened &,
  .scrolling &,
  .cms & {
    background-color: #fff;
    box-shadow: $shadow-core-bundle;
  }

  .search-form-in-menu-opened & {
    z-index: 3;
  }
}

a span.text-icon-espace-perso {
  font-family: sans-serif;
}
