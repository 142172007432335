//Logos
.logo-core-bundle {
  text-align: left;
  padding: 10px 10px 5px 10px;
  @include transition(all .2s);

  a {
    display: block;
    text-decoration: none;
  }

  img.logo-image {
    width: 50px;
    height: 50px;
    @include transition(all .2s);
  }

  .bloc-logo {
    position: relative;
    height: 100%;
  }

  img.logo-sarpsp-white {
    display: none;
    width: auto;
    height: 50px;
    @include transition(all .2s);
  }

  img.logo-sarpsp-white-without-baseline {
    display: block;
    width: auto;
    height: 50px;
    @include transition(all .2s);
  }

  img.logo-sarpsp-color-without-baseline {
    display: none;
    width: auto;
    height: 50px;
    @include transition(all .2s);
  }

  body:not(.homepage) &,
  .homepage.scrolling &,
  .search-form-in-menu-opened & {
    padding: 20px 10px 15px 20px;

    img.logo-sarpsp-white {
      display: none;
      width: auto;
      height: 50px;
      @include transition(all .2s);
    }

    img.logo-sarpsp-white-without-baseline {
      display: none;
      width: auto;
      height: 50px;
      @include transition(all .2s);
    }

    img.logo-sarpsp-color-without-baseline {
      display: block;
      width: auto;
      height: 50px;
      @include transition(all .2s);
    }
  }

  @include media(">=md") {
    padding: 20px;

    .homepage & {
      padding: 40px 10px 40px 60px;

      img.logo-image {
        width: 95px;
        height: 95px;
      }

      .container-nav {
        height: 175px;
      }

      img.logo-sarpsp-white {
        position: absolute;
        display: block;
        width: auto;
        height: 120px;
        @include transition(all .2s);
      }

      img.logo-sarpsp-white-without-baseline {
        display: none;
        width: auto;
        height: 50px;
        @include transition(all .2s);
      }

      img.logo-sarpsp-color-without-baseline {
        position: absolute;
        display: block;
        opacity: 0;
        width: auto;
        height: 85px;
        @include transition(opacity .1s);
      }
    }

    body:not(.homepage) &,
    .homepage.scrolling &,
    .search-form-in-menu-opened & {
      padding: 20px 10px 15px 60px;


      img.logo-image {
        width: 50px;
        height: 50px;
      }

      .container-nav {
        height: 55px;
      }

      img.logo-sarpsp-white {
        opacity: 0;
        position: absolute;
        display: block;
        width: auto;
        height: 60px;
        @include transition(all .2s);
      }

      img.logo-sarpsp-white-without-baseline {
        display: none;
        width: auto;
        height: 50px;
        @include transition(all .2s);
      }

      img.logo-sarpsp-color-without-baseline {
        opacity: 1;
        position: absolute;
        display: block;
        width: auto;
        height: 60px;
        @include transition(all .2s);
      }
    }
  }
}
