.main {
  display: block;
  z-index: 3;
  min-height: 150px;
  width: 100%;
  transition: all .2s;

  @include media(">=md") {
    min-height: 400px;
  }

  // Ajout d'un espace en-dessous de la dernière section
  & > .section:last-child,
  .article > .section:last-child {
    &:not(.section-push-bas-de-page) {
      padding-bottom: 5rem;
    }
  }
}
