.bm-menu {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.bm-burger-button {
  z-index: 1000;
  position: absolute;
  width: 30px;
  height: 28px;
  text-align: center;
  right: 20px;
  top: 21px;
  @include transition(all .2s);

  &:hover {
    opacity: 1;
  }

  // Agrandissement de la zone de clic
  > button {
    background: transparent !important;
    z-index: 1 !important;
    left: -20px !important;
    top: -10px !important;
    width: calc(100% + 40px) !important;
    height: calc(100% + 40px) !important;
  }
}

.bm-burger-bars {
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
  opacity: 1;
  background: $color-primary-core-bundle;
  height: 3px !important;
  @include transition(all .2s);

  &:nth-of-type(2) {
    top: 40%;
  }

  &:nth-of-type(3) {
    top: 80%;
  }

  .header-inverted:not(.scrolling) & {
    background: #fff;
  }
}

.bm-item-list {
  height: 100%;

  #toggler {
    display: none;
    @include translate3d(-400px, -400px, 0);
  }
}

.burger-menu-mainnav,
.burger-menu-profile {
  position: fixed;
  top: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 1100;
  background-color: $color-primary-core-bundle;
  text-align: left;
  transform: translate3d(100%, 0, 0);
  transition: all .5s;
}

.burger-menu-profile {
  background-color: $color-third-core-bundle;
}

.bm-overlay {
  display: block;
  position: fixed;
  pointer-events: none;
  touch-action: none;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
  transition: opacity .3s;
}

.body-menu-navigation-opened {
  overflow: hidden;

  .burger-menu-mainnav {
    transform: translate3d(0, 0, 0);
  }

  .bm-overlay-navigation {
    opacity: 1;
    width: 100%;
    height: 100vh;
  }
}

.body-menu-profile-opened {
  overflow: hidden;

  .burger-menu-profile {
    transform: translate3d(0, 0, 0);
  }

  .bm-overlay-profile {
    opacity: 1;
    width: 100%;
    height: 100vh;
  }
}

.bm-cross-button {
  position: absolute;
  z-index: 10;
  width: 24px;
  height: 24px;
  right: 20px;
  top: 15px;
  @include transition(all .2s);

  .bm-cross-wrapper {
    position: absolute;
    top: 6px;
    right: 14px;
  }

  .bm-cross {
    position: absolute;
    width: 3px;
    height: 30px;
    transform: rotate(45deg);
    background: #fff;
    @include transition(all .1s);

    &:nth-of-type(2) {
      transform: rotate(-45deg);
    }
  }

  // Agrandissement de la zone de clic
  > button {
    position: absolute;
    margin: 0;
    padding: 0;
    border: none;
    text-indent: -9999px;
    outline: none;
    cursor: pointer;
    background: transparent;
    z-index: 1;
    left: -20px;
    top: -10px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
  }
}

.burger-menu-profile {
  .bm-item-list {
    padding-top: 70px;

    @include media(">=md") {
      padding-top: 150px;
    }

    > ul.bm-item {
      display: block;
      outline: none;

      > li {
        > a {
          position: relative;
          padding-right: 60px;
          text-transform: inherit;

          span {
            &.reference {
              display: block;
              font-weight: normal;
              padding-top: 10px;
              @include em-size(14);
            }

            &.lastname {
              text-transform: uppercase;
            }

            &.firstname {
              text-transform: capitalize;
            }
          }

          .iicon {
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -12px;
            @include transition(all .2s);
          }

          &:hover {
            &::after {
              right: 0;
            }
          }
        }

        /* bienvenue */
        &.menu-item-welcome {
          margin-bottom: 10px;
          color: #fff;
          font-family: $font-primary-core-bundle;
          font-weight: 700;
          @include em-size(27);

          @include media(">=md") {
            margin-bottom: 30px;
          }
        }

        /* Btn Déconnexion */
        &.menu-item-logout {
          margin-top: 20px;

          > a {
            .firstname {
              margin-left: 30px;
              text-transform: uppercase;
            }

            .iicon {
              right: inherit;
              stroke: none;
              width: 1.4rem;
              height: 1.4rem;
            }
          }
        }
      }
    }
  }
}

@include media(">=md") {
  .bm-overlay {
    top: #{$navTopHeight}px !important;
    height: calc(100vh - #{$navTopHeight}px) !important;
  }

  .bm-burger-button {
    right: 60px;
    top: 59px;
    height: 24px;

    &::after {
      position: absolute;
      left: -7px;
      top: 26px;
      font-weight: bold;
      color: $color-primary-core-bundle;
      content: "MENU";
      @include em-size(14);
      @include transition(all .2s);

      .header-inverted:not(.scrolling) & {
        color: #fff;
      }
    }

    .search-form-in-menu-opened &,
    .scrolling &,
    body:not(.homepage) & {
      top: 27px;

      &::after {
        color: $color-primary-core-bundle;
      }
    }
  }

  .bm-burger-bars {
    .search-form-in-menu-opened &,
    .scrolling &,
    body:not(.homepage) & {
      background: $color-primary-core-bundle;
    }
  }

  .burger-menu-mainnav,
  .burger-menu-profile {
    top: #{$navTopHeight}px !important;
    height: calc(100vh - #{$navTopHeight}px) !important;
    width: 600px !important;
  }

  .bm-cross-button {
    right: 62px !important;
    top: 52px !important;

    &::after {
      position: absolute;
      left: -15px;
      top: 36px;
      font-weight: bold;
      color: #fff;
      content: "FERMER";
      @include em-size(14);
      @include transition(all .2s);
    }
  }
}
