.iicon {
  width: 1em;
  height: 1em;
  vertical-align: -.15em;
  fill: currentColor;
  stroke: currentColor;
  overflow: hidden;
}

.iicon-no-stroke {
  stroke: none;
}
