*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  text-rendering: auto;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  line-height: 1.6;
  font-family: $fontPrimary;
  font-size: #{$baseFontSize}px;
  color: $colorText;
  overflow-y: scroll;
}

html,
body {
  min-height: 100%;
}

body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  font-family: $fontPrimary;
}

::-moz-selection {
  color: #fff;
  background-color: $colorPrimary;
  box-shadow: 0 0 0 rgba(0,0,0,0);
}

::selection {
  color: #fff;
  background-color: $colorPrimary;
  box-shadow: 0 0 0 rgba(0,0,0,0);
}

/* Masque la croix sur IE lors de la saisie d'un champ input */
::-ms-clear {
  display: none;
}
