.breadcrumb {
    @include em-size(14);
    color: $colorBlue;
    font-weight: bold;
    background-color: #fff;
    border-radius: 0;
    border-top: 1px solid #dadada;

    a {
      color: $colorText;
      font-weight: normal;
      border:1px dotted transparent;

      &:focus {
        border: 1px dotted $colorBlue;
        outline: 0;
      }
    }

    .content-text & {
      background-color: transparent;
      border: 0;
      color: $colorText;
      font-weight: normal;
      @include em-size(10);

      a {
        color: $colorText;
        font-weight: bold;
      }
    }
  }

a span.text-icon-espace-perso {
  line-height: 2;
}
