html.js-enabled {
  // animation des sections principales
  .section.to-animate {
    opacity: 0;
    transition: all .8s;
    transform: translate3d(0, 2%, 0);

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  // animation des pushs simples/number
  .push-simple.to-animate,
  .push-number.to-animate {
    opacity: 0;
    transition: all .8s;
    transform: translate3d(0, 2%, 0);
    transition-delay: .2s;

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    // si plusieurs pushs se suivent, on les anime en décallé
    // boucle de calcul qui ajoute .2s au délai de la transition
    // i.e : .push:nth-of-type(1) { .2s }
    // i.e : .push:nth-of-type(2) { .4s }
    // i.e : .push:nth-of-type(3) { .6s }
    // i.e : .push:nth-of-type(4) { .8s }
    // i.e : .push:nth-of-type(..) {  }
    @for $i from 1 through 9 {
      & + .push:nth-of-type(#{$i}) {
        $transi: .2 * $i;

        transition-delay: #{$transi}s;
      }
    }
  }

  // animation des pushs star
  .push-star.to-animate {
    & > img,
    & > .push-inner {
      transition: all .8s;
    }

    & > img {
      opacity: 0;
      transform: translate3d(0, -2%, 0);
    }

    & > .push-inner {
      transform: translate3d(0, 2%, 0);
    }

    &.is-animated {
      & > img,
      & > .push-inner {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  //Animation du titre insitutionnelle
  .institutionnelle .first-line.to-animate,
  .institutionnelle .banner-subtitle.to-animate {
    opacity: 0;
    transition: all .8s;
    transform: translate3d(0, 2%, 0);

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .institutionnelle .banner-subtitle.to-animate {
    transition-delay: .4s;
  }
}

.icone {
  margin: 0 auto;
}
